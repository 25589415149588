import React, { useState, useEffect, useRef } from "react";
import {
  Paper,
} from "@mui/material";
import ReactAudioPlayer from "react-audio-player";
import AudiotrackOutlinedIcon from "@material-ui/icons/AudiotrackOutlined";
import { nameHandler } from "../../../../utils/helper";


const AudioView = ({selectedUrl}) => {
  return (
    <Paper
      sx={{
        padding: '20px',
        display: 'flex',
        flexDirection: 'column',
        rowGap: '10px'
      }}
    >
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          columnGap: '10px',
          alignItems: 'center',
          padding: '10px 0px'
        }}
      >
      
        {nameHandler(selectedUrl)}
      </div>
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          width: '100%',
          background: 'rgb(241, 237, 237)',
          padding: '20px',
          borderRadius: '10px',
          height: '100%'
          // minHeight: "100vh",
        }}
      >
        <ReactAudioPlayer src={selectedUrl} autoPlay={false} controls controlsList="nodownload"/>
      </div>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between'
        }}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            rowGap: '5px'
          }}
        >
          {/* <div style={{ fontWeight: 600 }}>
          {nameHandler(selectedUrl)}
        </div> */}
        </div>
      </div>
    </Paper>
  )
}

export default AudioView
