import React, { useEffect, useState } from "react";
import { Accordion, AccordionSummary, AccordionDetails } from "@mui/material";
import axios from "axios";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import queSelectionStyles from "../../../css/queSelectionStyles.module.css";
import quebankStyle from "../../../css/questionbank.module.css";
import SubConcept from "./SubConcept";
import { isNOTNullOrUndefined } from "../../../../utils/helper";
import useFetch from "../../../../hooks/quiz/useFetch";
import ConceptQuestionsFetched from "./ConceptQuestionsFetched";

const Concept = ({
  chapterId,
  taxonomySelected,
  questiontypeId,
  isExpanded,
  search,
}) => {
  const local_data = JSON.parse(localStorage.getItem("quiz_data"));
  const boardId = local_data?.selected_board?.id;
  const fromGradeId = local_data?.selected_fromGrade?.id;
  const gradeId = local_data?.selected_grade?.id;
  const subjectId = local_data?.selected_subject?.id;
  const boy = local_data?.boy;
  // console.log("local_data ", local_data, local_data?.boy ? fromGradeId : local_data?.selected_grade?.id, local_data?.boy)
  const subTopicId = local_data?.selected_sub_topic?.id;

  const TOKEN = sessionStorage.getItem("token");

  const showtaxonomy = () => {
    if (questiontypeId && questiontypeId.code === "CASE") {
      return false;
    } else if (isNOTNullOrUndefined(taxonomySelected)) {
      return true;
    }
  };

  const [concepts, setConcepts] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_SWAGGER_URL
          }v1/api/content/question/concept-list?pageSize=1000${showtaxonomy() ? `&taxonomyId=${taxonomySelected?.id}` : ""
          }${isNOTNullOrUndefined(questiontypeId.id)
            ? `&questionTypeId=${questiontypeId.id}`
            : ""
          }${isNOTNullOrUndefined(boardId) ? `&boardId=${boardId}` : ""}${isNOTNullOrUndefined(boy ? fromGradeId : gradeId)
            ? `&gradeId=${boy ? fromGradeId : gradeId}`
            : ""
          }${isNOTNullOrUndefined(chapterId) ? `&chapterId=${chapterId}` : ""}${isNOTNullOrUndefined(subjectId) ? `&subjectId=${subjectId}` : ""
          }${isNOTNullOrUndefined(subTopicId) ? `&subTopicId=${subTopicId}` : ""
          }${isNOTNullOrUndefined(subTopicId) ? `&subTopicId=${subTopicId}` : ""
          }`,
          {
            headers: {
              Authorization: `Bearer ${TOKEN}`,
            },
          }
        );
        setConcepts(response.data.data.data[0]?.concepts);
      } catch (error) {
        // console.log(error);
      }
    };
    if (isExpanded) {
      fetchData();
    }
  }, [isExpanded]);

  const [isConceptExpanded, setIsConceptExpanded] = useState(true);
  return (
    <AccordionDetails>
      {concepts && concepts.length > 0
        ? concepts?.map((conc, i) => {
          return (
            <Accordion
              sx={{ background: "#FDF6ED" }}
              key={conc.id}
              expanded={setIsConceptExpanded}
              onChange={(e, expanded) => setIsConceptExpanded(expanded)}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <p className={quebankStyle.plan_container_link}>
                  <span
                    className={queSelectionStyles.breadcrumbs}
                    style={{ color: "#FD8C00" }}
                  >
                    {conc.concept}
                  </span>
                </p>
              </AccordionSummary>
              <SubConcept
                isConceptExpanded={isConceptExpanded}
                taxonomySelected={taxonomySelected}
                questiontypeId={questiontypeId}
                chapterId={chapterId}
                conceptId={conc.id}
                search={search}
              />
              <ConceptQuestionsFetched
                taxonomySelected={taxonomySelected}
                questiontypeId={questiontypeId}
                chapterId={chapterId}
                conceptId={conc.id}
                isConceptExpanded={isConceptExpanded}
                search={search}
              />
            </Accordion>
          );
        })
        : null}
    </AccordionDetails>
  );
};

export default Concept;
