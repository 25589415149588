import React, { useState, useEffect, useRef } from "react";
import {
  Button,
  Divider,
  FormControl,
  IconButton,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select,
  TextField,
  Paper,
  Box,
  InputLabel,
} from "@mui/material";
import PsychologyAltOutlinedIcon from "@mui/icons-material/PsychologyAltOutlined";
import ArrowCircleUpIcon from "@mui/icons-material/ArrowCircleUp";
import ArrowCircleDownIcon from "@mui/icons-material/ArrowCircleDown";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import dashboardContentStyles from "../../css/dashboardContent.module.css";
// import { MenuProps } from "./Data";
import { MenuProps } from "../../js/Data";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import DarkGreenButton from "../../../components/common/DarkGreenButton";
import { styled } from "@mui/material/styles";
import DarkYellowButton from "../../../components/common/DarkYellowButton";
// import number from "../img/Numbers.svg";
import number from "../../img/Numbers.svg";
// import pdf from "./sample.pdf";
import pdf from "../../../../src/pages/js/sample.pdf";
import DocViewer, { DocViewerRenderers } from "@cyntler/react-doc-viewer";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import Rotate90DegreesCwOutlinedIcon from "@mui/icons-material/Rotate90DegreesCwOutlined";
import Rotate90DegreesCcwOutlinedIcon from "@mui/icons-material/Rotate90DegreesCcwOutlined";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import PlayCircleIcon from "@mui/icons-material/PlayCircle";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import PauseCircleIcon from "@mui/icons-material/PauseCircle";
import PauseIcon from "@mui/icons-material/Pause";
// import SelectSectionModal from "./SelectSectionModal";
import SelectSectionModal from "../SelectSectionModal";
import { FormattedTime } from "react-player-controls";
import "../../css/switch.css";
import SideMenu from "../SideMenu";
import ReactPlayer from "react-player";
import GetLastModifiedAt from "../../API/token-controller/GetLastModifiedAt";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import dashHome from "../../../../src/img/dashHome.svg";
import ReactAudioPlayer from "react-audio-player";
import AudiotrackOutlinedIcon from "@material-ui/icons/AudiotrackOutlined";
import { isNOTNullOrUndefined, isNullOrUndefined } from "../../js/TeachValidate";
import Principal_SideMenu from "./Principal_SideMenu";
import { Worker, Viewer } from "@react-pdf-viewer/core";
import { defaultLayoutPlugin } from "@react-pdf-viewer/default-layout";
import "@react-pdf-viewer/core/lib/styles/index.css";
import "@react-pdf-viewer/default-layout/lib/styles/index.css";
import { handleRightClick } from '../../../utils/AppUtility';

import ContentViewer from '../../../components/common/ContentViewer';


const RedButton = styled(Button)(() => ({
  color: "#fff",
  backgroundColor: "red",
  borderColor: "red",
  textTransform: "none",
  display: "flex",
  flexDirection: "row",
  columnGap: "10px",
  fontSize: "0.9vw",
  "&:hover": {
    color: "#fff",
    backgroundColor: "red",
    border: 0,
    boxShadow: "0px 3px 5px red",
  },
}));
const GrayButton = styled(Button)(() => ({
  color: "#fff",
  backgroundColor: "gray",
  borderColor: "gray",
  textTransform: "none",
  display: "flex",
  flexDirection: "row",
  columnGap: "10px",
  fontSize: "0.9vw",
  "&:hover": {
    color: "#fff",
    backgroundColor: "gray",
    border: 0,
    boxShadow: "0px 3px 5px gray",
  },
}));
const YButton = styled(Button)(() => ({
  color: "#fff",
  backgroundColor: "#ffcc00",
  borderColor: "#ffcc00",
  textTransform: "none",
  display: "flex",
  flexDirection: "row",
  columnGap: "10px",
  fontSize: "0.9vw",
  "&:hover": {
    color: "#fff",
    backgroundColor: "#ffcc00",
    border: 0,
    boxShadow: "0px 3px 5px #ffcc00",
  },
}));
const WhiteButton = styled(Button)(() => ({
  color: "black",
  backgroundColor: "#fff",
  textTransform: "none",
  border: "1px solid #757575",
  borderColor: "#757575",
  fontWeight: "500",
  fontSize: "0.9vw",
  boxShadow: "1px -0px -0px 0px rgba(117,117,117,0.77)",
  "&:hover": {
    color: "black",
    backgroundColor: "#fff",
  },
}));
const PresentationButton = styled(Button)(() => ({
  color: "black",
  textTransform: "none",
  fontWeight: "500",
  fontSize: "0.9vw",
  backgroundColor: "rgb(207,205,205)",
  border: "0px",
  borderRadius: "150px",
}));
const LightWhiteButton = styled(Button)(() => ({
  color: "#3b3939",
  backgroundColor: "rgb(200,237,251)",
  textTransform: "none",
  border: "1px solid rgb(200,237,251)",
  borderColor: "#757575",
  fontWeight: "600",
  fontSize: "0.9vw",
  boxShadow: "1px -0px -0px 0px rgb(200,237,251)",
  "&:hover": {
    color: "#3b3939",
    backgroundColor: "rgb(200,237,251)",
  },
}));
const BlueButton = styled(Button)(() => ({
  color: "white",
  backgroundColor: "#061ca5",
  textTransform: "none",
  border: "1px solid #061ca5",
  borderColor: "#061ca5",
  fontWeight: "600",
  fontSize: "0.9vw",
  boxShadow: "1px -0px -0px 0px #061ca5",
  "&:hover": {
    color: "white",
    backgroundColor: "#061ca5",
  },
}));
const data = [
  { id: "1", label: "Practice Question" },
  { id: "2", label: "K-W-L Chart" },
  { id: "3", label: "Concepts / Sub-concepts" },
  { id: "4", label: "The Fundamental Theorem of Arithmetic" },
  { id: "5", label: "Decimal Expansions of Real Numbers" },
  { id: "6", label: "Progression of the Chpater" },
  { id: "7", label: "Worksheet" },
];
const Principal_ViewContent = ({
  menuType,
  setIsClicked,
  studyData,
  closeModal,
  selectedChapterId,
  pageName,
  setSelectedSection,
  selectedSection,
  selectedAcademicYear,
  setSelectedAcademicYear,
}) => {
  const token = sessionStorage.getItem("token");
  const navigate = useNavigate();
  const [collapseId, setCollapseId] = useState("");
  const [numPages, setNumPages] = useState(null);
  const [presentationNumPages, setPresentationNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  const [presentationPageNumber, setPresentationPageNumber] = useState(1);
  const [startIndex, setStartIndex] = useState(0);
  const [scale, setScale] = useState(1);
  const [rotate, setRotate] = useState(0);
  const [enterPageNo, setEnterPagNo] = useState("");
  const [selectedMenu, setSelectedMenu] = useState("");
  const [showSectionModal, setShowSectionModal] = useState(false);
  const [tabsValue, setTabsValue] = useState("1");
  const [tab, setTab] = useState("Practice Question");
  const [modifiedDate, setModifiedDate] = useState({});
  const [sectionList, setSectionList] = useState([]);
  const [academicYearList, setAcademicYearList] = useState([]);
  const [selectedUrl, setSelectedUrl] = useState("");
  const [isPlaying, setPlaying] = useState(false);
  const [duration, setDuration] = useState(0);
  const [progressObj, setProgressObj] = useState({});
  const [subDurationValue, setSubDurationValue] = useState(0);
  const [teacherId, setTeacherId] = useState("");
  const [Section, setSection] = useState([]);
  const [startDisabled, setStartDisabled] = useState(false);
  const [endDisabled, setEndDisabled] = useState(true);
  const [quizDisabled, setQuizDisabled] = useState(true);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");

  const [teacherDetail, setTeacherDetail] = useState({
    firstName: "",
    lastName: "",
    schoolName: "",
    joinDate: "",
    designation: "",
    email: "",
    mobile: "",
    id: "",
  });
  const userName = localStorage.getItem("userName");
  const role = localStorage.getItem("role");
  const [buttonsActiveState, setButtonsActiveState] = useState({
    startButton: true,
    endButton: false,
    assignButton: false,
  });
  const videoRef = useRef(null);
  const [sectionFilteredList, setSectionFilteredList] = useState([]);

  const defaultLayoutPluginInstance = defaultLayoutPlugin();

  useEffect(async () => {
    await GetLastModifiedAt(setModifiedDate);
    await fetchTeacherData(userName, role);
    const teacherData = await fetchTeacherData(userName, role);
    // Set the initial value of the filter
    const storedSectionId = sessionStorage.getItem("sectionId");
    if (storedSectionId) {
      setSelectedSection(storedSectionId);
    }
    fetchStartEnd();
    setTeacherDetail(teacherData);
    return () => { };
  }, []);

  useEffect(() => {
    fetchStartEnd();
  }, [buttonsActiveState.startButton, buttonsActiveState.endButton]);

  const fetchStartEnd = async () => {
    const boardId = sessionStorage.getItem("boardId");
    const schoolId = sessionStorage.getItem("schoolId");
    const branchId = sessionStorage.getItem("branchId");
    const gradeId = sessionStorage.getItem("gradeId");
    const sectionId = sessionStorage.getItem("sectionId");
    const subjectId = sessionStorage.getItem("subjectId");
    const academicYearId = sessionStorage.getItem("academicYearId");
    await axios
      .get(
        `${process.env.REACT_APP_SWAGGER_URL}v1/api/teacher/teach/chapter/min/details?schoolId=${schoolId}&branchId=${branchId}&boardId=${boardId}&gradeId=${gradeId}&sectionId=${sectionId}&subjectId=${subjectId}&chapterId=${selectedChapterId}&academicYearId=${academicYearId}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((response) => {
        // console.log(response, 'date response')
        setStartDate(response.data.data.startDate);
        setEndDate(response.data.data.endDate);
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    if (Object.keys(progressObj).length !== 0) {
      if (progressObj && duration) {
        setSubDurationValue(duration - progressObj?.playedSeconds);
      }
    }
  }, [progressObj, duration]);

  const checkLastIndexHandler = (menu, value) => {
    const array = menu.split(".");
    const lastIndex = array?.[array.length - 1];
    return lastIndex.includes(value);
  };

  const fetchAcademicYearData = async () => {
    const res = await axios.get(
      `${process.env.REACT_APP_SWAGGER_URL}v1/api/master/academic-years/all`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return res?.data?.data || [];
  };

  const fetchTeacherData = async (userName, role) => {
    const res = await axios.get(
      `${process.env.REACT_APP_SWAGGER_URL}v1/api/user/users/profile?username=${userName}&roles=${role}`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );
    setTeacherId(res.data.data.teacher);
  };

  const fetchSection = async () => {
    // const id = '4028928886f00aec0186fda2e0a70004'
    const id = teacherId.id;
    await axios
      .get(
        `${process.env.REACT_APP_SWAGGER_URL}v1/api/user/teachers/assigned-grades?teacherId=${id}&menuName=ASSIGN`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((response) => {
        setSection(response.data.data);
        // console.log(response.data.data, 'response section')
      })
      .catch((err) => console.log(err));
  };

  const quizRelease = async () => {
    const bordId = sessionStorage.getItem("boardId");
    const schoolId = sessionStorage.getItem("schoolId");
    const branchId = sessionStorage.getItem("branchId");
    const gradeId = sessionStorage.getItem("gradeId");
    const sectionId = sessionStorage.getItem("sectionId");
    const subjectId = sessionStorage.getItem("subjectId");
    const subTopicId = sessionStorage.getItem("subTopicId");
    const academicYearId = sessionStorage.getItem("academicYearId");
    const chapterId = selectedChapterId;
    const data = {
      bordId,
      gradeId,
      schoolId,
      branchId,
      subjectId,
      subTopicId,
      sectionId,
      chapterId,
      academicYearId,
    };
    await axios
      .get(
        `${process.env.REACT_APP_SWAGGER_URL
        }v1/api/content/quiz-release/list-to-teacher?schoolId=${schoolId}&branchId=${branchId}&boardId=${bordId}&gradeId=${gradeId}&sectionId=${sectionId}&subjectId=${subjectId}&chapterId=${selectedChapterId}&academicYearId=${academicYearId}${subTopicId ? `&subTopicId=${subTopicId}` : ""
        }`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((response) => {
        if (response?.data) {
          //   const data = response?.data?.data?.data
          // console.log(response, 'assign response')
          // alert('quiz released')
          navigate("/dashboard/assign%20quiz", {
            state: { ...data, id: selectedChapterId },
          });
        } else {
          alert("Something went wrong while releasing , try later");
          fetchStartEnd();
          // setButtonsActiveState({
          //   startButton: false,
          //   endButton: false,
          //   assignButton: true
          // })
        }
      })
      .catch((err) => console.log(err.message, "error"));
  };

  useEffect(async () => {
    const section = await fetchSection();
    const academic = await fetchAcademicYearData();
    setAcademicYearList(academic);
    setSectionList(section);

    // Set the initial value of the filter
    const storedAcademicYearId = sessionStorage.getItem("academicYearId");
    if (storedAcademicYearId) {
      setSelectedAcademicYear(storedAcademicYearId);
    } else if (academic.length > 0) {
      setSelectedAcademicYear(academic[0].id);
      sessionStorage.setItem("academicYearId", academic[0].id);
    }
  }, [teacherId.id]);

  useEffect(() => {
    if (studyData?.length) {
      const data =
        studyData[0]?.uploadContents || studyData[0]?.studentRevisionContent;
      // console.log('data', data)
      if (!data?.length) {
        setSelectedMenu(data?.documentType);
      } else {
        setSelectedMenu(data[0]?.contentWithIndux);
        setSelectedUrl(data[0]?.contentUrl);
      }
    }
  }, [studyData]);

  const startQuiz = async () => {
    const boardId = sessionStorage.getItem("boardId");
    const schoolId = sessionStorage.getItem("schoolId");
    const branchId = sessionStorage.getItem("branchId");
    const gradeId = sessionStorage.getItem("gradeId");
    const sectionId = sessionStorage.getItem("sectionId");
    const subjectId = sessionStorage.getItem("subjectId");
    const subTopicId = sessionStorage.getItem("subTopicId");
    const academicYearId = sessionStorage.getItem("academicYearId");
    const id = teacherId.id;
    //console.log("start3")
    try {
      await axios
        .post(
          `${process.env.REACT_APP_SWAGGER_URL}v1/api/teacher/teach/chapter/start`,
          {
            boardId: boardId,
            schoolId: schoolId,
            branchId: branchId,
            gradeId: gradeId,
            sectionId: sectionId,
            subjectId: subjectId,
            subTopicId: subTopicId,
            chapterId: selectedChapterId,
            academicYearId: academicYearId,
            operation: "START",
            teacherId: id,
          },

          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((res) => {
          // console.log(res, 'res dataa')
          fetchStartEnd();
        })
        .catch((error) => {
          // console.log(error.response, 'errorr in catch inside')
          if (error.response?.data?.errorCode === 404) {
            alert(error.response?.data?.message);
            fetchStartEnd();
          } else {
            alert("Something went wrong try later");
            fetchStartEnd();
          }
        });
    } catch (error) {
      // console.error(error, "errorrrrd");
    }
    // setShowSectionModal(true)
  };

  const endQuiz = async () => {
    // setButtonsActiveState({
    //   startButton: false,
    //   endButton: false,
    //   assignButton: true
    // })
    // return
    const boardId = sessionStorage.getItem("boardId");
    const schoolId = sessionStorage.getItem("schoolId");
    const branchId = sessionStorage.getItem("branchId");
    const gradeId = sessionStorage.getItem("gradeId");
    const sectionId = sessionStorage.getItem("sectionId");
    const subjectId = sessionStorage.getItem("subjectId");
    const subTopicId = sessionStorage.getItem("subTopicId");
    const academicYearId = sessionStorage.getItem("academicYearId");
    const id = teacherId.id;
    await axios
      .put(
        `${process.env.REACT_APP_SWAGGER_URL}v1/api/teacher/teach/chapter/end/or/assign-quiz`,
        {
          boardId: boardId,
          schoolId: schoolId,
          branchId: branchId,
          gradeId: gradeId,
          sectionId: sectionId,
          subjectId: subjectId,
          subTopicId: subTopicId,
          chapterId: selectedChapterId,
          academicYearId: academicYearId,
          operation: "END",
          teacherId: id,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((response) => {
        fetchStartEnd();
      })
      .catch((err) => {
        if (err.response?.data?.errorCode === 404) {
          alert(err.response?.data?.message);
          // alert("Something went wrong try later");
        } else {
          alert("Something went wrong try later");
        }
        fetchStartEnd();
      });
  };

  const onAssignQuizClick = () => {
    quizRelease();
  };

  useEffect(() => {
    // console.log(endDate, startDate, 'dates')

    if (startDate) {
      // console.log(buttonsActiveState, 'dates start exist')
      setButtonsActiveState((prev) => ({
        endButton: isNullOrUndefined(endDate),
        startButton: false,
        assignButton:
          isNOTNullOrUndefined(startDate) && isNOTNullOrUndefined(endDate),
      }));
    }
    if (endDate) {
      // console.log(buttonsActiveState, 'dates end exist')

      setButtonsActiveState((prev) => ({
        startButton: isNullOrUndefined(startDate),
        endButton: false,
        assignButton:
          isNOTNullOrUndefined(startDate) && isNOTNullOrUndefined(endDate),
      }));
    }
    if (endDate && startDate) {
      // console.log(buttonsActiveState, 'dates both exist')
      setButtonsActiveState({
        startButton: false,
        endButton: false,
        assignButton: true,
      });
    }
  }, [startDate, endDate]);

  const handleSectionSelect = (e) => {
    setSelectedSection(e.target.value);
    sessionStorage.setItem("sectionId", e.target.value);
    fetchStartEnd();
  };

  useEffect(() => {
    const selectedGrade = sessionStorage.getItem("gradeId");
    if (isNOTNullOrUndefined(selectedGrade) && Section?.length) {
      const filtered = Section?.find((i) => i.id === selectedGrade)?.sections;
      // console.log(filtered, 'filteredb', selectedGrade)
      setSectionFilteredList(filtered);
    }
  }, [Section]);

  const mql = window.matchMedia("(max-width: 2000px)");
  const smallScreen = mql.matches;
  const tabHandle = (event, newValue) => {
    setTab(newValue);
  };
  const tabsChangeHandle = (event, newValue) => {
    setTabsValue(newValue);
  };
  const addScale = () => {
    setScale((pre) => pre + 0.5);
  };
  const removeScale = () => {
    setScale((pre) => pre - 0.5);
  };
  const changePage = (offset) => {
    setPageNumber((prevPageNumber) => prevPageNumber + offset);
  };
  const changePresentationPage = (offset) => {
    setPresentationPageNumber((prevPageNumber) => prevPageNumber + offset);
  };
  const previousPresentationPage = () => {
    changePresentationPage(-1);
  };
  const nextPresentationPage = () => {
    changePresentationPage(1);
  };
  const previousPage = () => {
    changePage(-1);
  };
  const nextPage = () => {
    changePage(1);
  };
  const nameHandler = (name) => {
    const data = name?.split("/")?.[name.split("/")?.length - 1]?.split(".");
    return `${data[0]}.${data?.[data.length - 1]}`;
  };
  useEffect(() => {
    if (studyData?.length) {
      const data =
        studyData[0]?.uploadContents || studyData[0]?.studentRevisionContent;
      //console.log("data", data);
      if (!data?.length) {
        setSelectedMenu(data?.documentType);
      } else {
        setSelectedMenu(data[0]?.contentWithIndux);
        setSelectedUrl(data[0]?.contentUrl);
      }
    }
  }, [studyData]);
  const handleClick = (id, item, index) => {
    //console.log("handleClick", id, item, index);
    setCollapseId((pre) => (pre === id ? "" : id));
    if (!item.uploadContents?.length || !item.studentRevisionContent?.length) {
      setSelectedMenu(item?.documentType);
    }
    setStartIndex(index);
  };
  const subMenuHandler = (item, url) => {
    //console.log("subMenuHandler", item, url);
    if (item !== selectedMenu) {
      setPlaying(false);
      setProgressObj({});
      setDuration(0);
      setSubDurationValue(0);
    }
    setSelectedMenu(item);
    setSelectedUrl(url);
  };
  const pageChangeHandler = (number) => {
    if (number) {
      setEnterPagNo(number);
    } else {
      setEnterPagNo("");
      setPageNumber(1);
    }
  };
  const pageHandler = () => {
    setPageNumber(enterPageNo ? Number(enterPageNo) : "");
  };
  const rightRotateHandler = () => {
    setRotate((pre) =>
      pre === 0
        ? 90
        : pre === 90
          ? 180
          : pre === -90
            ? 0
            : pre === -180
              ? -90
              : 0
    );
  };
  const leftRotateHandler = () => {
    setRotate((pre) =>
      pre === 0
        ? -90
        : pre === -90
          ? -180
          : pre === 90
            ? 0
            : pre === 180
              ? 90
              : 0
    );
  };
  return (
    <div style={{ width: "100%", display: "flex", flexDirection: "row" }}>
      <SideMenu
        menuType={menuType}
        menu={studyData?.length && studyData}
        menuClickHandler={handleClick}
        subMenuHandler={subMenuHandler}
        collapseId={collapseId}
        startIndex={startIndex}
        selectedUrl={selectedUrl}
      />

      <div style={{ width: "100%", overflow: "auto" }}>
        <article>
          <div
            // className={dashboardContentStyles.dashboard_link}
            style={{ fontSize: "1vw", margin: "3px 10px" }}
          >
            <span className={dashboardContentStyles.link_icon}>
              <img
                src={dashHome}
                alt="no img"
                width="15px"
                height="20px"
                style={{ verticalAlign: "middle" }}
              />
            </span>
            <span className={dashboardContentStyles.link_text}>Home</span>
            <span>
              <i className="fa-solid fa-angle-right" />
            </span>
            <span>
              <i className="fa-solid fa-angle-right" />
            </span>
            <span onClick={() => closeModal()} style={{ cursor: "pointer" }}>
              {pageName}
            </span>
            <span>
              <i className="fa-solid fa-angle-right" />
            </span>
            <span>
              <i className="fa-solid fa-angle-right" />
            </span>
            <a style={{ color: "blue" }}>View Document</a>
          </div>
          <div className={dashboardContentStyles.dashboard_last_updated}>
            <p>
              Last Update:
              {modifiedDate?.length && modifiedDate}
            </p>
          </div>
        </article>
        <div
          style={{
            marginTop: "20px",
            paddingRight: "33px",
            marginLeft: "10px",
            width: "100%",
            display: "flex",
            flexDirection: "column",
            rowGap: "1rem",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                columnGap: "1rem",
                alignItems: "center",
              }}
            >
              <FormControl
                sx={{
                  width: "auto",
                  color: "white",
                  borderRadius: "150px",
                }}
              >
                <Select
                  MenuProps={MenuProps}
                  input={<OutlinedInput label="Tag" />}
                  size={"small"}
                  IconComponent={(props) => (
                    <ExpandMoreIcon
                      {...props}
                      sx={{
                        fontSize: "1.5vw",
                        color: "white",
                      }}
                    />
                  )}
                  sx={{
                    color: "white",
                    background: "#061ca5",
                    borderRadius: "150px",
                    width: "146px",
                    height: "32px",
                  }}
                  value={selectedSection}
                  onChange={handleSectionSelect}
                  inputProps={{ "aria-label": "Without label" }}
                >
                  {sectionFilteredList?.map((item) => {
                    return (
                      <MenuItem
                        value={item.id}
                        key={item.id}
                        className={dashboardContentStyles.menu_item}
                      >
                        {item.hasOwnProperty("section") ? (
                          <ListItemText primary={item.section} />
                        ) : null}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
              <FormControl
                sx={{
                  background: "white",
                  borderRadius: 150,
                  width: "156px",
                }}
              >
                <InputLabel
                  id="demo-simple-select-label"
                  style={{
                    fontSize: "14px",
                  }}
                >
                  Academic Year
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  input={<OutlinedInput label="Tag" />}
                  MenuProps={MenuProps}
                  style={{
                    borderRadius: "150px",
                    padding: "4px",
                    color: "#01b5eb",
                  }}
                  size={"small"}
                  disabled
                  IconComponent={(props) => (
                    <ExpandMoreIcon {...props} sx={{ fontSize: "1.5vw" }} />
                  )}
                  value={selectedAcademicYear}
                  onChange={setSelectedAcademicYear}
                >
                  {academicYearList?.map((item) => {
                    return (
                      <MenuItem value={item.id} key={item.id}>
                        <ListItemText primary={item.academicYear} />
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  columnGap: "10px",
                  alignItems: "center",
                  fontSize: "0.93vw",
                }}
              >
                <div style={{ fontWeight: 600, color: "rgb(66, 61, 92)" }}>
                  Start Date:
                </div>
                <div style={{ color: "#01b5eb" }}>
                  {startDate ? startDate : "Not started yet"}
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  columnGap: "10px",
                  alignItems: "center",
                  fontSize: "0.93vw",
                }}
              >
                <div style={{ fontWeight: 600, color: "rgb(66, 61, 92)" }}>
                  End Date:
                </div>
                <div style={{ color: "#01b5eb" }}>
                  {endDate ? endDate : "Not ended yet"}
                </div>
              </div>
            </div>
            {selectedChapterId && (
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  alignItems: "center",
                  flexDirection: "row",
                  columnGap: "10px",
                }}
              >
                <DarkGreenButton
                  disabled={!buttonsActiveState.startButton}
                  onClick={startQuiz}
                >
                  Start
                </DarkGreenButton>
                <RedButton
                  onClick={endQuiz}
                  disabled={!buttonsActiveState.endButton}
                >
                  End
                </RedButton>
                <DarkYellowButton
                  disabled={!buttonsActiveState.assignButton}
                  onClick={onAssignQuizClick}
                >
                  Assign Quiz
                </DarkYellowButton>
              </div>
            )}
          </div>

          {checkLastIndexHandler(selectedMenu, "pdf") ? (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                background: "rgb(204, 204, 204)",
                flexDirection: "column",
                alignItems: "center",
                // width: "100%",
                // height: "550px",
              }}
              onContextMenu={handleRightClick}
            >

              <ContentViewer contentstyle={{ height: "600px", width: "100" }}
                url={selectedUrl} />

              {/* <Worker 
                workerUrl="https://unpkg.com/pdfjs-dist@3.4.120/build/pdf.worker.min.js"> */}
              {/* <div
                style={{ height: "600px", width: "100%" }} frameBorder={0}>
                <Viewer
                  fileUrl={'https://cors-anywhere.herokuapp.com/' + selectedUrl}
                  plugins={[defaultLayoutPluginInstance]}
                />
              </div> */}
              {/* </Worker> */}

              {/* <iframe
                title={nameHandler(selectedUrl)}
                src={selectedUrl}
                frameBorder={0}
                style={{ height: "600px", width: "100%" }}
              /> */}
            </div>
          ) : selectedMenu === "Workbook" ? (
            <Paper
              sx={{
                padding: "10px",
              }}
            >
              <TabContext value={tabsValue}>
                <Box
                  sx={{
                    borderBottom: 1,
                    borderColor: "rgba(221,218,218,0.67)",
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    // columnGap: "1rem",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      flexDirection: "row",
                      columnGap: "10px",
                    }}
                  >
                    <div
                      style={{
                        background: "#ffcc00",
                        borderRadius: "150px",
                        width: "1.5vw",
                        height: "1.5vw",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <PsychologyAltOutlinedIcon
                        style={{ color: "white", fontSize: "1.3vw" }}
                      />
                    </div>
                    <div
                      style={{
                        fontSize: "1.09vw",
                        fontWeight: 600,
                        color: "black",
                      }}
                    >
                      Mathematics
                    </div>
                  </div>
                  <TabList
                    onChange={(e, value) => tabsChangeHandle(e, value)}
                    aria-label="lab API tabs example"
                    textColor="inherit"
                    // indicatorColor="secondary"
                    TabIndicatorProps={{
                      style: {
                        background: "#061ca5",
                        height: "10px",
                        top: "50px",
                      },
                    }}
                    variant={smallScreen ? "scrollable" : "standard"}
                  >
                    <Tab
                      label="All"
                      value="1"
                      sx={{
                        background: tabsValue === "1" ? "#061ca5" : "",
                        color: tabsValue === "1" ? "white" : "black",
                        borderRadius: "150px",
                        marginBottom: "5px",
                        textTransform: "none",
                        fontWeight: 600,
                      }}
                    />
                    <Tab
                      label="Real Number"
                      value="2"
                      sx={{
                        background: tabsValue === "2" ? "#061ca5" : "",
                        color: tabsValue === "2" ? "white" : "black",
                        borderRadius: "150px",
                        marginBottom: "5px",
                        textTransform: "none",
                        fontWeight: 600,
                      }}
                    />
                    <Tab
                      label="Chapter 2"
                      value="3"
                      sx={{
                        background: tabsValue === "3" ? "#061ca5" : "",
                        color: tabsValue === "3" ? "white" : "black",
                        borderRadius: "150px",
                        marginBottom: "5px",
                        textTransform: "none",
                        fontWeight: 600,
                      }}
                    />
                    <Tab
                      label="Chapter 4"
                      value="4"
                      sx={{
                        background: tabsValue === "4" ? "#061ca5" : "",
                        color: tabsValue === "4" ? "white" : "black",
                        borderRadius: "150px",
                        marginBottom: "5px",
                        textTransform: "none",
                        fontWeight: 600,
                      }}
                    />
                    <Tab
                      label="Chapter 5"
                      value="5"
                      sx={{
                        background: tabsValue === "5" ? "#061ca5" : "",
                        color: tabsValue === "5" ? "white" : "black",
                        borderRadius: "150px",
                        marginBottom: "5px",
                        textTransform: "none",
                        fontWeight: 600,
                      }}
                    />
                  </TabList>
                  <TextField
                    size={"small"}
                    placeholder={"Search Content"}
                    // onChange={coordinatorSearch}
                    InputProps={{
                      startAdornment: (
                        <IconButton>
                          <PsychologyAltOutlinedIcon
                            style={{ color: "#152738" }}
                          />
                        </IconButton>
                      ),
                    }}
                    sx={{
                      background: "rgba(221,218,218,0.67)",
                      borderRadius: "7px",
                      marginBottom: "10px",
                    }}
                  />
                </Box>
                <TabPanel value="1">All</TabPanel>
                <TabPanel value="2">
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      rowGap: "1rem",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        rowGap: "2rem",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          rowGap: "1rem",
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            rowGap: "10px",
                          }}
                        >
                          <div
                            style={{
                              fontSize: 20,
                              fontWeight: 600,
                              color: "black",
                            }}
                          >
                            Real Number
                          </div>
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              columnGap: "10px",
                            }}
                          >
                            <YButton sx={{ borderRadius: "150px" }}>
                              11.Sessions
                            </YButton>
                            <GrayButton sx={{ borderRadius: "150px" }}>
                              Chapter 1
                            </GrayButton>
                          </div>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "space-between",
                          }}
                        >
                          <div
                            style={{
                              width: "100%",
                              display: "flex",
                              flexDirection: "column",
                              rowGap: "10px",
                            }}
                          >
                            <div
                              style={{
                                color: "rgb(66, 61, 92)",
                                fontSize: 18,
                                fontWeight: 600,
                              }}
                            >
                              Learning Objective
                            </div>
                            <div>
                              <span style={{ color: "black", fontWeight: 600 }}>
                                Knowledge:
                              </span>
                              The student is able to :
                            </div>
                            <div
                              style={{
                                width: "100%",
                                paddingLeft: "20px",
                              }}
                            >
                              <ul>
                                <li>
                                  State and understand Euclid's Division Lemma.
                                </li>
                                <li>
                                  State and understand Euclid's Division Lemma.
                                </li>
                                <li>
                                  State and understand Fundamental Theorem of
                                  Arithmetic.
                                </li>
                              </ul>
                            </div>
                          </div>
                          <div
                            style={{
                              width: "100%",
                              display: "flex",
                              flexDirection: "column",
                              rowGap: "10px",
                            }}
                          >
                            <div>
                              <span style={{ color: "black", fontWeight: 600 }}>
                                Value/Behaviour
                              </span>
                              The student is able to :
                            </div>
                            <div
                              style={{
                                width: "100%",
                                paddingLeft: "20px",
                              }}
                            >
                              <ul>
                                <li>
                                  Understand the importance of L.C.M and H.C.F
                                  in real life situation.
                                </li>
                                <li>
                                  State and understand Euclid's Division Lemma.
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        style={{
                          width: "100%",
                          display: "flex",
                          flexDirection: "column",
                          rowGap: "10px",
                        }}
                      >
                        <div>
                          <span style={{ color: "black", fontWeight: 600 }}>
                            Skills:
                          </span>
                          The student is able to :
                        </div>
                        <div
                          style={{
                            width: "100%",
                            paddingLeft: "20px",
                          }}
                        >
                          <ul>
                            <li>
                              Solve divisibility problem using Euclid Division
                              Lemma.
                            </li>
                            <li>Solve problem based on L.C.M and H.C.F.</li>
                            <li>Prove irrationality of 2,3,5 etc.</li>
                            <li>
                              Differentiate between rational and irrational
                              numbers.
                            </li>
                            <li>
                              Comprehend and solve word problem based on L.C.M
                              and H.C.F.
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                    <div style={{ display: "flex", flexDirection: "column" }}>
                      <Divider
                        orientation="horizontal"
                        flexItem
                        sx={{
                          border: "1px solid rgba(221,218,218,0.67)",
                          padding: "0px",
                        }}
                      />
                      <div style={{ padding: "15px" }}>
                        <LightWhiteButton
                          sx={{
                            display: "flex",
                            flexDirection: "row",
                            columnGap: "10px",
                          }}
                        >
                          Session 1
                          <PsychologyAltOutlinedIcon />
                        </LightWhiteButton>
                      </div>
                      <Divider
                        orientation="horizontal"
                        flexItem
                        sx={{
                          border: "1px solid rgba(221,218,218,0.67)",
                          padding: "0px",
                        }}
                      />
                    </div>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        rowGap: "10px",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "space-between",
                          alignItems: "center",
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            // columnGap: "1px",
                            color: "black",
                            alignItems: "center",
                          }}
                        >
                          <PsychologyAltOutlinedIcon sx={{ fontSize: 20 }} />
                          <div
                            style={{ fontSize: "22px", fontWeight: "bolder" }}
                          >
                            Session 1
                          </div>
                        </div>
                        <div
                          style={{
                            border: "1px solid rgba(221,218,218,0.67)",
                            padding: "10px",
                            display: "flex",
                            justifyContent: "end",
                            alignItems: "end",
                          }}
                        >
                          <PsychologyAltOutlinedIcon
                            sx={{ color: "rgb(151,151,151)" }}
                          />
                        </div>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "space-between",
                          columnGap: "1rem",
                        }}
                      >
                        <div
                          style={{
                            width: "100%",
                            display: "flex",
                            flexDirection: "column",
                            rowGap: "1rem",
                          }}
                        >
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              rowGap: "10px",
                            }}
                          >
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "row",
                                columnGap: "10px",
                                alignItems: "center",
                              }}
                            >
                              <div style={{ fontWeight: 600, color: "black" }}>
                                Mindful Practice:
                              </div>
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "row",
                                  columnGap: "5px",
                                  alignItems: "center",
                                }}
                              >
                                <PsychologyAltOutlinedIcon
                                  sx={{ color: "rgb(151,151,151)" }}
                                />
                                <span
                                  style={{ color: "#00b85d", fontWeight: 600 }}
                                >
                                  5 Min
                                </span>
                              </div>
                            </div>
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "row",
                                columnGap: "10px",
                                alignItems: "center",
                              }}
                            >
                              <div style={{ fontWeight: 600, color: "black" }}>
                                Introduction:
                              </div>
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "row",
                                  columnGap: "5px",
                                  alignItems: "center",
                                }}
                              >
                                <PsychologyAltOutlinedIcon
                                  sx={{ color: "rgb(151,151,151)" }}
                                />
                                <span
                                  style={{ color: "#00b85d", fontWeight: 600 }}
                                >
                                  10 Min
                                </span>
                              </div>
                            </div>
                          </div>
                          <div
                            style={{ display: "flex", flexDirection: "column" }}
                          >
                            The teacher revises varius types of numbers like
                            Natural Numbers, Whole NUmbers, Integers, Rational
                            Numbers, Irrational Numbers and Real Numbers with
                            the help of a Venn Diagram
                          </div>
                          <div
                            style={{ display: "flex", flexDirection: "column" }}
                          >
                            <div>
                              The teacher also revises Division Algorithm of
                              Integers by asking follwing question:
                            </div>
                            <div style={{ paddingLeft: "20px" }}>
                              <ol type={"a"}>
                                <li>
                                  What is dividend, divisor, quotient and
                                  remainder?
                                </li>
                                <li>What is Division Algorithm?</li>
                              </ol>
                            </div>
                          </div>
                          <div
                            style={{ display: "flex", flexDirection: "column" }}
                          >
                            The teacher explains division algorithm using
                            suitable example Dividend = Divisor * Quotient +
                            Remainder
                          </div>
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              rowGap: "10px",
                            }}
                          >
                            <div
                              style={{
                                fontSize: 22,
                                fontWeight: 600,
                                color: "blue",
                              }}
                            >
                              Guided inquiry
                            </div>
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "row",
                                columnGap: "10px",
                                alignItems: "center",
                              }}
                            >
                              <div style={{ fontWeight: 600, color: "black" }}>
                                Introduction:
                              </div>
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "row",
                                  columnGap: "5px",
                                  alignItems: "center",
                                }}
                              >
                                <PsychologyAltOutlinedIcon
                                  sx={{ color: "rgb(151,151,151)" }}
                                />
                                <span
                                  style={{ color: "#00b85d", fontWeight: 600 }}
                                >
                                  15 Min
                                </span>
                              </div>
                            </div>
                          </div>
                          <div
                            style={{
                              width: "100%",
                              display: "flex",
                              flexDirection: "column",
                              rowGap: "3px",
                            }}
                          >
                            <div>
                              The teacher states and explains Euclid's Division
                              Lemma with the help of PPT, for reach pair of
                              positive integers, a and b, there exists unique
                              integers q and r , satisfying the relations:
                            </div>
                            <div>{`a = bq + r, 0 < r < b`} </div>
                            <div>Debriefing : [10min]</div>
                            <div>
                              The teacher asks the students to find integers 'q'
                              and 'r' for the following pair of positive
                              integers 'a' and 'b',
                            </div>
                            <div style={{ paddingLeft: "20px" }}>
                              <ol
                                type={"1"}
                                style={{
                                  display: "flex",
                                  flexDirection: "row",
                                  columnGap: "1.2rem",
                                }}
                              >
                                <li>10,3</li>
                                <li>4,19</li>
                                <li>81,3</li>
                              </ol>
                            </div>
                          </div>
                        </div>
                        <div
                          style={{
                            width: "100%",
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "space-between",
                          }}
                        >
                          <div
                            style={{
                              width: "100%",
                              height: "100%",
                              display: "flex",
                              alignItems: "center",
                            }}
                          >
                            <img src={number} width={"100%"} alt={""} />
                          </div>
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "end",
                              alignItems: "center",
                            }}
                          >
                            <BlueButton
                              sx={{
                                display: "flex",
                                flexDirection: "row",
                                alignItems: "center",
                                columnGap: "10px",
                              }}
                            >
                              <PsychologyAltOutlinedIcon />
                              Home Work Assainment
                            </BlueButton>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        rowGap: "10px",
                      }}
                    >
                      <Divider
                        orientation="horizontal"
                        flexItem
                        sx={{
                          border: "1px solid rgba(221,218,218,0.67)",
                          padding: "0px",
                        }}
                      />
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "space-between",
                          alignItems: "center",
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            // columnGap: "1px",
                            color: "black",
                            alignItems: "center",
                          }}
                        >
                          <PsychologyAltOutlinedIcon sx={{ fontSize: 20 }} />
                          <div
                            style={{ fontSize: "22px", fontWeight: "bolder" }}
                          >
                            Session 2
                          </div>
                        </div>
                        <div
                          style={{
                            border: "1px solid rgba(221,218,218,0.67)",
                            padding: "10px",
                            display: "flex",
                            justifyContent: "end",
                            alignItems: "end",
                          }}
                        >
                          <PsychologyAltOutlinedIcon
                            sx={{ color: "rgb(151,151,151)" }}
                          />
                        </div>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          columnGap: "1em",
                          justifyContent: "space-between",
                        }}
                      >
                        <div
                          style={{
                            width: "100%",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          <img src={number} alt={""} width={"100%"} />
                        </div>
                        <div
                          style={{
                            width: "100%",
                            display: "flex",
                            flexDirection: "column",
                            rowGap: "10px",
                          }}
                        >
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              rowGap: "5px",
                            }}
                          >
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "row",
                                columnGap: "10px",
                                alignItems: "center",
                              }}
                            >
                              <div style={{ fontWeight: 600, color: "black" }}>
                                Mindful Practice:
                              </div>
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "row",
                                  columnGap: "5px",
                                  alignItems: "center",
                                }}
                              >
                                <PsychologyAltOutlinedIcon
                                  sx={{ color: "rgb(151,151,151)" }}
                                />
                                <span
                                  style={{ color: "#00b85d", fontWeight: 600 }}
                                >
                                  5 Min
                                </span>
                              </div>
                            </div>
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "row",
                                columnGap: "10px",
                                alignItems: "center",
                              }}
                            >
                              <div style={{ fontWeight: 600, color: "black" }}>
                                Guided Inquiry:
                              </div>
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "row",
                                  columnGap: "5px",
                                  alignItems: "center",
                                }}
                              >
                                <PsychologyAltOutlinedIcon
                                  sx={{ color: "rgb(151,151,151)" }}
                                />
                                <span
                                  style={{ color: "#00b85d", fontWeight: 600 }}
                                >
                                  25 Min
                                </span>
                              </div>
                            </div>
                          </div>
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              rowGap: "10px",
                            }}
                          >
                            <div
                              style={{
                                fontSize: 22,
                                fontWeight: 600,
                                color: "blue",
                              }}
                            >
                              Euclid's division Algorithm
                            </div>
                            <div>
                              The teacher explain how to find HCF of two given
                              number using euclid's division Algorithm.
                            </div>
                            <div>
                              An algorithm is series of well-definded steps
                              which gives a proceure of solving a type of
                              problem.
                            </div>
                            <div>what is euclid's division Algorithm?</div>
                          </div>
                        </div>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          rowGap: "10px",
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            columnGap: "10px",
                            alignItems: "center",
                          }}
                        >
                          <div style={{ fontWeight: 600, color: "black" }}>
                            Recapitulation:
                          </div>
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              columnGap: "5px",
                              alignItems: "center",
                            }}
                          >
                            <PsychologyAltOutlinedIcon
                              sx={{ color: "rgb(151,151,151)" }}
                            />
                            <span style={{ color: "#00b85d", fontWeight: 600 }}>
                              10 Min
                            </span>
                          </div>
                        </div>
                        <div>
                          Students are asked to make a flowchart to euclid's
                          division Algorithm
                        </div>
                        <div
                          style={{
                            fontSize: 18,
                            fontWeight: 600,
                            color: "blue",
                          }}
                        >
                          Home Work
                        </div>
                        <div>Complete section 2 Q1 & Q2.</div>
                      </div>
                    </div>
                  </div>
                </TabPanel>
                <TabPanel value="3">Chapter 2</TabPanel>
                <TabPanel value="4">Chapter 4</TabPanel>
                <TabPanel value="5">Chapter 5</TabPanel>
              </TabContext>
            </Paper>
          ) : selectedMenu === "WB_Student" ? (
            <Paper
              sx={{
                padding: "20px",
                display: "flex",
                flexDirection: "column",
                rowGap: "1rem",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  columnGap: "10px",
                  alignItems: "center",
                  padding: "10px 0px",
                }}
              >
                <div
                  style={{
                    background: "#ffcc00",
                    borderRadius: "150px",
                    width: "1.5vw",
                    height: "1.5vw",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <PsychologyAltOutlinedIcon
                    style={{ color: "white", fontSize: "1.3vw" }}
                  />
                </div>
                <div style={{ fontSize: 20, color: "black", fontWeight: 600 }}>
                  WoorkBook key
                </div>
              </div>
              <Divider
                orientation="horizontal"
                flexItem
                sx={{
                  border: "1px solid rgba(221,218,218,0.67)",
                  padding: "0px",
                }}
              />
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  rowGap: "10px",
                }}
              >
                <div
                  style={{ fontSize: 21, color: "black", fontWeight: "bold" }}
                >
                  Number System
                </div>
                <div style={{ display: "flex", flexDirection: "row" }}>
                  <GrayButton sx={{ borderRadius: 150 }}>Chapter 6</GrayButton>
                </div>
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <img src={number} alt={""} width={"100%"} height={300} />
                </div>
                <div style={{ color: "rgb(66, 61, 92)", fontWeight: "600" }}>
                  Overview:
                </div>
                <div>
                  Rational number are the numbers that can be written in the
                  form p/q, where p and q integer and q 0. irrational number are
                  the numbers that cannot be written in the form p/q, where p
                  and q are integer and q = 0. A collection of rational and
                  irrational numbers and real numbers.
                </div>
                <div>
                  This chapter deal Euclid's dividion algorithm and fundamental
                  thorems of arithmertic Euclids division algorithm state the
                  divisibility of integer. We can find HCF of given.
                </div>
                <div>Number with the help of algorithm.</div>
                <Divider
                  orientation="horizontal"
                  flexItem
                  sx={{
                    border: "1px solid rgba(221,218,218,0.67)",
                    padding: "0px",
                  }}
                />
                <Box sx={{ flexGrow: 1 }}>
                  <TabContext value={tab}>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        rowGap: "10px",
                      }}
                    >
                      <TabList
                        onChange={(e, value) => tabHandle(e, value)}
                        aria-label="lab API tabs example"
                        textColor="inherit"
                        TabIndicatorProps={{
                          style: {
                            background: "#061ca5",
                            height: "10px",
                            top: "50px",
                          },
                        }}
                        // indicatorColor={false}
                        variant={smallScreen ? "scrollable" : "standard"}
                      >
                        {data?.map((item) => {
                          return (
                            <Tab
                              label={item?.label}
                              value={item?.label}
                              sx={{
                                background:
                                  tab === item?.label
                                    ? "#1a1588"
                                    : "rgb(220,219,219)",
                                color:
                                  tab === item?.label ? "white" : "#1a1588",
                                borderRadius: "10px",
                                marginBottom: "5px",
                                textTransform: "none",
                                fontWeight: 600,
                                opacity: "1",
                                marginRight: "10px",
                                // width: "100%",
                              }}
                              key={item.id}
                            />
                          );
                        })}
                      </TabList>
                      <Divider
                        orientation="horizontal"
                        flexItem
                        sx={{
                          border: "1px solid rgba(221,218,218,0.67)",
                          padding: "0px",
                        }}
                      />
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          rowGap: "10px",
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            columnGap: "10px",
                            padding: "10px",
                          }}
                        >
                          <PsychologyAltOutlinedIcon
                            sx={{ color: "#1a1588" }}
                          />
                          <div
                            style={{
                              fontSize: 21,
                              fontWeight: "bolder",
                              color: "rgb(253 47 78)",
                            }}
                          >
                            {tab}
                          </div>
                        </div>
                        <Divider orientation="horizontal" flexItem />
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            rowGap: "1rem",
                          }}
                        >
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              rowGap: "5px",
                            }}
                          >
                            <div style={{ fontWeight: 600, color: "blue" }}>
                              Euclid's Division Lemma
                            </div>
                            <div>
                              Given positive integers a and b, there exist
                              unique integer q and r satisfying a = bq + r ,{" "}
                              {`where 0 < r <b,`}
                            </div>
                            <div>Dividend = Divisor * Quotient + Remainder</div>
                          </div>
                          <div style={{ fontWeight: 600, color: "blue" }}>
                            Euclid's Division Algoithm
                          </div>
                          <div style={{ paddingLeft: "20px" }}>
                            <ul>
                              <li>It is based onEuclid's division lemma.</li>
                              <li>
                                The HCF of two positive integer can be obtained
                                as follows:
                              </li>
                            </ul>
                          </div>
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              rowGap: "5px",
                            }}
                          >
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "row",
                                columnGap: "2px",
                              }}
                            >
                              <span style={{ fontWeight: 600 }}>Step 1:</span>
                              <div>
                                Apply the division lemma to find q and r where a
                                = bq + 1, {`0 < 1 < b`}
                              </div>
                            </div>
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "row",
                                columnGap: "2px",
                              }}
                            >
                              <div style={{ fontWeight: 600 }}>Step 2:</div>
                              <div>
                                If r = 0, the HCF is b. if r % 0, apply Euclid's
                                lemma to b and r.
                              </div>
                            </div>
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "row",
                                columnGap: "2px",
                              }}
                            >
                              <div style={{ fontWeight: 600 }}>Step 3:</div>
                              <div>
                                Continus the process till the remainder is zero.
                                The divisor at this stage will be HCF (a,b).
                                Also, HCF (a,b) = HCF (b,r).
                              </div>
                            </div>
                          </div>
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              columnGap: "5px",
                            }}
                          >
                            <div style={{ fontWeight: 600, color: "blue" }}>
                              Solved Example 1
                            </div>
                            <div style={{ fontWeight: 600 }}>
                              :Use Euclid's algorithm to find the HCF of 225 and
                              135
                            </div>
                          </div>
                          <div
                            style={{
                              fontWeight: 600,
                              color: "rgb(0, 184, 93)",
                            }}
                          >
                            Solution:
                          </div>
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              rowGap: "5px",
                            }}
                          >
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "row",
                                columnGap: "2px",
                              }}
                            >
                              <span style={{ fontWeight: 600 }}>Step 1:</span>
                              <div>
                                Since 225 135, We apply the division lemma to
                                225 and 135, to get 225 = 135 * 1 + 90.
                              </div>
                            </div>
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "row",
                                columnGap: "2px",
                              }}
                            >
                              <div style={{ fontWeight: 600 }}>Step 2:</div>
                              <div>
                                Since the remainder (90) 0, We apply the
                                division lemma to 135 and 90, to get 135 = 90 *
                                1 + 45
                              </div>
                            </div>
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "row",
                                columnGap: "4px",
                              }}
                            >
                              <div style={{ fontWeight: 600 }}>Step 3:</div>
                              <div>
                                We consider the new divisor (90) and the new
                                remainder (45), and apply the division lemma to
                                get 90 = 45 * 2 +0
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </TabContext>
                </Box>
              </div>
            </Paper>
          ) : selectedMenu === "Video" ||
            checkLastIndexHandler(selectedMenu, "mp4") ||
            checkLastIndexHandler(selectedMenu, "mpeg") ||
            checkLastIndexHandler(selectedMenu, "mpg") ||
            checkLastIndexHandler(selectedMenu, "webm") ||
            checkLastIndexHandler(selectedMenu, "avi") ? (
            <Paper
              sx={{
                padding: "20px",
                display: "flex",
                flexDirection: "column",
                rowGap: "10px",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  columnGap: "10px",
                  alignItems: "center",
                  padding: "10px 0px",
                }}
              >
                <div
                  style={{
                    background: "#ffcc00",
                    borderRadius: "150px",
                    width: "1.5vw",
                    height: "1.5vw",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <PsychologyAltOutlinedIcon
                    style={{ color: "white", fontSize: "1.3vw" }}
                  />
                </div>
                <div style={{ fontSize: 20, color: "black", fontWeight: 600 }}>
                  Video
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  width: "100%",
                  background: "rgb(241, 237, 237)",
                  padding: "20px",
                  borderRadius: "10px",
                  height: "100%",
                  // minHeight: "100vh",
                }}
              >
                <ReactPlayer
                  ref={videoRef}
                  url={selectedUrl}
                  className="react-player"
                  playing={isPlaying}
                  controls="true"
                  width="100%"
                  height="450px"
                  progressInterval={500}
                  onDuration={(d) => setDuration(d)}
                  onProgress={(o) => {
                    setProgressObj(o);
                  }}
                  onReady={(r) => console.log("ready", r)}
                  onEnded={() => setPlaying(false)}
                />
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    rowGap: "5px",
                  }}
                >
                  {/* <div style={{ fontWeight: 600 }}>
                    {nameHandler(selectedUrl)}
                  </div> */}
                </div>
              </div>
            </Paper>
          ) : selectedMenu === "Audio" ||
            checkLastIndexHandler(selectedMenu, "mp3") ||
            checkLastIndexHandler(selectedMenu, "wav") ||
            checkLastIndexHandler(selectedMenu, "wma") ||
            checkLastIndexHandler(selectedMenu, "aac") ||
            checkLastIndexHandler(selectedMenu, "ogg") ? (
            <Paper
              sx={{
                padding: "20px",
                display: "flex",
                flexDirection: "column",
                rowGap: "10px",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  columnGap: "10px",
                  alignItems: "center",
                  padding: "10px 0px",
                }}
              >
                <div
                  style={{
                    background: "#ffcc00",
                    borderRadius: "150px",
                    width: "1.5vw",
                    height: "1.5vw",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <AudiotrackOutlinedIcon
                    style={{ color: "white", fontSize: "1.3vw" }}
                  />
                </div>
                <div style={{ fontSize: 20, color: "black", fontWeight: 600 }}>
                  Audio
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  width: "100%",
                  background: "rgb(241, 237, 237)",
                  padding: "20px",
                  borderRadius: "10px",
                  height: "100%",
                  // minHeight: "100vh",
                }}
              >
                <ReactAudioPlayer src={selectedUrl} autoPlay={false} controls  controlsList="nodownload"/>
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    rowGap: "5px",
                  }}
                >
                  {/* <div style={{ fontWeight: 600 }}>
                    {nameHandler(selectedUrl)}
                  </div> */}
                </div>
              </div>
            </Paper>
          ) : selectedMenu === "Presentation" ||
            checkLastIndexHandler(selectedMenu, "pptx") ||
            checkLastIndexHandler(selectedMenu, "xlsx") ||
            checkLastIndexHandler(selectedMenu, "docx") ||
            checkLastIndexHandler(selectedMenu, "doc") ||
            checkLastIndexHandler(selectedMenu, "pdf") ||
            checkLastIndexHandler(selectedMenu, "xls") ||
            checkLastIndexHandler(selectedMenu, "ppt") ? (
            <Paper
              sx={{
                padding: "20px",
                display: "flex",
                flexDirection: "column",
                rowGap: "10px",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  columnGap: "10px",
                  alignItems: "center",
                  padding: "10px 0px",
                }}
              >
                <div
                  style={{
                    background: "#ffcc00",
                    borderRadius: "150px",
                    width: "2.5vw",
                    height: "1.5vw",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <PsychologyAltOutlinedIcon
                    style={{ color: "white", fontSize: "1.3vw" }}
                  />
                </div>
                <div style={{ fontSize: 20, color: "black", fontWeight: 600 }}>
                  Presentation
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  width: "100%",
                  background: "rgb(241, 237, 237)",
                  padding: "15px",
                  borderRadius: "10px",
                  height: "100%",
                  // minHeight: "100vh",
                  flexDirection: "column",
                }}
              >
                <div
                  style={{
                    height: "520px",
                    overflow: "auto",
                    padding: "10px",
                    width: "100%",
                  }}
                >
                  {/* <DocViewer
                    width={"100%"}
                    pluginRenderers={DocViewerRenderers}
                    documents={[{ uri: selectedUrl }]}
                    theme={{
                      primary: "rgb(241,237,237)",
                     
                    }}
                    style={{ height: "600px" }}
                  /> */}

                  <iframe
                    src={`https://view.officeapps.live.com/op/embed.aspx?src=${selectedUrl}`}
                    style={{ width: "100%", height: "100%" }}
                    frameBorder="0"
                  />
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    columnGap: "2px",
                    alignItems: "center",
                    background: "rgb(192 190 190)",
                    borderRadius: "150px",
                    marginTop: "10px",
                  }}
                >
                  <PresentationButton
                    onClick={previousPresentationPage}
                    disabled={presentationPageNumber <= 1}
                    sx={{
                      borderTopLeftRadius: "150px",
                      borderTopRightRadius: "5px",
                      borderBottomRightRadius: "5px",
                      borderBottomLeftRadius: "150px",
                    }}
                  >
                    <ArrowDropDownIcon />
                  </PresentationButton>
                  <TextField
                    type={"number"}
                    sx={{
                      width: "60px",
                      margin: "2px",
                      background: "white",
                      borderRadius: "3px",
                      // height: "50px",
                    }}
                    value={presentationPageNumber}
                    onChange={(e) => {
                      const number = e.target.value;
                      if (number) {
                        setPresentationPageNumber(Number(number));
                      } else {
                        setPresentationPageNumber("");
                      }
                    }}
                    size={"small"}
                  />
                  <PresentationButton
                    onClick={nextPresentationPage}
                    disabled={presentationPageNumber >= presentationNumPages}
                    sx={{
                      borderBottomRightRadius: "150px",
                      borderTopRightRadius: "150px",
                      borderTopLeftRadius: "5px",
                      borderBottomLeftRadius: "5px",
                    }}
                  >
                    <ArrowDropUpIcon />
                  </PresentationButton>
                </div>
              </div>
            </Paper>
          ) : selectedMenu === "Revision" ? (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                background: "rgb(204, 204, 204)",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <div
                style={{
                  width: "100%",
                  background: "rgb(241,237,237)",
                  padding: "10px",
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <div>{nameHandler(pdf)}</div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    columnGap: "10px",
                  }}
                >
                  <TextField
                    type={"number"}
                    size={"small"}
                    sx={{ width: 100, background: "white" }}
                    value={enterPageNo}
                    onChange={(e) => pageChangeHandler(e.target.value)}
                  />
                  <WhiteButton
                    onClick={pageHandler}
                    disabled={enterPageNo?.length === 0}
                  >
                    Go To
                  </WhiteButton>
                </div>
              </div>
              <div
                style={{
                  height: "550px",
                  overflow: "auto",
                  padding: "10px",
                  maxWidth: "1300px",
                }}
              >


                <iframe
                  src={`https://view.officeapps.live.com/op/embed.aspx?src=${selectedUrl}`}
                  style={{ width: "100%", height: "100%" }}
                  frameBorder="0"
                />
                {/* <DocViewer
                  pluginRenderers={DocViewerRenderers}
                  documents={[{ uri: selectedUrl }]}
                  theme={{
                    primary: "rgb(241,237,237)",
                    
                    disableThemeScrollbar: true,
                  }}
                  style={{ height: "600px" }}
                /> */}
              </div>
              <div
                style={{
                  background: "rgb(61 60 60)",
                  width: "100%",
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                  padding: "10px",
                  color: "white",
                  alignItems: "center",
                  columnGap: "10px",
                  fontSize: "0.93vw",
                }}
              >
                <div style={{ color: "white" }}>
                  {pageNumber || (numPages ? 1 : "--")} / {numPages || "--"}
                </div>
                <Divider
                  sx={{
                    border: "0.1px solid white",
                    padding: "0px",
                  }}
                  orientation="vertical"
                  flexItem
                />
                <IconButton
                  onClick={nextPage}
                  disabled={pageNumber >= numPages}
                >
                  <ArrowCircleUpIcon sx={{ color: "white" }} />
                  {/*Next*/}
                </IconButton>
                <IconButton onClick={previousPage} disabled={pageNumber <= 1}>
                  <ArrowCircleDownIcon sx={{ color: "white" }} />
                  {/*prev*/}
                </IconButton>
                <Divider
                  orientation="vertical"
                  flexItem
                  sx={{
                    border: "0.1px solid white",
                    padding: "0px",
                  }}
                />
                <IconButton onClick={addScale}>
                  <AddCircleOutlineIcon
                    sx={{ color: "white", fontSize: "1.5vw" }}
                  />
                </IconButton>
                <IconButton onClick={removeScale} disabled={scale <= 1}>
                  <RemoveCircleOutlineIcon
                    sx={{ color: "white", fontSize: "1.5vw" }}
                  />
                </IconButton>
                <Divider
                  orientation="vertical"
                  flexItem
                  sx={{
                    border: "0.1px solid white",
                    padding: "0px",
                  }}
                />
                <IconButton onClick={rightRotateHandler}>
                  <Rotate90DegreesCwOutlinedIcon
                    sx={{ color: "white", fontSize: "1.5vw" }}
                  />
                </IconButton>
                <IconButton onClick={leftRotateHandler}>
                  <Rotate90DegreesCcwOutlinedIcon
                    sx={{ color: "white", fontSize: "1.5vw" }}
                  />
                </IconButton>
              </div>
            </div>
          ) : (
            <>
              {
                checkLastIndexHandler(selectedMenu, "html") ||
                checkLastIndexHandler(selectedMenu, "jpg") ||
                checkLastIndexHandler(selectedMenu, "jpeg") ||
                checkLastIndexHandler(selectedMenu, "svg") ||
                checkLastIndexHandler(selectedMenu, "bmp") ||
                checkLastIndexHandler(selectedMenu, "tif") ||
                checkLastIndexHandler(selectedMenu, "tiff") ||
                checkLastIndexHandler(selectedMenu, "raw") ||
                (checkLastIndexHandler(selectedMenu, "png") && (
                  <Paper
                    sx={{
                      padding: "20px",
                      display: "flex",
                      flexDirection: "column",
                      rowGap: "10px",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        columnGap: "10px",
                        alignItems: "center",
                        padding: "10px 0px",
                      }}
                    >
                      <div
                        style={{
                          background: "#ffcc00",
                          borderRadius: "150px",
                          width: "1.5vw",
                          height: "1.5vw",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <PsychologyAltOutlinedIcon
                          style={{ color: "white", fontSize: "1.3vw" }}
                        />
                      </div>
                      <div
                        style={{
                          fontSize: 20,
                          color: "black",
                          fontWeight: 600,
                        }}
                      >
                        Image
                      </div>
                    </div>
                    <div>{nameHandler(selectedUrl)}</div>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        width: "100%",
                        background: "rgb(241, 237, 237)",
                        padding: "20px",
                        borderRadius: "10px",
                        height: "100%",
                        // minHeight: "100vh",
                      }}
                    >

                      <iframe
                        height={"550px"}
                        width={"100%"}
                        src={selectedUrl}
                        title={`${nameHandler(selectedUrl)}`}
                      />


                      {/*  <DocViewer*/}
                      {/*    pluginRenderers={DocViewerRenderers}*/}
                      {/*    documents={[*/}
                      {/*      {*/}
                      {/*        uri: selectedUrl,*/}
                      {/*      },*/}
                      {/*    ]}*/}
                      {/*    theme={{*/}
                      {/*      primary: "rgb(241,237,237)",*/}
                      {/*      disableThemeScrollbar: true,*/}
                      {/*    }}*/}
                      {/*    style={{ height: "600px" }}*/}
                      {/*  />*/}
                    </div>
                  </Paper>
                ))}
            </>
          )}
        </div>
      </div>
      {showSectionModal && (
        <SelectSectionModal
          open={showSectionModal}
          close={() => setShowSectionModal(false)}
          sectionList={Section}
          selectedSection={selectedSection}
          setSelectedSection={setSelectedSection}
        />
      )}
    </div>
  );
};
export default Principal_ViewContent;
