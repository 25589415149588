import axios from 'axios'

function map_post(mapInputs) {
    const token = sessionStorage.getItem("token")
    let item = {
        mapType: mapInputs.mapType,
        mapUrl: sessionStorage.getItem("mapUrl") || mapInputs?.mapUrl,
        title: mapInputs.title
    }
    axios.post(`${process.env.REACT_APP_SWAGGER_URL}v1/api/content/map`, item, {
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`
        }
    })
        .then((res) => {
            // console.log(res)
        })
        .catch(err => console.log(err))
}

export default map_post