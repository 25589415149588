import {
  Box,
  Modal,
  Typography,
  Grid,
  styled,
  Button,
  Radio,
  TextField,
} from "@mui/material";
import contentApprovalStyles from "../css/contetnApproval.module.css";
import { blue, orange } from "@mui/material/colors";
import "../css/Student/Student_Enrich_Quiz_CS.css";
import Checkbox from "@mui/material/Checkbox";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import RadioButtonUncheckedIcon from "@mui/icons-material/RadioButtonUnchecked";
import CancelIcon from "@mui/icons-material/Cancel";

import Cs_img from "../../../src/img/CS-img.jpeg";
import GreenAddButton from "../../components/common/GreenAddButton";
import { useEffect, useState } from "react";
import axios from "axios";
import { CheckBoxOutlineBlank } from "@mui/icons-material";
import TextArea from "antd/es/input/TextArea";

const CustomTextField = styled(TextField)(({ }) => ({
  width: "96%",
  boxShadow: "0px 3px 6px #00000029",
  borderRadius: "2px !important",
  "&.css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input.Mui-disabled": {
    opacity: "1 !important",
    background: "rgb(255 255 255 / 31%) !important",
    border: "1px solid #B5B6B6",
    borderRadius: "2px !important",
  },
}));

const CustomRadioIcon = styled("span")(({ theme }) => ({
  borderRadius: "50%",
  width: 16,
  height: 16,
  color: orange[600],
  boxShadow: "inset 0 0 0 3px orange, inset 0 -1px 0 orange",
  backgroundColor: theme.palette.mode === "dark" ? "#394b59" : "#f5f8fa",
  backgroundImage:
    theme.palette.mode === "dark"
      ? "linear-gradient(180deg,hsla(0,0%,100%,.05),hsla(0,0%,100%,0))"
      : "linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))",
  ".Mui-focusVisible &": {
    outline: "2px auto orange",
    color: orange[600],
    outlineOffset: 2,
  },
  "input:hover ~ &": {
    backgroundColor: theme.palette.mode === "dark" ? "#30404d" : "#ebf1f5",
  },
  "input:disabled ~ &": {
    boxShadow: "none",
    background:
      theme.palette.mode === "dark"
        ? "rgba(57,75,89,.5)"
        : "rgba(206,217,224,.5)",
  },
}));

const CustomRadioCheckedIcon = styled(CustomRadioIcon)({
  backgroundColor: orange[600],
  backgroundImage:
    "linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))",
  cursor: "default",

  "&:before": {
    display: "block",
    width: 16,
    height: 16,
    backgroundImage: "radial-gradient(#fff,#fff 28%,transparent 32%)",
    content: '""',
  },
  "input:hover ~ &": {
    backgroundColor: orange[800],
  },
});

function CustomRadio(props) {
  return (
    <Radio
      disableRipple
      color="default"
      checkedIcon={<CustomRadioCheckedIcon />}
      icon={<CustomRadioIcon />}
      {...props}
      className="cursorDefault"
    />
  );
}

const CustomHead = styled(Typography)(({ theme }) => ({
  '&': {
    color: orange[600],
  },
}))

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 1200,
  bgcolor: "background.paper",
  // border: "2px solid #000",
  boxShadow: 24,
  borderRadius: 4,
  height: 600,
  p: 4,
};
const PreviewModal = ({
  open,
  close,
  questionslength,
  currentindex,
  questionPreview,
  allquestions,
  imgUrl,
  questionType,
  subQuestions,
  isSubQuestions,
}) => {

  useEffect(() => {
    sessionStorage.removeItem("mainQstn")
  }, [])
  const [question, setQuestion] = useState();
  const [answers, setAnswers] = useState([]);
  const token = sessionStorage.getItem("token");
  const [currentIndex, setCurrentIndex] = useState();
  const [imagUrl, setImagUrl] = useState();
  const [mapUrl, setMapUrl] = useState();
  const [mapTitle, setMapTitle] = useState();
  const [marks, setMarks] = useState();
  const [qtype, setqtype] = useState();
  const [subqts, setSubQts] = useState();
  const [isub, setSub] = useState();
  useEffect(() => {
    // console.log("questionPreview ", questionPreview)
    if (Object.keys(questionPreview || {}).length > 0) {
      setQuestion(questionPreview);
      // if(questionPreview?.questionTypeCode == "FILL"){
      //   setAnswers(questionPreview?.options.length > 0 ? questionPreview?.options[0][0]?.answerKey : []);
      // }else{
      setAnswers(
        questionPreview?.options.length > 0 ? questionPreview?.options[0] : []
      );
    }
    //setAnswers(questionPreview?.options.length > 0 ? questionPreview?.options[0][0]?.answerKey : []);
    setCurrentIndex(currentindex);
    setImagUrl(imgUrl);
    setqtype(questionType);
    setMapUrl(questionPreview?.mapUrl)
    setMapTitle(questionPreview?.mapTitle)
    setMarks(questionPreview?.marks)
    setSubQts(subQuestions);
    setSub(isSubQuestions);
    //}
  }, [questionPreview, imgUrl, mapUrl, mapTitle, marks, questionType, subQuestions, isSubQuestions]);

  const showPreviewModal = (currentquestionindex) => {
    setCurrentIndex(currentquestionindex);
    setAnswers([]);
    setqtype("");
    const id = allquestions[currentquestionindex]?.id;
    axios
      .get(
        `${process.env.REACT_APP_SWAGGER_URL}v1/api/content/question/${id}?showCorrectAnswer=true`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then(function (response) {
        // console.log("response  ",response)
        setImagUrl(response?.data?.data?.imageUrl);
        setMapUrl(response?.data?.data?.mapUrl);
        setMapTitle(response?.data?.data?.mapTitle);
        setMarks(response?.data?.data?.marks);
        setQuestion(response?.data?.data);
        setqtype(response?.data?.data?.questionTypeCode);
        // if(response?.data?.data?.questionTypeCode == "FILL"){
        //   setAnswers(response?.data?.data?.options.length > 0 ? response?.data?.data?.options[0][0]?.answerKey : []);
        // }else{
        setAnswers(
          response?.data?.data?.options.length > 0
            ? response?.data?.data?.options[0]
            : []
        );
        if (response?.data?.data?.hasSubQuestions) {
          axios
            .get(
              `${process.env.REACT_APP_SWAGGER_URL}v1/api/content/question/${response?.data?.data?.id}/sub-questions?showCorrectAnswer=true`,
              {
                headers: {
                  "Content-Type": "application/json",
                  Authorization: `Bearer ${token}`,
                },
              }
            )
            .then(function (response) {
              setSub(true);
              setSubQts(response?.data?.data);
            });
        } else {
          setSubQts([]);
          setSub(false);
        }
        // }
      })
      .catch((err) => console.log(err));
  };
  // console.log('question ++++++++++',question?.explanation);

  return (
    <Modal
      open={open}
      onClose={close}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        {qtype != "MATCH" && (
          <>
            <div>
              <div
                style={{ display: "flex", justifyContent: "space-between" }}
              ></div>
              {
                // console.log("question ", question)
              }
              <div className="CS_div" style={{ height: "500px" }}>
                {/*---------------------------| Quiz Case-Study Para & Imge Division Starts Here |---------------------------*/}
                <div className="Para_and_img">
                  <CustomHead>{qtype === "TEXT" && `Text Question` || qtype === "MAP_PHYSICAL" && "Map Physical Question" || qtype === "MAP_POLITICAL" && "Map Political Question"}</CustomHead>
                  {/* {qtypeType === "SIMPLE" ? <CustomHead>{qtype === "TEXT" && `Simple Text Questions` || qtype === "MAP_PHYSICAL" && "Simple Physical Questions" || qtype === "MAP_POLITICAL" && "Simple Political Questions"}</CustomHead> : null} */}
                  <div className="head_1"></div>
                  <div>
                    <h4>Question :-</h4>
                    <p
                      className="head_3"
                      dangerouslySetInnerHTML={{ __html: question?.question }}
                    ></p>
                  </div>
                  <div className="Para_div_1">
                    {/* <h4>Explanation1:-</h4> */}
                    <p
                      className="Para"
                      dangerouslySetInnerHTML={{
                        __html: question?.explanation,
                      }}
                    ></p>
                  </div>
                  {imagUrl && (<div className="Para_div_1">
                    <h4>Uploaded Image</h4>
                    <div>
                      <img src={imagUrl} width="50%" height="240px" />
                    </div>
                  </div>)}
                  {
                    // console.log("!isub ",!isub,isub,qtype,question?.mapUrl)
                  }
                  {(mapUrl && isub) && (<div className="Para_div_1">
                    <h3>{mapTitle || question?.mapTitle}</h3>
                    <img src={mapUrl || question?.mapUrl} width="50%" height="240px" />
                  </div>)}


                </div>
                {
                  // console.log("qtype ", qtype, isub, !isub && (qtype === "MAP_PHYSICAL" || qtype === "MAP_POLITICAL"), "question ", question)
                }
                <div className="Qstns_and_Ans" style={{ Height: "430px", overflow: "auto" }}>
                  {!isub && (qtype === "MAP_PHYSICAL" || qtype === "MAP_POLITICAL") && (
                    <>
                      <div style={{ marginBottom: "10px" }}>
                        <h4>Number Of Marks  : {marks}</h4>
                      </div>
                      <Grid
                        item
                        xs={12}
                        md={6}
                        lg={6}
                        sx={{
                          height: "39vw",
                          overflow: "auto",
                          "&::-webkit-scrollbar": { width: 20 },
                          "&::-webkit-scrollbar-track": {
                            backgroundColor: "orange",
                          },
                        }}
                      >
                        {/* {
                          console.log("question ", question, question?.mapUrl)
                        } */}
                        <div>
                          Uploaded Map
                        </div>
                        <h3>{mapTitle}</h3>
                        <img src={question?.mapUrl} width="50%" height="240px" />
                        <div>
                          Answer
                        </div>
                        <TextArea rows={5} id="fullWidth" value={""} disabled />
                      </Grid>
                    </>
                  )}
                  {(!isub && qtype === "TEXT") && (
                    <>
                      <div style={{ marginBottom: "10px" }}>
                        <h4>Number Of Marks : {marks}</h4>
                      </div>
                      <Grid
                        item
                        xs={12}
                        md={6}
                        lg={6}
                        sx={{
                          height: "39vw",
                          overflow: "auto",
                          "&::-webkit-scrollbar": { width: 20 },
                          "&::-webkit-scrollbar-track": {
                            backgroundColor: "orange",
                          },
                        }}
                      >
                        <div>
                          Answer
                        </div>
                        <TextArea rows={5} id="fullWidth" value={""} disabled />
                      </Grid>
                    </>
                  )}
                  {(!isub && qtype === "MCQ") && (
                    <Grid
                      item
                      xs={12}
                      md={6}
                      lg={6}
                      sx={{
                        height: "39vw",
                        overflow: "auto",
                        "&::-webkit-scrollbar": { width: 20 },
                        "&::-webkit-scrollbar-track": {
                          backgroundColor: "orange",
                        },
                      }}
                    >
                      <Typography
                        id="modal-modal-title"
                        variant="h6"
                        component="h2"
                        sx={{
                          color: "#1B1B1B",
                          font: "normal normal 600 16px/25px Poppins",
                        }}
                      >
                        Answer
                      </Typography>
                      <Grid container spacing={4} sx={{ p: 2 }}>
                        {answers.map((v) => {
                          return (
                            <Grid
                              item
                              xs={12}
                              mg={12}
                              lg={12}
                              style={{ display: "flex" }}
                            >
                              <div
                                id="modal-modal-description"
                                style={{
                                  paddingRight: "20px",
                                  paddingTop: "40px",
                                }}
                                sx={{
                                  mt: 2,
                                  mr: 2,
                                  color: "#000000",
                                  font: "normal normal normal 20px/30px Poppins",
                                }}
                              >
                                {String.fromCharCode(v)}
                              </div>
                              <div
                                style={{
                                  width: "100%",
                                  height: "auto",
                                  minHeight: "60px",
                                  border: "1px solid #dbdee6",
                                  fontSize: "18px",
                                  color: "#1b1b1b",
                                  opacity: "0.9",
                                  marginTop: "30px",
                                  lineHeight: "1",
                                  padding: "10px",
                                  borderRadius: "5px",
                                  position: "relative",
                                  paddingLeft: "45px",
                                }}
                              >
                                <CustomRadio
                                  id="fullWidth"
                                  sx={{
                                    color: "white",
                                    position: "absolute",
                                    top: "10px",
                                    left: "10px",
                                    "&.Mui-checked": {
                                      color: "rgba(0, 0, 0, 0.26)",
                                    },
                                  }}
                                  disabled={!v?.correctAnswer}
                                  checked={v?.correctAnswer}
                                />
                                <p
                                  className="head_3"
                                  dangerouslySetInnerHTML={{ __html: v?.htmlTag }}
                                ></p>
                                {/* {v?.answerKey} */}
                              </div>
                            </Grid>
                          );
                        })}
                      </Grid>
                    </Grid>
                  )}
                  {!isub && qtype === "MSQ" && (
                    <Grid
                      item
                      xs={12}
                      md={6}
                      lg={6}
                      sx={{
                        height: "39vw",
                        overflow: "auto",
                        "&::-webkit-scrollbar": { width: 20 },
                        "&::-webkit-scrollbar-track": {
                          backgroundColor: "orange",
                        },
                      }}
                    >
                      <Typography
                        id="modal-modal-title"
                        variant="h6"
                        component="h2"
                        sx={{
                          color: "#1B1B1B",
                          font: "normal normal 600 16px/25px Poppins",
                        }}
                      >
                        Answer
                      </Typography>
                      <Grid container spacing={4} sx={{ p: 2 }}>
                        {answers.map((value) => {
                          return (
                            <Grid
                              item
                              xs={12}
                              mg={12}
                              lg={12}
                              style={{ display: "flex" }}
                            >
                              <div
                                id="modal-modal-description"
                                style={{
                                  paddingRight: "20px",
                                  paddingTop: "40px",
                                }}
                                sx={{
                                  mt: 2,
                                  mr: 2,
                                  color: "#000000",
                                  font: "normal normal normal 20px/30px Poppins",
                                }}
                              >
                                {String.fromCharCode(value)}
                              </div>
                              <div
                                style={{
                                  width: "100%",
                                  height: "auto",
                                  minHeight: "60px",
                                  border: "1px solid #dbdee6",
                                  fontSize: "18px",
                                  color: "#1b1b1b",
                                  opacity: "0.9",
                                  marginTop: "30px",
                                  lineHeight: "1",
                                  padding: "10px",
                                  borderRadius: "5px",
                                  borderRadius: "5px",
                                  position: "relative",
                                  paddingLeft: "60px",
                                  paddingTop: "20px",
                                }}
                              >
                                <Checkbox
                                  id="fullWidth"
                                  sx={{
                                    color: "#001AF3",
                                    position: "absolute",
                                    top: "10px",
                                    left: "10px",
                                    cursor: "default",
                                    "&.Mui-checked": {
                                      color: "rgba(0, 0, 0, 0.26)",
                                    },
                                  }}
                                  disabled={!value?.correctAnswer}
                                  checked={value?.correctAnswer}
                                />
                                <div dangerouslySetInnerHTML={{ __html: value?.htmlTag }}></div>
                                {/* {value?.answerKey} */}
                              </div>
                            </Grid>
                          );
                        })}
                      </Grid>
                    </Grid>
                  )}
                  {!isub && qtype === "FILL" && (
                    <Grid
                      item
                      xs={12}
                      md={6}
                      lg={6}
                      sx={{
                        height: "39vw",
                        overflow: "auto",
                        "&::-webkit-scrollbar": { width: 20 },
                        "&::-webkit-scrollbar-track": {
                          backgroundColor: "orange",
                        },
                      }}
                    >
                      <Typography
                        id="modal-modal-title"
                        variant="h6"
                        component="h2"
                        sx={{
                          color: "#1B1B1B",
                          font: "normal normal 600 16px/25px Poppins",
                        }}
                      >
                        Answer
                      </Typography>
                      <Grid container spacing={4} sx={{ p: 2 }}>
                        {answers.map((v) => {
                          return (
                            <Grid
                              item
                              xs={12}
                              mg={12}
                              lg={12}
                              style={{ display: "flex" }}
                            >
                              <div
                                id="modal-modal-description"
                                style={{
                                  paddingRight: "20px",
                                  paddingTop: "40px",
                                }}
                                sx={{
                                  mt: 2,
                                  mr: 2,
                                  color: "#000000",
                                  font: "normal normal normal 20px/30px Poppins",
                                }}
                              >
                                {String.fromCharCode(v)}
                              </div>
                              <div
                                style={{
                                  width: "100%",
                                  height: "auto",
                                  minHeight: "60px",
                                  border: "1px solid #dbdee6",
                                  fontSize: "18px",
                                  color: "#1b1b1b",
                                  opacity: "0.9",
                                  marginTop: "30px",
                                  lineHeight: "1",
                                  padding: "10px",
                                  borderRadius: "5px",
                                  position: "relative",
                                  paddingLeft: "45px",
                                }}
                                dangerouslySetInnerHTML={{ __html: v?.htmlTag }}
                              >
                                {/* {v?.answerKey} */}
                              </div>
                            </Grid>
                          );
                        })}
                      </Grid>
                    </Grid>
                  )}
                  {isub && (
                    <Box>
                      <Grid container spacing={2} p={2}>
                        <Grid
                          item
                          xs={6}
                          md={6}
                          lg={6}
                          sx={{ display: "flex", justifyContent: "left" }}
                        >
                          <Typography
                            id="modal-modal-title"
                            variant="h6"
                            component="h2"
                            sx={{
                              color: "#00BE66",
                              font: "normal normal 600 16px/25px Poppins",
                            }}
                          >
                            {"Sub-Questions"}
                          </Typography>
                        </Grid>
                        <Grid
                          item
                          xs={6}
                          md={6}
                          lg={6}
                          sx={{ display: "flex", justifyContent: "right" }}
                        >
                          <Typography
                            id="modal-modal-title"
                            variant="h6"
                            component="h2"
                            sx={{
                              color: "#1B1B1B",
                              font: "normal normal 600 16px/25px Poppins",
                            }}
                          >
                            {/* {`Total Sub Questions - 60`} */}
                          </Typography>
                        </Grid>
                      </Grid>

                      <Grid
                        item
                        xs={12}
                        md={12}
                        lg={12}
                        sx={{
                          maxHeight: "37vw",
                          overflowY: "scroll",
                          "&::-webkit-scrollbar": {
                            width: "6px",
                            backgroundColor: "white",
                            position: "relative",
                            display: "block",
                          },
                          "&::-webkit-scrollbar-thumb": {
                            borderRadius: "10px",
                            backgroundColor: "#b4b4b4",
                          },
                          "&::-webkit-scrollbar-track": {
                            backgroundColor: "#f8f4fc",
                          },
                        }}
                      >
                        {subqts.length > 0 &&
                          subqts.map((subQues, index) => {
                            return (
                              <>
                                {/* To display uploaded sub question image and question*/}
                                {subQues.imageUrl !== null ? (
                                  <>
                                    <Grid container>
                                      <Grid
                                        item
                                        xs={10}
                                        md={10}
                                        lg={10}
                                        sx={{
                                          display: "flex",
                                          justifyContent: "left",
                                          flexWrap: "wrap",
                                        }}
                                      >
                                        <Typography
                                          id="modal-modal-title"
                                          sx={{
                                            color: "#1B1B1B",
                                            font: "normal normal 400 15px/25px Poppins",
                                            textAlign: "left",
                                          }}
                                          dangerouslySetInnerHTML={{
                                            __html: `Q${index + 1})${subQues.question
                                              }`,
                                          }}
                                        ></Typography>
                                      </Grid>
                                      <Grid
                                        item
                                        xs={2}
                                        md={2}
                                        lg={2}
                                        sx={{
                                          display: "flex",
                                          alignContent: "center",
                                          flexWrap: "wrap",
                                        }}
                                      >
                                        <img
                                          src={subQues.imageUrl}
                                          width="100%"
                                          height="auto"
                                        />
                                      </Grid>
                                      <Grid
                                        item
                                        xs={12}
                                        md={12}
                                        lg={12}
                                        sx={{
                                          display: "flex",
                                          justifyContent: "left",
                                          flexWrap: "wrap",
                                        }}
                                      >
                                        <Typography
                                          id="modal-modal-title"
                                          variant="h6"
                                          component="h2"
                                          sx={{
                                            color: "#1B1B1B",
                                            font: "normal normal 600 16px/25px Poppins",
                                          }}
                                        >
                                          Explanation
                                        </Typography>
                                        <Typography
                                          id="modal-modal-title"
                                          sx={{
                                            color: "#1B1B1B",
                                            font: "normal normal 400 15px/25px Poppins",
                                            textAlign: "left",
                                          }}
                                          dangerouslySetInnerHTML={{
                                            __html: subQues.explanation,
                                          }}
                                        ></Typography>
                                      </Grid>
                                    </Grid>
                                  </>
                                ) : (
                                  <>
                                    <Grid
                                      item
                                      xs={12}
                                      md={12}
                                      lg={12}
                                      sx={{
                                        display: "flex",
                                        justifyContent: "left",
                                        flexWrap: "wrap",
                                      }}
                                    >
                                      <Typography
                                        id="modal-modal-title"
                                        sx={{
                                          color: "#1B1B1B",
                                          font: "normal normal 400 15px/25px Poppins",
                                          textAlign: "left",
                                        }}
                                        dangerouslySetInnerHTML={{
                                          __html: `Q${index + 1})${subQues.question
                                            }`,
                                        }}
                                      ></Typography>
                                    </Grid>
                                    <Grid
                                      item
                                      xs={12}
                                      md={12}
                                      lg={12}
                                      sx={{
                                        display: "flex",
                                        justifyContent: "left",
                                        flexWrap: "wrap",
                                      }}
                                    >
                                      <Typography
                                        id="modal-modal-title"
                                        variant="h6"
                                        component="h2"
                                        sx={{
                                          color: "#1B1B1B",
                                          font: "normal normal 600 16px/25px Poppins",
                                        }}
                                      >
                                        Explanation
                                      </Typography>
                                      <Typography
                                        id="modal-modal-title"
                                        sx={{
                                          color: "#1B1B1B",
                                          font: "normal normal 400 15px/25px Poppins",
                                          textAlign: "left",
                                        }}
                                        dangerouslySetInnerHTML={{
                                          __html: subQues.explanation,
                                        }}
                                      ></Typography>
                                    </Grid>
                                  </>
                                )}
                                <Typography
                                  id="modal-modal-title"
                                  variant="h6"
                                  component="h2"
                                  sx={{
                                    color: "#1B1B1B",
                                    font: "normal normal 600 16px/25px Poppins",
                                  }}
                                >
                                  Answer
                                </Typography>
                                {/* To display answer content fields */}
                                <Grid container spacing={4} sx={{ p: 2 }}>
                                  {subQues.options[0].length > 0 &&
                                    subQues.options[0].map((value, ind) => {
                                      return subQues.questionTypeCode ===
                                        "FILL" ? (
                                        <Grid item xs={12} mg={12} lg={12}>
                                          <Box
                                            sx={{
                                              display: "flex",
                                              justifyContent: "space-between",
                                            }}
                                          >
                                            <Typography
                                              id="modal-modal-description"
                                              sx={{
                                                mt: 2,
                                                mr: 2,
                                                color: "#000000",
                                                font: "normal normal normal 20px/30px Poppins",
                                              }}
                                            >
                                              {String.fromCharCode(ind + 65)}
                                            </Typography>
                                            <CustomTextField
                                              id="fullWidth"
                                              disabled
                                              value={value.answerKey[0]}
                                              className="cursorDefault"
                                            />
                                          </Box>
                                        </Grid>
                                      ) : subQues.questionTypeCode ===
                                        "TEXT" ? (
                                        <Grid item xs={12} mg={12} lg={12}>
                                          <Box
                                            sx={{
                                              display: "flex",
                                              justifyContent: "space-between",
                                            }}
                                          >
                                            <Typography
                                              id="modal-modal-description"
                                              sx={{
                                                mt: 2,
                                                mr: 2,
                                                color: "#000000",
                                                font: "normal normal normal 20px/30px Poppins",
                                              }}
                                            >
                                              {String.fromCharCode(ind + 65)}
                                            </Typography>
                                            <CustomTextField
                                              id="fullWidth"
                                              value={""}
                                              disabled
                                            />
                                          </Box>
                                          <div>
                                            Answer
                                          </div>
                                          <TextArea rows={5} id="fullWidth" value={""} disabled />
                                        </Grid>
                                      ) : subQues.questionTypeCode === "MCQ" ? (
                                        <Grid item xs={12} mg={12} lg={12}>
                                          <Box
                                            sx={{
                                              display: "flex",
                                              justifyContent: "space-between",
                                            }}
                                          >
                                            <Typography
                                              id="modal-modal-description"
                                              sx={{
                                                mt: 2,
                                                mr: 2,
                                                color: "#000000",
                                                font: "normal normal normal 20px/30px Poppins",
                                              }}
                                            >
                                              {String.fromCharCode(ind + 65)}
                                            </Typography>
                                            <div
                                              style={{
                                                width: "100%",
                                                height: "60px",
                                                border: "1px solid #dbdee6",
                                                fontSize: "18px",
                                                color: "#1b1b1b",
                                                opacity: "0.9",
                                                lineHeight: "1",
                                                padding: "10px",
                                                borderRadius: "5px",
                                                display:"flex"
                                              }}
                                            >
                                              <CustomRadio
                                                id="fullWidth"
                                                sx={{
                                                  color: "white",
                                                  "&.Mui-checked": {
                                                    color:
                                                      "rgba(0, 0, 0, 0.26)",
                                                  },
                                                }}
                                                className="cursorDefault"
                                                checked={value.correctAnswer}
                                              />
                                              <span  dangerouslySetInnerHTML={{
                                                __html: value.htmlTag,
                                              }}></span>
                                              {/* {value.answerKey} */}
                                            </div>
                                          </Box>
                                        </Grid>
                                      ) : subQues.questionTypeCode === "MSQ" ? (
                                        <Grid item xs={12} mg={12} lg={12}>
                                          <Box
                                            sx={{
                                              display: "flex",
                                              justifyContent: "space-between",
                                            }}
                                          >
                                            <Typography
                                              id="modal-modal-description"
                                              sx={{
                                                mt: 2,
                                                mr: 2,
                                                color: "#000000",
                                                font: "normal normal normal 20px/30px Poppins",
                                              }}
                                            >
                                              {String.fromCharCode(ind + 65)}
                                            </Typography>
                                            <div
                                              style={{
                                                width: "100%",
                                                height: "60px",
                                                border: "1px solid #dbdee6",
                                                fontSize: "18px",
                                                color: "#1b1b1b",
                                                opacity: "0.9",
                                                lineHeight: "1",
                                                padding: "10px",
                                                borderRadius: "5px",
                                              }}
                                            >
                                              <Checkbox
                                                id="fullWidth"
                                                sx={{
                                                  color: "#001AF3",
                                                  cursor: "default",
                                                  "&.Mui-checked": {
                                                    color:
                                                      "rgba(0, 0, 0, 0.26)",
                                                  },
                                                }}
                                                checked={value.correctAnswer}
                                              />
                                              {value.answerKey}
                                            </div>
                                          </Box>
                                        </Grid>
                                      ) : (
                                        <>
                                          <p>There is no Sub Question</p>
                                        </>
                                      );
                                    })}
                                </Grid >
                              </>
                            );
                          })}
                      </Grid>
                    </Box>
                  )}
                  {/* Subjective Question */}
                  {/* {qtype === "MAP_PHYSICAL" || qtype === "MAP_POLITICAL" && (
                    <Grid
                      item
                      xs={12}
                      md={6}
                      lg={6}
                      sx={{
                        height: "39vw",
                        overflow: "auto",
                        "&::-webkit-scrollbar": { width: 20 },
                        "&::-webkit-scrollbar-track": {
                          backgroundColor: "orange",
                        },
                      }}
                    >
                      <div className="Para_div_1">
                        <h4>Map Image</h4>
                        <div>
                          <img src={mapUrl} width="50%" height="240px" />
                        </div>
                      </div>
                    </Grid>
                  )}

                  {qtype === "MAP_PHYSICAL" || qtype === "MAP_POLITICAL" || qtype === "TEXT" && (
                    <Grid
                      item
                      xs={12}
                      md={6}
                      lg={6}
                      sx={{
                        height: "39vw",
                        overflow: "auto",
                        "&::-webkit-scrollbar": { width: 20 },
                        "&::-webkit-scrollbar-track": {
                          backgroundColor: "orange",
                        },
                      }}
                    >
                      <div className="Para_div_1">
                        <h4>Number Of Marks : {marks}</h4>
                      </div>
                    </Grid>
                  )} */}

                  <div
                    style={{
                      justifyContent: "end",
                      position: "absolute",
                      bottom: "50px",
                      right: "50px",
                    }}
                  >
                    <GreenAddButton onClick={close}>Exit</GreenAddButton>
                    {!currentIndex == 0 && (
                      <button
                        className={contentApprovalStyles.orange_btn}
                        disabled={currentIndex == 0}
                        onClick={() => {
                          showPreviewModal(currentIndex - 1);
                        }}
                      >
                        Previous Question
                      </button>
                    )}
                    {!(currentIndex + 1 == questionslength) && (
                      <button
                        className={contentApprovalStyles.orange_btn}
                        disabled={currentIndex + 1 == questionslength}
                        onClick={() => {
                          showPreviewModal(currentIndex + 1);
                        }}
                      >
                        Next Question
                      </button>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
        {qtype == "MATCH" && (
          <>
            <Grid container p={2}>
              <Grid
                item
                xs={6}
                md={6}
                lg={6}
                sx={{ display: "flex", justifyContent: "left" }}
              >
                <Typography
                  sx={{
                    color: "#1B1B1B",
                    fontFamily: "Poppins",
                    fontWeight: "600",
                    fontSize: "16px",
                    lineHeight: "25px",
                  }}
                >
                  Match the following
                </Typography>
              </Grid>
            </Grid>
            <Grid container p={2}>
              <Grid
                item
                xs={6}
                md={6}
                lg={6}
                sx={{ display: "flex", justifyContent: "left" }}
              >
                <Typography
                  sx={{
                    color: "#1B1B1B",
                    fontFamily: "Poppins",
                    fontWeight: "600",
                    fontSize: "16px",
                    lineHeight: "25px",
                  }}
                >
                  Question
                </Typography>
              </Grid>
              {imagUrl && (<Grid
                item
                xs={6}
                md={6}
                lg={6}
                sx={{ display: "flex", justifyContent: "left" }}
              >
                <Typography
                  sx={{
                    color: "#1B1B1B",
                    fontFamily: "Poppins",
                    fontWeight: "600",
                    fontSize: "16px",
                    lineHeight: "25px",
                  }}
                >
                  Uploaded Image
                </Typography>
              </Grid>)}
              <Grid
                item
                xs={6}
                md={6}
                lg={6}
                sx={{ display: "flex", justifyContent: "left" }}
              >
                <Typography
                  dangerouslySetInnerHTML={{ __html: question?.question }}
                ></Typography>
              </Grid>
              {imagUrl && (<Grid
                item
                xs={6}
                md={6}
                lg={6}
                sx={{ display: "flex", justifyContent: "left" }}
              >
                <Typography>
                  <img src={imagUrl} width="100%" height="auto" />
                </Typography>
              </Grid>)}
            </Grid>
            <div className="MTF_DD_Box" style={{ height: "200px" }}>
              <div className="MTF_DD_Div">
                <div className="MTF_DD_Qstn_And_Field_div">
                  {/*------------------| Qstn And Field 1 |-----------------*/}
                  {answers.length > 0 &&
                    answers.map((v) => {
                      return (
                        <div className="MTF_DD_Fields_1">
                          <>
                            <div className="MTF_DD_Qstn_Field_1_B1">
                              <p>{v?.question}</p>
                            </div>
                            <div className="MTF_DD_Qstn_Field_1_B2">
                              <p>{v?.answers?.length > 0 && v?.answers[0]}</p>
                            </div>
                          </>
                        </div>
                      );
                    })}
                </div>
                {/*------------------| MTF DD Ans IMages Div |-----------------*/}
                <div>
                  <div className="MTF_DD_Ans">
                    {answers.length > 0 &&
                      answers.map((v) => {
                        return (
                          <div className="MTF_DD_Ans_Field_1">
                            <p>{v.jumbledAnswer}</p>
                          </div>
                        );
                      })}
                  </div>
                </div>
              </div>
            </div>
            <div
              style={{
                justifyContent: "end",
                position: "absolute",
                bottom: "50px",
                right: "50px",
              }}
            >
              <GreenAddButton onClick={close}>Exit</GreenAddButton>
              {!currentIndex == 0 && (
                <button
                  className={contentApprovalStyles.orange_btn}
                  disabled={currentIndex == 0}
                  onClick={() => {
                    showPreviewModal(currentIndex - 1);
                  }}
                >
                  Previous Question
                </button>
              )}
              {!(currentIndex + 1 == questionslength) && (
                <button
                  className={contentApprovalStyles.orange_btn}
                  disabled={currentIndex + 1 == questionslength}
                  onClick={() => {
                    showPreviewModal(currentIndex + 1);
                  }}
                >
                  Next Question
                </button>
              )}
            </div>
          </>
        )}
      </Box>
    </Modal >
  );
};
export default PreviewModal;