import React from 'react'
import { Box, Modal, Typography, Button, styled } from "@mui/material";
import { useNavigate } from "react-router-dom";
import dashboardContentStyles from "../../../css/dashboardContent.module.css";
import ReportGmailerrorredOutlinedIcon from "@mui/icons-material/ReportGmailerrorredOutlined";
import action__Success from "../../../../../src/img/Successfully_added.svg";
import { PropaneSharp } from '@mui/icons-material';

const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 550,
    bgcolor: "background.paper",
    boxShadow: 24,
    borderRadius: 4,
    p: 4,
};

const ConceptButtons = styled(Button)(({ }) => ({
    backgroundColor: "#9EA3D4",
    color: "#fff",
    borederRadius: "3px",
    textTransform: "none",
    fontSize: "0.8vw",
    padding: "8px 4px",
    '&:hover': {
        backgroundColor: "rgb(62 77 216)",
    }
}));

const CustomButton = styled(Button)(({ }) => ({
    color: "#FFFFFF",
    backgroundColor: '#FD8C00',
    fontFamily: ['normal', 'normal', 'medium', 'Poppins'].join(','),
    fontSize: '10px',
    borderRadius: '30px',
    width: 'auto',
    padding: "0.6vw 1vw",
    borderRadius: "3px",
    textTransform: "none",
    '&:hover': {
        backgroundColor: '#fff',
        color: "#929292",
        border: "1px solid #FD8C00",
    },
}));

const DeleteConfirmationAlert = ({ open, close, alert, caseStudy, isEditQuestion, setDisableTopFields, openPreview,setPreviewFromModal, setNewConcept, setGoToQuestionBank, onBackdropClickable }) => {
    const navigate = useNavigate();
    const onEditClick = () => {
        close();
        isEditQuestion();
        setDisableTopFields(true);
    }
    return (
        <>
            <Modal
                open={open}
                onClose={close}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                hideBackdrop={false}
                onBackdropClick={() => { onBackdropClickable ? navigate("/dashboard/QuestionBank") : navigate("/dashboard/view all questions")}}
            >
                <Box sx={style}>
                    <Typography id="modal-modal-title" variant="h6" component="h2">
                        <div style={{ display: "flex", justifyContent: "center" }}>
                            {caseStudy ?
                                <img
                                    className={
                                        dashboardContentStyles.aRoleActionBtn
                                    }
                                    src={action__Success}
                                    alt="Success-Btn"
                                    style={{ width: "18%", height: "auto" }}
                                />
                                :
                                <ReportGmailerrorredOutlinedIcon
                                    style={{ color: "#FFA700", fontSize: 120 }}
                                />
                            }
                        </div>
                    </Typography>
                    <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                        <div
                            style={{
                                display: "flex",
                                justifyContent: "center",
                            }}
                        >
                            <div
                                style={{
                                    display: "flex",
                                    justifyContent: "center",
                                    flexDirection: "column",
                                }}
                            >
                                <center>{alert}</center>
                                <center><Typography sx={{ color: "#FD8C00" }}>What do you want to do next</Typography></center>
                                <hr></hr>
                                <div
                                    style={{
                                        display: "flex",
                                        justifyContent: "center",
                                        flexDirection: "row",
                                        columnGap: "1rem",
                                        paddingTop: '20px'
                                    }}
                                >
                                    <ConceptButtons onClick={() => {close(); setDisableTopFields(false);sessionStorage.removeItem("mapUrl");sessionStorage.removeItem("mapImage");sessionStorage.removeItem("uploadedImg");sessionStorage.removeItem("logoUrl");sessionStorage.removeItem("mainQstn")}}>Create Another Question for concept</ConceptButtons>
                                    <ConceptButtons onClick={() => {setNewConcept(true); close();sessionStorage.removeItem("mapUrl");sessionStorage.removeItem("mapImage");sessionStorage.removeItem("uploadedImg");sessionStorage.removeItem("logoUrl");sessionStorage.removeItem("mainQstn")}}>Create Another Question for new concept</ConceptButtons>
                                </div>
                                <div
                                    style={{
                                        display: "flex",
                                        justifyContent: "center",
                                        flexDirection: "row",
                                        columnGap: "1rem",
                                        paddingTop: '20px'
                                    }}
                                >
                                    <CustomButton onClick={() => onEditClick()}>Edit Question</CustomButton>
                                    {/* <CustomButton onClick={() => { navigate("/dashboard/QuestionBankPreview", { state: true }) }}>Preview this Question</CustomButton> */}
                                    <CustomButton onClick={() => {openPreview(); setPreviewFromModal(true)}}>Preview this Question</CustomButton>
                                    <CustomButton onClick={() => {setGoToQuestionBank(true); setDisableTopFields(false); close()}}>Go to Question Bank</CustomButton>
                                </div>
                            </div>
                        </div>
                    </Typography>
                </Box>
            </Modal>
        </>
    )
}

export default DeleteConfirmationAlert