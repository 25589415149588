import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import { Provider } from 'react-redux';
import { store, persistor } from './store';

import { QueryClient, QueryClientProvider } from '@tanstack/react-query';

import { positions, transitions, Provider as AlertProvider } from 'react-alert';
import AlertTemplate from 'react-alert-template-basic';
import EducationContextProvider from './context/quiz/EducationContext';
import QuestionTypeContextProvider from './context/quiz/QuestionTypeContext';
import * as serviceWorker from '../src/Serviceworker';
import NotificationContextProvider from './context/notification/NotificationContext';
import { PersistGate } from 'redux-persist/integration/react';
import mixpanel from 'mixpanel-browser';


const options = {
  // timeout: 500,
  position: positions.BOTTOM_CENTER,
  transition: transitions.SCALE,
};

const queryClient = new QueryClient();
const fetchToken = () => {
  if (process.env.REACT_APP_LMS_ENV === 'DEV') {
    return process.env.REACT_APP_DEV_MIXPANEL;
  } else if (process.env.REACT_APP_LMS_ENV === 'PRE_PROD') {
    return process.env.REACT_APP_PRE_PROD_MIXPANEL
  } else if (process.env.REACT_APP_LMS_ENV === 'PROD') {
    return process.env.REACT_APP_PROD_MIXPANEL;
  }
}
const log = process.env.REACT_APP_LMS_CONSOLE == "true" ? console.log : () => { };
const mixPanelToken = fetchToken();

log('environment', process.env.REACT_APP_LMS_ENV, process.env.REACT_APP_LMS_ENV === 'DEV');
log('token', mixPanelToken);

mixpanel.init(`${mixPanelToken}`)

ReactDOM.render(
  <QueryClientProvider client={queryClient}>
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        {/* <Sample /> */}
        {/* <TextEditorNew /> */}
        <NotificationContextProvider>
          <EducationContextProvider>
            <QuestionTypeContextProvider>
              <AlertProvider template={AlertTemplate} {...options}>
                <React.StrictMode>
                  <App />
                </React.StrictMode>
              </AlertProvider>
            </QuestionTypeContextProvider>
          </EducationContextProvider>
        </NotificationContextProvider>
      </PersistGate>
    </Provider>
  </QueryClientProvider>,
  document.getElementById('root')
);
serviceWorker.unregister();