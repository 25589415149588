import React, { useEffect, useState } from "react";

//Images Used
import bubble_img from "../../../../../src/img/BB.svg";
import prev from "../../../../../src/img/Prev.svg";
import nxt from "../../../../../src/img/Nxt.svg";

//Css Pages
import "../../../css/Student/ChapterQuizKeyModal.css";

//Mui
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import { Paper, Backdrop } from "@mui/material";
import {
  Button,
  Checkbox,
  FormControl,
  TextField,
  FormControlLabel,
  FormLabel,
  Grid,
  LinearProgress,
  Radio,
  RadioGroup,
  Typography,
} from "@mui/material";
// Quiz Quistion Type Common Components
// import Student_Enrich_Quiz_MTF_DrawLines from "../Student_Enrich_Quiz_MTF_DrawLines";
import Student_Enrich_Quiz_MTF_DrawLines1 from "../Student_Enrich_Quiz_MTF_DrawLines1";

import Student_Enrich_Quiz_FIB from "../Student_Enrich_Quiz_FIB";
import Student_Enrich_Quiz_MCQ from "../Student_Enrich_Quiz_MCQ";
import Student_Enrich_Quiz_MSQ from "../Student_Enrich_Quiz_MSQ";
import Student_Enrich_Quiz_MTF_DD from "../Student_Enrich_Quiz_MTF_DD";
import student_enrich_quiz_mtf_drawline from "../Student_Enrich_Quiz_MTF_DrawLines";
import Student_Enrich_Quiz_CS from "../Student_Enrich_Quiz_CS"

import ChapterQuizKey_Cs from "./ChapterQuizKey_Cs";
import Quiz_Common_component from "../Quiz_Common_component"

// Api
import { fetch_Chapter_Quiz_Key_Api,fetch_Chapter_Quiz_Key_Api_Rprt, fetch_Chapter_Quiz_Key_Api_Report } from "../../../js/Student/Apis/Chapter_Quiz_Key_Api";
const log = process.env.REACT_APP_LMS_CONSOLE == "true" ? console.log : () => { };
const LinearProgressWithLabel = (props) => {
  // console.log('propsss', props);
  return (
    <Box sx={{ display: "flex", alignItems: "center", width: "100%" }}>
      <Box sx={{ width: "100%", mr: 1 }}>
        <Box
          sx={{
            minWidth: 35,
            display: "flex",
            justifyContent: "space-between",
            padding: "10",
          }}
        >
          <Typography
            fontSize={18}
            fontWeight={600}
            sx={{ padding: "10px 0px" }}
            dangerouslySetInnerHTML={{ __html: props.name }}
          ></Typography>
          <Typography
            variant="body2"
            fontWeight={600}
            fontSize={18}
            sx={{ padding: "10px 0px" }}
          >{`${Math.round(props.value)} out of  ${props.totalQuestions
            }`}</Typography>
        </Box>
        <LinearProgress
          variant="determinate"
          value={Math.round((100 * props.value) / props.totalQuestions)}
          sx={{ height: 10, borderRadius: "150px", color: "#FFA700", textAlign: "right", left: "40%", right: "0%", width: "60%" }}
          color={"inherit"}
        />
      </Box>
    </Box>
  );
};

function ChapterQuizKeyModal({ examId1, showModal, closeModal, paramsData }) {
  // console.log("ChapterQuizKeyModal", showModal, closeModal, paramsData, examId1)
  const modalStyle = {
    width: '90vw',
    height: '85vh',
    position: "absolute",
    // top: "50%",
    // left: "50%",
    // transform: "translate(-50%, -50%)",
    border: "none",
    outline: "none",
    bgcolor: "white",
    // borderRadius: "10px",
    display: "flex",
    flexDirection: "column",
    rowGap: "1rem",
    overflow: "auto",
    scrollbarWidth: "thin"
  };

  const modal = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%',
    width: '100%',
    backgroundColor: "white"

  };

  const [questions, setQuestions] = useState([]);
  const [currentQuestion, setCurrentQuestion] = useState(0);
  const [list, setList] = useState();
  const [attendMtfDd, setAttendMtfDd] = useState([]);
  const [attendMtfDl, setAttendMtfDl] = useState([]);
  const [attendedMtfDd, setAttendedMtfDd] = useState([]);
  const [attendedMtfDl, setAttendedMtfDl] = useState([]);


  const nextQuestionHandler = () => {
    // console.log(currentQuestion + 1 < list?.questionAnswerKeys?.length,list?.questionAnswerKeys[currentQuestion]?.questionId.length,"********************")
    if (
      currentQuestion + 1 <
      list?.questionAnswerKeys?.length
    ) {
      setCurrentQuestion(currentQuestion + 1);
    } else {
      // console.log(currentQuestion, list)
    }
  };

  const preQuestionHandler = () => {
    if (currentQuestion >= 1) {
      setCurrentQuestion(currentQuestion - 1);
    }
  };

  const currentQuestionHandler = (i) => {
    setCurrentQuestion(i);
  };

  useEffect(() => {
    const fetchSub = async () => {
      let sub = [];
      if (paramsData) {
        log("ChapterQuizKeyModal if fetch_Chapter_Quiz_Key_Api_Report")
        sub = await fetch_Chapter_Quiz_Key_Api_Report(paramsData);
      } else {
        log("ChapterQuizKeyModal else fetch_Chapter_Quiz_Key_Api_Rprt")
        sub = await fetch_Chapter_Quiz_Key_Api_Rprt();
      }
      // console.log("sub.data", sub.data)
      setList(sub.data);
    };
    fetchSub();
    setCurrentQuestion(0);
  }, []);

  return (
    <>

      {/* <Quiz_Common_component
        id="content"
        quizData={list}
        ispreview={true}
        showModalpreview={showModal}
        closeModal={closeModal}
        currentQuestion={currentQuestion}
        questions={list}
      /> */}
      <Modal sx={modal}
        open={showModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            sx: {
              backgroundColor: "white",
            },
          },
        }}
      >
        <Box sx={modalStyle} style={{ backgroundColor: "white" }}>
          <Paper>
            <LinearProgressWithLabel
              value={currentQuestion + 1}
              name={"Quiz"}
              totalQuestions={list?.questionAnswerKeys?.length}
            />
            {
              //console.log(list?.questionAnswerKeys[currentQuestion]?.matchType,"18999")
            }
            <div className="Cqk_Quiz_Wrapper">

              <div className="Cqk_OutOf">
                {/* {currentQuestion + 1} Out of {list?.questionAnswerKeys?.length}
                 */}

              </div>

              {list?.questionAnswerKeys[currentQuestion]?.questionTypeCode ===
                "CASE" ? (
                <Student_Enrich_Quiz_CS
                  list={list}
                  currentQuestion={currentQuestion}
                  ispreview={true}
                  resumeBtnClicked={true}
                  showExplanation={true}
                />
              ) : (
                ""
              )}

              {list?.questionAnswerKeys[currentQuestion]?.questionTypeCode ===
                "FILL" ? (
                <Student_Enrich_Quiz_FIB
                  list={list}
                  currentQuestion={currentQuestion}
                  ispreview={true}
                  resumeBtnClicked={true}
                  showExplanation={true}
                />
              ) : (
                ""
              )}

              {list?.questionAnswerKeys[currentQuestion]?.questionTypeCode ===
                "MSQ" ? (
                <Student_Enrich_Quiz_MSQ
                  list={list}
                  currentQuestion={currentQuestion}
                  ispreview={true}
                  resumeBtnClicked={true}
                  showExplanation={true}
                />

              ) : (
                ""
              )}

              {list?.questionAnswerKeys[currentQuestion]?.questionTypeCode ===
                "MCQ" ? (
                <Student_Enrich_Quiz_MCQ
                  list={list}
                  currentQuestion={currentQuestion}
                  ispreview={true}
                  resumeBtnClicked={true}
                  showExplanation={true}
                />
              ) : (
                ""
              )}

              {list?.questionAnswerKeys[currentQuestion]?.questionTypeCode ===
                "MATCH" &&
                list?.questionAnswerKeys[currentQuestion]?.matchType ===
                "DRAG_DROP" ? (
                <Student_Enrich_Quiz_MTF_DD
                  list={list}
                  currentQuestion={currentQuestion}
                  ispreview={true}
                  resumeBtnClicked={true}
                  showExplanation={true}
                />
              ) : list?.questionAnswerKeys &&
                list?.questionAnswerKeys[currentQuestion]?.questionTypeCode === "MATCH" &&
                (list?.questionAnswerKeys[currentQuestion]?.matchType === "draw-lines" || list?.questionAnswerKeys[currentQuestion]?.matchType === "DRAW_LINE") ? (
                <Student_Enrich_Quiz_MTF_DrawLines1
                  questions={list?.questionAnswerKeys}
                  currentQuestion={currentQuestion}
                  setAttendMtfDl={setAttendMtfDl}
                  attendMtfDl={attendMtfDl}
                  setAttendedMtfDl={setAttendedMtfDl}
                  attendedMtfDl={attendedMtfDl}
                  resumeBtnClicked={true}
                  ispreview={true}
                  showExplanation={true}
                />


              ) : (
                ""
              )}


              <div className="Cqk_BtnsDiv">
                <div className="Cqk_QstnBtns">
                  <div className="TimeTakenLabel">
                    <label>
                      Taken Time : <i>{list?.questionAnswerKeys[currentQuestion]?.totalTimeSpend ? list?.questionAnswerKeys[currentQuestion]?.totalTimeSpend : '-'}</i>
                    </label>
                  </div>
                  <button
                    id={`az-student-cqk-exitbtn`}
                    className="Cqk_ExitBtn" onClick={closeModal}>
                    <h6>Exit</h6>
                  </button>

                  {currentQuestion !== 0 && (
                    <button
                      id={`az-student-cqk-prevbtn`}
                      className="Cqk_PrevBtn"
                      onClick={preQuestionHandler}
                    >
                      <img src={prev} />
                      <h6>Previous Question</h6>
                    </button>
                  )}

                  {currentQuestion !== list?.questionAnswerKeys?.length - 1 && (
                    <button
                      id={`az-student-cqk-nextbtn`}
                      className="Cqk_NxtBtn"
                      onClick={nextQuestionHandler}
                    >
                      <h6>Next Question </h6>
                      <img src={nxt} />
                    </button>
                  )}
                </div>
              </div>
            </div>
          </Paper>
          <Paper
            style={{
              // borderRadius: "10px",
              display: "flex",
              flexDirection: "row",
              width: "100%",
            }}
          >
            <Box>

              <div>
                <div className="Cqk_Qstn_Bubble_div">
                  <div className="Cqk_Bubble_and_img">
                    <div className="Cqk_Qstn_Bubble_img">
                      <div>
                        <img src={bubble_img} />
                      </div>
                      <div>
                        <h6>
                          Quiz <span>Key</span>
                        </h6>
                      </div>
                    </div>
                    <div className="Cqk_Bubbles">
                      {list?.questionAnswerKeys?.map((item, index) => {
                        return (
                          <>
                            <div
                              id={`az-student-cqk-ques-bubble-${index}`}
                              className="Cqk_Bubble"
                              key={index}
                              style={{
                                backgroundColor:
                                  list?.questionAnswerKeys[index]?.attended ===
                                    true
                                    ? list?.questionAnswerKeys[index]
                                      ?.ansFromStudentCurrect === true
                                      ? "#00CE95"
                                      : "#EB3E68"
                                    : "#BABABA",
                              }}
                              onClick={() => currentQuestionHandler(index)}
                            >
                              <h6>{index + 1}</h6>
                            </div>
                          </>
                        );
                      })}
                    </div>
                  </div>
                </div>

                <div className="Cqk_Info_Bubbles">
                  <div className="Cqk_bubble_1">
                    <div className="Cqk_circle_1"></div>
                    <span> - Correct</span>
                  </div>
                  <div className="Cqk_bubble_2">
                    <div className="Cqk_circle_2"></div>
                    <span> - Unattempted</span>
                  </div>
                  <div className="Cqk_bubble_3">
                    <div className="Cqk_circle_3"></div>
                    <span> - Incorrect</span>
                  </div>
                </div>

              </div>
            </Box>
          </Paper>
        </Box>
      </Modal>
    </>
  );
}

export default ChapterQuizKeyModal;
