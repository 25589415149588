import axios from 'axios'

function map_content_get(setMapcontent, setFilteredData) {
    let token = sessionStorage.getItem("token");

    let result = axios.get(`${process.env.REACT_APP_SWAGGER_URL}v1/api/content/map?pageSize=1000&sortBy=createdAt`,
        {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`
            }
        })
        .then((res) => {
            // console.log(res?.data?.data?.data, "13-6-24", res?.data?.data?.data?.filter((v) => v.mapUrl !== null) || [])
            var maps = res?.data?.data?.data?.filter((v) => v.mapUrl !== null) || [];
            setMapcontent(maps);
            if (setFilteredData) {
                setFilteredData(maps);
            }
        })
        .catch(err => console.log(err))
}

export default map_content_get