import React, { useState, useEffect } from "react";
import axios from "axios";
import dashboardContentStyles from "../../css/dashboardContent.module.css";
import sectionstyles from "../../css/BranchSection19.module.css";
import { useNavigate } from "react-router-dom";
import School_name from "../school_name";
import Branches_Changer_School_Id from "../../API/branch-controller/Branches_Changer_School_Id";
import sectionDataGet from "../../API/grade-section-mapping-controller/section-data";
import gradeSectionGet from "../../API/grade-section-mapping-controller/grade_section_get";
import sectionListGet from "../../API/grade-section-mapping-controller/section_get";
import createGradeSectionMapping from "../../API/grade-section-mapping-controller/grade_section_post";
import updateGradeSectionMapping from "../../API/grade-section-mapping-controller/grade-section-update";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import CommonDeleteModal from "../CommonDeleteModal";
import ConfirmationAlert from "./CommonComponents/ConfirmationModal";
import Switch from "../../../components/common/Switch";
import { Box, Checkbox } from "@mui/material";
import UpdatedModal from "../UpdatedModal";
import Fade from "@mui/material/Fade";
import IconButton from "@mui/material/IconButton";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import sortIcon from "../../img/sort_1.png"
import commonsort from "../../js/CommonSort"

import action__Add from "../../../../src/img/AddDefault.svg";
import action__Edit from "../../../../src/img/Edit.svg";
import action__Delete from "../../../../src/img/Delete.svg";
import arrow__Up from "../../../../src/img/Arrowup.svg";
import arrow__Down from "../../../../src/img/Arrowdown.svg";
import dashHome from "../../../../src/img/dashHome.svg";
import ConfirmationModal from "./../ConfirmationModal";
import { Search } from "@mui/icons-material";
import MappingAlert from "../../../components/common/MappingAlert";
import { blue } from "@mui/material/colors";
import SchoolAdminBreadcrumb from "../../../../src/pages/js/SchoolAdminBreadcrumb";
import Studentdynamic from "../../css/student32.module.css";
import ConfigurationSuccessModal from "../../../components/common/ConfigurationSuccessModal";

const BranchSectionTesting = () => {
  const [showAlert, setShowAlert] = useState(false);
  const [haveSectionData, setHaveSectionData] = useState([]);
  const [gradeSection, setGradeSection] = useState([]);
  const [sections, setSections] = useState({});
  const [sectionList, setSectionList] = useState({});
  const [gradeSectionMapping, setGradeSectionMapping] = useState({});
  const [lastModifiedAt, setLastModifiedAt] = useState();
  const [createSection, setCreateSection] = useState(false);
  const [newSetion, setnewSection] = useState("");
  const [section, setSection] = useState("");
  const [updateSection, setUpdateSection] = useState("");
  const [updateId, setUpdateId] = useState("");
  const [selectedSectionData, setSelectedSectionData] = useState([
    false,
    false,
    true,
  ]);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [selectedItem, setSelectedItem] = useState({});
  let board_name = sessionStorage.getItem("boardName");
  const schoolid = sessionStorage.getItem("schoolid");
  const branchid = sessionStorage.getItem("branchid");
  const [branchIde, setBranchIde] = useState({
    branch: sessionStorage.getItem("branchid"),
  });
  const [searchValue, setSearchValue] = useState("");
  const planid = sessionStorage.getItem("planid");
  const [sectionType, setSectionType] = useState("");
  const [gradeExpanded, setGradeExpanded] = useState([]);
  const [expanded, setExpanded] = useState([]);
  const [gradeRow, setGradeRow] = useState([]);
  const [editId, setEditId] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [alertMessage, setAlertMessage] = useState("");
  const [deleteId, setDeleteId] = useState("");
  const [isAddSectionModal, setIsAddSectionModal] = useState(false);
  const [showSub, setShowSub] = useState([]);

  // let showSub;
  const navigate = useNavigate();


  //BranchController starts
  const [branchList, setBranchList] = useState([]);

  const [schoolDetails, setSchoolDetails] = useState({
    branch: sessionStorage.getItem("branchid"),
  });
  let token = sessionStorage.getItem("token");

  // console.log(token);
  const planId = sessionStorage.getItem("planid");

  const handlerSchoolDetailsInputs = (e) => {
    setSchoolDetails({
      ...schoolDetails,
      [e.target.name]: e.target.value,
    });
    var branchid = e.target.value;
    setBranchIde({
      ...branchIde,
      branch: branchid,
    });
  };

  const showAlertHandler = () => {
    setShowAlert(true);
  };

  const hideAlertHandler = () => {
    setShowAlert(false);
    setErrorMessage(false);
  };

  const hideDeleteModal = () => {
    setOpenDeleteModal(false);
    setSelectedItem("");
  };

  const handleSectionType = (e) => {
    if (e.target.value === "SAME_SECTION") {
      setSectionType("SAME_SECTION");
      setSelectedSectionData(
        selectedSectionData.map((selected, index) => {
          if (index === parseInt(e.target.id)) {
            return true;
          } else {
            return false;
          }
        })
      );
    } else if (e.target.value === "NO_SECTION") {
      setSectionType("NO_SECTION");
      var payload = {
        active: true,
        sectionData: "NO_SECTION",
        schoolId: schoolid,
        branchId: branchid,
        gradeSection: {
          gradeId: allGradeId,
        },
      };
      setSelectedSectionData(
        selectedSectionData.map((selected, index) => {
          if (index === parseInt(e.target.id)) {
            return true;
          } else {
            return false;
          }
        })
      );
      createGradeSectionMapping(payload, () => {
        setnewSection("");
        getSectionBranchPlan(setGradeSection);
        gradeSectionMappingGet(setGradeSectionMapping);
      });
    } else {
      setSelectedSectionData(
        selectedSectionData.map((selected, index) => {
          if (index === parseInt(e.target.id)) {
            return true;
          } else {
            return false;
          }
        })
      );
      setSectionType("DIFFERENT_SECTIONS");
    }
  };
  const [toggleDetails, setToggleDetails] = useState({});

  const [toggleMessage, settoggleMessage] = useState();
  const [toggleConfirmation, setToggleConfirmation] = useState(false);
  const [sortOrder, setSortOrder] = useState(true);

  const handleConfirmationClose = () => {
    setToggleConfirmation(false);
    setToggleDetails({});
  };
  const [filterValue, setFilterValue] = useState();

  const updateToggle = () => {
    axios
      .get(
        `${process.env.REACT_APP_SWAGGER_URL}v1/api/user/grade-section/toggle-active/${toggleDetails?.id}?active=${toggleDetails?.value}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          data: toggleDetails?.value,
        }
      )
      .then(function (response) {
        gradeSectionMappingGet();
        if (response?.data) {
          const find = gradeSectionMapping?.find(
            (item) => item.id === toggleDetails?.id
          );
          find.active = toggleDetails?.value;
          const clone = [...gradeSectionMapping];
          clone.forEach((data) => {
            data = find;
          });
          setFilterValue(clone);
          handleConfirmationClose();
        }
      })
      .catch((err) => console.log(err));
  };

  const switchHandler = async (e, id) => {
    setToggleDetails({});
    const value = e.target.checked;
    if (!value) {
      const response = await axios.get(
        `${process.env.REACT_APP_SWAGGER_URL}v1/api/user/grade-section/confirmation-api?id=${id}&operationType=TOGGLE`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );

      setToggleConfirmation(true);
      settoggleMessage(response?.data?.data?.message);
      setToggleDetails({ id, value });
    } else {
      axios
        .get(
          `${process.env.REACT_APP_SWAGGER_URL}v1/api/user/grade-section/toggle-active/${id}?active=${value}`,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
            data: value,
          }
        )
        .then(function (response) {
          gradeSectionMappingGet();
          if (response?.data) {
            const find = gradeSectionMapping?.find((item) => item.id === id);
            find.active = value;
            const clone = [...gradeSectionMapping];
            clone.forEach((data) => {
              data = find;
            });
            setFilterValue(clone);
            handleConfirmationClose();
          }
        })
        .catch((err) => console.log(err));
    }
  };

  const checkBothMapping = async (e, show, activeValue) => {
    const id = show.id;
  //console.log(show, "sada");
    const active = !activeValue;
    setDeleteId(show.id);
    axios
      .get(
        `${process.env.REACT_APP_SWAGGER_URL}v1/api/user/grade-section/confirmation-api?id=${id}&operationType=TOGGLE`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        if (res.data.data.mappingExists === false) {
          setOpenDeleteModal(true);
          setSelectedItem(show);
        } else {
          setShowAlert(true);
        }
      });

    // const gradeMap = await axios.get(`${process.env.REACT_APP_SWAGGER_URL}v1/api/user/grade-section/${elem.gradeId}/grade-mappings`, {
    //     headers: {
    //         "Content-Type": "application/json",
    //         Authorization: `Bearer ${token}`
    //     }

    // })
    // let gradeRes = await gradeMap.data;

    // const secMap = await axios.get(`${process.env.REACT_APP_SWAGGER_URL}v1/api/user/grade-section/${elem.sectionId}/section-mappings`, {
    //     headers: {
    //         "Content-Type": "application/json",
    //         Authorization: `Bearer ${token}`
    //     }
    // })

    // const sectionRes = await secMap.data;

    // function checkBoth() {
    //     if (gradeRes.data && sectionRes.data) {
    //         setShowAlert(true)
    //         setDeleteId(elem.id)
    //         setAlertMessage(
    //             `Do you want to delete this section?`
    //         )
    //         return true
    //     } else {
    //         return false
    //     }
    // }
    // return checkBoth();
  };

  const errorMessageFunc = () => {
    setShowAlert(true);
    setAlertMessage(`This section is already existed in this grade`);
    setErrorMessage(true);
  };

  const onCloseAddSectionModal = () => {
    setIsAddSectionModal(false)
  }

  const addMappingNew = (e, u, section, gradeId, subAdd) => {
    setSearchValue("");
    e.preventDefault();
    if (subAdd) {
      // console.log("subAdd")
      const array = [...gradeRow];
      const deleteIndex = gradeRow.findIndex((x) => x.id === u.id);
      if (deleteIndex !== -1) {
        array.splice(deleteIndex, 1);
        setGradeRow(array);
      }
    }
    if (sectionType === "SAME_SECTION") {
      var payload = {
        active: true,
        sectionData: "SAME_SECTION",
        schoolId: schoolid,
        branchId: branchid,
        gradeSection: {
          gradeId: allGradeId,
          sectionId: [section[0].id],
        },
      };
    } else if (sectionType === "NO_SECTION") {
      sessionStorage.setItem("gradeid", gradeId ? gradeId : u.id);
      var payload = {
        active: true,
        sectionData: "NO_SECTION",
        schoolId: schoolid,
        branchId: branchid,
        gradeSection: {
          gradeId: [gradeId ? gradeId : u.id],
        },
      };
    } else {
      sessionStorage.setItem("gradeid", gradeId ? gradeId : u.id);
      var payload = {
        active: true,
        branchId: branchid,
        diffGradeSection: [
          {
            gradeId: gradeId ? gradeId : u.id,
            sectionId: [section[0].id],
          },
        ],
        schoolId: schoolid,
        sectionData: "DIFFERENT_SECTIONS",
      };
    }
    createGradeSectionMapping(
      payload,
      () => {
        setnewSection("");
        getSectionBranchPlan(setGradeSection);
        gradeSectionMappingGet(setGradeSectionMapping);
        setIsAddSectionModal(true)
      },
      () => {
        errorMessageFunc();
      }
    );
  };
  const updateSectionData = async (e, data, section) => {
    setSection([data]);
  //console.log("updateSectionData called", e, "-----", data, "=======", section,);
  //console.log(updateSection)
    // PUT v1/api/master/sections/{id}
    try {
      const id = data.sectionId;
      const res = await axios.put(
        `${process.env.REACT_APP_SWAGGER_URL}v1/api/master/sections/${id}`,
        {
          "section": updateSection,
          "discription": updateSection, // Assuming "discription" is the correct field name
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
    //console.log(res);

      setUpdatedName(updateSection)
      setSuccesUpdatesModal(true)
      getSectionBranchPlan(setGradeSection);
      gradeSectionMappingGet(setGradeSectionMapping);
    } catch (err) {
      // console.error(err);
      errorMessageFunc();
    }
    setEditId("");
  };
  const updateMapping = (e, data, section) => {
  //console.log("updateMapping", sectionType)
    setSearchValue("");
    if (sectionType === "SAME_SECTION") {
      sessionStorage.setItem("gradeid", data.gradeId);
      var payload = {
        id: data.id,
        active: data.active,
        sectionData: "SAME_SECTION",
        schoolId: schoolid,
        branchId: branchid,
        gradeId: data.gradeId,
        sectionId: section[0].id,
      };
    } else if (sectionType === "NO_SECTION") {
      sessionStorage.setItem("gradeid", data.gradeId);
      var payload = {
        id: data.id,
        active: data.active,
        sectionData: "NO_SECTION",
        schoolId: schoolid,
        branchId: branchid,
        gradeId: data.gradeId,
      };
    } else {
      sessionStorage.setItem("gradeid", data.gradeId);
      var payload = {
        id: data.id,
        active: data.active,
        sectionData: "DIFFERENT_SECTIONS",
        schoolId: schoolid,
        branchId: branchid,
        gradeId: data.gradeId,
        sectionId: section[0].id,
      };
    }
  //console.log(newSetion, "updatenaame", payload)
    updateGradeSectionMapping(
      payload,
      () => {
        setUpdatedName(updateSection)

        setSuccesUpdatesModal(true)
        // setnewSection("");
        getSectionBranchPlan(setGradeSection);
        gradeSectionMappingGet(setGradeSectionMapping);

      },
      () => {
        errorMessageFunc();
      }
    );

    setEditId("");
  };
  // const paths = ["master", "teacher", "student", "user", "content"];
  // const apiCalls = paths.map(path => updateAsperServices(oldId, newId, path));
  // try {
  //   const results = Promise.all(apiCalls);
  // //console.log(results);
  // } catch (error) {
  //   console.error(error.message);
  // }
  // const updateAsperServices = async (oldid, newid, service) => {
  //   // update-sectionId / old - id / new- id
  // //console.log(oldid, newid, service)
  //   try {
  //     const res = await axios.put(
  //       `${process.env.REACT_APP_SWAGGER_URL}v1/api/${service}/update-sectionId/${oldid}/${newid}`,
  //       {
  //         "section": section,
  //         "discription": section, // Assuming "discription" is the correct field name
  //       },
  //       {
  //         headers: {
  //           "Content-Type": "application/json",
  //           Authorization: `Bearer ${token}`,
  //         },
  //       }
  //     );
  //     return res.data;
  //   } catch (error) {
  //     throw new Error(`Error updating: ${error.message}`);
  //   }
  // }

  //   branch section grade
  const getSectionBranchPlan = async () => {
    let data = await axios.get(
      `${process.env.REACT_APP_SWAGGER_URL
      }v1/api/master/grades/for-grade-section-mapping?planId=${sessionStorage.getItem(
        "planid"
      )}&boardId=${sessionStorage.getItem("boardid")}`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        },
      }
    );
    if (data.data.data) {
    //console.log(data.data.data)
      setGradeSection(data?.data?.data);
    }
  };

  const addSection = async (cb) => {
  //console.log("addSection")
    var data = {
      section: newSetion,
      discription: newSetion,
    };

    return await axios
      .post(
        `${process.env.REACT_APP_SWAGGER_URL}v1/api/master/sections`,
        data,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${sessionStorage.getItem("token")}`,
          },
        }
      )
      .then((response) => {
        cb({ id: response.data.data.id });
        setSection(response.data.data.id);
        setCreateSection(false);
      })
      .catch((error) => {
      //console.log(error);
      });
  };

  const handleTempSection = async (e, u, data, gradeId, showSub) => {
    setSection([data]);
    await addMappingNew(e, u, [data], gradeId, showSub);
    setSearchValue("");
    branchSectionListGet(setSections);
  };

  const handleTempSectionUpdate = async (e, u, data, gradeId, showSub) => {
  //console.log("handleTempSectionUpdate")
    setSection([data]);
    await updateMapping(e, u, [data], gradeId, showSub);
    setSearchValue("");
    branchSectionListGet(setSections);
  };

  const gradeSectionMappingGet = async () => {
    return await axios
      .get(
        `${process.env.REACT_APP_SWAGGER_URL}v1/api/user/grade-section/all?schoolId=${schoolid}&branchId=${branchIde.branch}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((response) => {
        if (response) {
        //console.log(response.data.data)

          setGradeSectionMapping(response.data.data);
        }
      })
      .catch((err) => console.log(err));
  };

  const branchSectionListGet = async () => {
    return await axios
      .get(
        `${process.env.REACT_APP_SWAGGER_URL}v1/api/user/grade-section/sections?search=${searchValue}&branchId=${branchIde.branch}&schoolId=${schoolid}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((response) => {
        if (response) {
          setSections(response.data.data);
        }
      })
      .catch((err) => console.log(err));
  };

  const getLastModifiedAt = async () => {
    return await axios
      .get(
        `${process.env.REACT_APP_SWAGGER_URL}v1/api/user/grade-section/last-modified-at`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((response) => {
        if (response) {
          setLastModifiedAt(response.data.data);
        }
      })
      .catch((err) => console.log(err));
  };

  const handleEditSection = (show) => {
  //console.log(show, "data");
    if (gradeRow.length > 0) {
      const array = [...gradeRow];
      const deleteIndex = gradeRow.findIndex((x) => x.id);
      if (deleteIndex !== -1) {
        array.splice(deleteIndex, 1);
        setGradeRow(array);
      }
    }
    setEditId(show.id);
    setUpdateId(show.id);
    setUpdateSection(show.section);
  };

  const handleDeleteSection = () => {
    axios
      .delete(
        `${process.env.REACT_APP_SWAGGER_URL}v1/api/user/grade-section/${deleteId}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        gradeSectionMappingGet();
        hideDeleteModal();
      });
  };

  useEffect(() => {
    // console.log(branchIde);
    var branchid = schoolDetails.branch;
    sessionStorage.setItem("branchid", branchid);
    Branches_Changer_School_Id(setBranchList);
    getSectionBranchPlan(setGradeSection);
    gradeSectionMappingGet(branchIde);
    // branchSectionListGet();
    getLastModifiedAt();
    return () => { };
  }, [schoolDetails]);

  //BranchController ends
  useEffect(() => {
    sectionDataGet(setHaveSectionData);
    sectionListGet(setSectionList);
    getSectionBranchPlan(setGradeSection);
    gradeSectionMappingGet();
    branchSectionListGet();
    getLastModifiedAt();
  }, []);

  useEffect(() => {
    getSectionBranchPlan(setGradeSection);
  }, [planId]);

  useEffect(() => {
    branchSectionListGet();
  }, [searchValue, gradeSectionMapping]);

  useEffect(() => {
    if (gradeSectionMapping.length > 0) {
      const found = gradeSectionMapping.some((sections) => {
        return (
          sections.sectionData === "DIFFERENT_SECTIONS" ||
          sections.sectionData === "SAME_SECTION"
        );
      });
      if (found) {
        if (sectionType === "SAME_SECTION") {
          setSelectedSectionData([false, true, false]);
          setSectionType("SAME_SECTION");
        } else {
          setSelectedSectionData([false, false, true]);
          setSectionType("DIFFERENT_SECTIONS");
        }
      } else {
        setSelectedSectionData([true, false, false]);
        setSectionType("NO_SECTION");
      }
    }
  }, [gradeSectionMapping]);

  useEffect(() => {
    if (gradeSectionMapping.length > 0) {
      const found = gradeSectionMapping.every((sections) => {
        return sections.sectionData === "SAME_SECTION";
      });
      // console.log("for check", found);
    }
  }, []);

  const allGradeId = gradeSection?.map((elem) => elem?.id);

  useEffect(() => {
    setGradeExpanded([...Array(gradeSection.length)].map((val) => false));
    setExpanded([...Array(gradeSection.length)].map((val) => false));
  }, [gradeSection]);

  const handleClick = (diff, index) => {
    if (diff === "addRow") {
      setGradeExpanded(
        gradeExpanded.map((boolean_value, i) => {
          if (index === i) {
            // once we retrieve the collapse index, we negate it
            return !boolean_value;
          } else {
            // all other collapse will be closed
            return false;
          }
        })
      );
    } else {
      setExpanded(
        expanded.map((boolean_value, i) => {
          if (index === i) {
            // once we retrieve the collapse index, we negate it
            return !boolean_value;
          } else {
            // all other collapse will be closed
            return false;
          }
        })
      );
    }
  };


  useEffect(() => {
    if (gradeSectionMapping.length > 0) {
      // result = gradeSection.map(res => ({
      //     match: gradeSectionMapping.some(res2 => res.id === res2.gradeId && res2.section !== ''),
      // }));
      var vshowSub = gradeSection.map((obj) => ({
        gradeName: obj.grade,
        mappedGrade: gradeSectionMapping.filter((res) => obj.grade === res.grade),
      }));
      setShowSub(vshowSub)
    }
  }, [gradeSectionMapping])

  const addGradeRow = (gradeData, index) => {
    setEditId("");
    const found = gradeRow.find((row) => {
      return row.id === gradeData.id;
    });
    if (!found) {
      setGradeRow([gradeData]);
    } else {
      return false;
    }

    setGradeExpanded(
      gradeExpanded.map((boolean_value, i) => {
        if (index === i) {
          // once we retrieve the collapse index, we negate it
          return !boolean_value;
        } else {
          // all other collapse will be closed
          return false;
        }
      })
    );
  };
  const commonsortInner = async (array, field, order) => {
  //console.log(array, field, order, "common sorting file");
    order = order === true ? "desc" : "asc";

    if (array?.length > 0) {
      var nullsFirst = false;
      if (order === "asc") {
        nullsFirst = true;
      }

      return array.sort((a, b) => {
        if (a[field] === null && b[field] === null) return 0;
        if (a[field] === null) return nullsFirst ? -1 : 1;
        if (b[field] === null) return nullsFirst ? 1 : -1;

        const getGradeNumber = (grade) => {
          const match = grade.match(/\d+/);
          return match ? parseInt(match[0], 10) : -1; // Assign a lower value to grades without numbers like "Nursery"
        };

        if (field === "grade") {
          const gradeA = getGradeNumber(a[field]);
          const gradeB = getGradeNumber(b[field]);

          if (gradeA === -1 && gradeB === -1) { // Both are non-numeric grades
            return order === "asc" ? a[field].localeCompare(b[field]) : b[field].localeCompare(a[field]);
          } else if (gradeA === -1) { // Only a is non-numeric grade
            return order === "asc" ? -1 : 1;
          } else if (gradeB === -1) { // Only b is non-numeric grade
            return order === "asc" ? 1 : -1;
          } else { // Both are numeric grades
            return order === "asc" ? gradeA - gradeB : gradeB - gradeA;
          }
        } else if (typeof a[field] === 'boolean') {
          if (order === 'asc') {
            return a[field] === b[field] ? 0 : a[field] ? 1 : -1;
          } else {
            return a[field] === b[field] ? 0 : a[field] ? -1 : 1;
          }
        } else if (typeof a[field] === 'string') {
          if (order === 'asc') {
            return a[field].localeCompare(b[field]);
          } else {
            return b[field].localeCompare(a[field]);
          }
        } else if (typeof a[field] === 'number' || a[field] instanceof Date) {
          if (order === 'asc') {
            return a[field] - b[field];
          } else {
            return b[field] - a[field];
          }
        }
      });
    } else {
      return [];
    }
  }
  const onSort = async (field) => {
    // console.log(field, gradeSectionMapping, showSub, gradeSection, "800000000000000000");
    gradeSection.forEach(item => {
      item.gradeSection = item.grade.replace(' ', '');
    });
  //console.log("gradeSection", gradeSection)
    var sorted = await commonsortInner(gradeSection || [], "gradeSection", !sortOrder);
  //console.log("sorted", sorted)
    var vshowSub = sorted.map((obj) => ({
      gradeName: obj.grade,
      mappedGrade: gradeSectionMapping.filter((res) => obj.grade === res.grade),
    }));
  //console.log("vshowSub", vshowSub)
    setShowSub(vshowSub)
    setSortOrder(!sortOrder)
  }
  const [updatedName, setUpdatedName] = useState("");
  const [succesUpdatesModal, setSuccesUpdatesModal] = useState(false);
  const closeModal = () => {
    setSuccesUpdatesModal(false);
  };

  return (
    <>
      <SchoolAdminBreadcrumb
        dashHome={dashHome}
        sectionName={'Section Details'}
        date={lastModifiedAt}
      />
      <article className={sectionstyles.article_add_school_plan}>
        <div style={{ marginTop: "10px" }} className={sectionstyles.Grid_school_name_branch}>
          <div className={sectionstyles.school_name}>
            <School_name />
          </div>
          <select
            className={sectionstyles.branchselect_Dropdown}
            name="branch"
            id="branch"
            value={schoolDetails.branch}
            onChange={handlerSchoolDetailsInputs}
          >
            {branchList.length > 0 ? (
              branchList.map((branchList) => {
                return (
                  <option value={branchList.branchId}>
                    {branchList.branch}
                  </option>
                );
              })
            ) : (
              <option className={sectionstyles.option} value=""></option>
            )}
          </select>
        </div>
        <div className={sectionstyles.grid_container_header_plan}>
          <div
            className={sectionstyles.item_plan1 + ' ' + 'az_cursor_pointer'}
            onClick={(e) => {
              e.preventDefault();
              navigate("/dashboard/branchplan");
            }}
          >
            Plan
          </div>
          <div
            className={sectionstyles.item_plan2 + ' ' + 'az_cursor_pointer'}
            onClick={(e) => {
              e.preventDefault();
              navigate("/dashboard/branchsection");
            }}
          >
            Sections
          </div>
          <div
            className={sectionstyles.item_plan3 + ' ' + 'az_cursor_pointer'}
            onClick={(e) => {
              e.preventDefault();
              navigate("/dashboard/teacherId", {
                state: "edit",
              });
            }}
          >
            Academic Staff
          </div>
          <div
            className={sectionstyles.item_plan4 + ' ' + 'az_cursor_pointer'}
            onClick={(e) => {
              e.preventDefault();
              navigate("/dashboard/student");
            }}
          >
            Students
          </div>
          <div
            className={sectionstyles.item_plan5 + ' ' + 'az_cursor_pointer'}
            onClick={(e) => {
              e.preventDefault();
              navigate("/dashboard/csp");
            }}
          >
            Change Student Profile
          </div>
          <div
            className={sectionstyles.item_plan6 + ' ' + 'az_cursor_pointer'}
            onClick={(e) => {
              e.preventDefault();
              navigate("/dashboard/token");
            }}
          >
            Tokens
          </div>
          <div
            className={sectionstyles.item_plan7 + ' ' + 'az_cursor_pointer'}
            onClick={(e) => {
              e.preventDefault();
              navigate("/dashboard/logo");
            }}
          >
            Branch Logo
          </div>
        </div>

        <div className={sectionstyles.boards_container}>
          <div className={sectionstyles.grid_item}>
            <label id="boards">Board</label>
            <label className={sectionstyles.board}>{board_name}</label>
          </div>

          {haveSectionData && haveSectionData?.length > 0
            ? haveSectionData.map((sectionData, index) => {
              return (
                <div
                  className={sectionstyles.radio}
                  key={sectionData.sectionData}
                >
                  <input
                    className="az_input_radio on cursorPointer"
                    id={index}
                    type="radio"
                    value={sectionData.sectionData}
                    onChange={(e) => {
                      handleSectionType(e);
                    }}
                    checked={selectedSectionData[index]}
                    disabled={
                      gradeSectionMapping.length > 0 &&
                        sectionData.sectionData === "NO_SECTION" &&
                        !selectedSectionData[index]
                        ? true
                        : false
                    }
                  />
                  <label
                    htmlFor="test2"
                    style={{
                      opacity:
                        gradeSectionMapping.length > 0 &&
                        sectionData.sectionData === "NO_SECTION" &&
                        !selectedSectionData[index] &&
                        "0.5",
                    }}
                  >
                    {sectionData.sectionName}
                  </label>
                </div>
              );
            })
            : ""}
        </div>
        <div className={sectionstyles.section_list_table}>
          <Paper sx={{ width: "100%", overflow: "hidden" }} >
            <TableContainer
              style={{ marginBottom: "90px" }}
              sx={{ maxHeight: 640, overflow: "auto" }}
            >
              <Table
                size="medium"
                sx={{ minWidth: 650 }}
                stickyHeader={true}
                aria-label="customized table"
              >
                <TableHead>
                  <TableRow>
                    <TableCell className={dashboardContentStyles.table_head}>

                      {/* <Checkbox
                        sx={{
                          color: `#111fee !important`,
                          "&.Mui-checked": {
                            color: blue[900],
                          },
                        }}
                      /> */}
                    </TableCell>

                    <TableCell className={dashboardContentStyles.table_head}>
                      Grades
                      <img
                        style={{
                          verticalAlign: "middle",
                          marginLeft: "5px",
                          marginBottom: "1px",
                        }}
                        className="cursor-pointer"
                        width="12"
                        height="15"
                        src={sortIcon}
                        onClick={() => {
                          onSort("grade")
                        }}
                      />
                    </TableCell>

                    <TableCell
                      className={dashboardContentStyles.table_head}
                      align="center"
                    >
                      Section Name
                      {/* <img
                        style={{
                          verticalAlign: "middle",
                          marginLeft: "5px",
                          marginBottom: "1px",
                        }}
                        className="cursor-pointer"
                        width="12"
                        height="15"
                        src={sortIcon}
                        onClick={() => {
                          onSort("name")
                        }}
                      /> */}
                    </TableCell>
                    <TableCell style={{ zIndex: "0" }}
                      className={dashboardContentStyles.table_head}
                      align="center"
                    >
                      Active
                    </TableCell>
                    <TableCell style={{ zIndex: "0" }}
                      className={dashboardContentStyles.table_head}
                      align="center"
                    >
                      Action
                    </TableCell>
                    <TableCell
                      className={dashboardContentStyles.table_head}
                    ></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>

                  {gradeSectionMapping.length > 0
                    ? // if gradeSectionMapping array's length greater than zero
                    showSub.map((res, ind) => {
                      return res.mappedGrade.length > 0
                        ? // if mappedGrade array's length is more than zero
                        res.mappedGrade.map((show, index) => {
                          return show.section !== "" ? (
                            // when section value is exist in gradeSectionMapping
                            <>
                              {index === 0 ? (
                                show.id === editId ? (
                                  <TableRow
                                    style={{
                                      borderBottom: "unset",
                                    }}
                                    className={
                                      sectionstyles.table_row_section
                                    }
                                    id={show.id}
                                  >
                                    <TableCell
                                      scope="row"
                                      className={
                                        sectionstyles.cbse_table_head3
                                      }
                                      color="#000000b8"
                                    >
                                      {show.grade}
                                    </TableCell>
                                    <TableCell
                                      scope="row"
                                      className={
                                        sectionstyles.cbse_table_head2
                                      }
                                      align="center"
                                      style={{
                                        color: "#354052",
                                        textAlign: "center",
                                      }}
                                    >
                                      <>
                                        <input
                                          type="text"
                                          placeholder="Ex: A,B"
                                          onChange={(event) => {
                                            setUpdateSection(
                                              event.target.value
                                            );
                                            setUpdateId("");
                                            setSearchValue(
                                              event.target.value
                                            );
                                            // branchSectionListGet();
                                            if (event.target.value !== "") {
                                              // console.log(event.target.value);
                                              const savingSection =
                                                sectionList.filter((e) => {
                                                  const capsLetter =
                                                    event.target.value.toUpperCase();
                                                  return (
                                                    e.section === capsLetter
                                                  );
                                                });
                                              if (
                                                savingSection.length > 0
                                              ) {
                                                setCreateSection(false);
                                                setSection(savingSection);
                                              } else {
                                                setnewSection(
                                                  event.target.value.toUpperCase()
                                                );
                                                setCreateSection(true);
                                              }
                                            }
                                          }}
                                          list="sectionDatas"
                                          value={updateSection}
                                        />
                                        {sections.length > 0 && (
                                          <datalist id="sectionDatas">
                                            {sections.map((section, i) => {
                                              return (
                                                <option
                                                  key={section.id}
                                                  value={section.section}
                                                />
                                              );
                                            })}
                                          </datalist>
                                        )}
                                      </>
                                    </TableCell>
                                    <TableCell
                                      scope="row"
                                      className={
                                        sectionstyles.cbse_table_head2
                                      }
                                      align="center"
                                    >
                                      <Switch
                                        checked={show.active}
                                        onChange={(e) =>
                                          switchHandler(e, show.id)
                                        }
                                        disabled
                                        style={{ color: "red !important" }}
                                      />
                                    </TableCell>

                                    <TableCell
                                      scope="row"
                                      className={
                                        sectionstyles.cbse_table_head3
                                      }
                                      align="center"
                                    >
                                      <button
                                        className={sectionstyles.button}
                                        onClick={async (e) => {
                                          if (createSection) {
                                            await addSection((data) =>
                                              handleTempSectionUpdate(
                                                e,
                                                show,
                                                data
                                              )
                                            );
                                          } else {
                                            await updateMapping(
                                              e,
                                              show,
                                              updateId ? updateId : section
                                            );
                                          }
                                          //console.log(updateId, section)
                                          // await updateSectionData(e, show, updateId ? updateId : section)
                                        }}
                                        id="section_table_btn"
                                        disabled={
                                          sectionType === "NO_SECTION"
                                        }
                                      >
                                        Update
                                      </button>
                                    </TableCell>
                                    <TableCell scope="row">
                                      {gradeRow.length > 0
                                        ? gradeRow.map(
                                          (grade) =>
                                            grade.id === show.id && (
                                              <IconButton
                                                aria-label="expand row"
                                                size="small"
                                                onClick={() =>
                                                  handleClick(
                                                    "addRow",
                                                    ind
                                                  )
                                                }
                                                id={section.id}
                                              >
                                                {gradeExpanded[ind] ? (
                                                  <img
                                                    src={arrow__Up}
                                                    alt="Up-Arrow"
                                                  />
                                                ) : (
                                                  <img
                                                    src={arrow__Down}
                                                    alt="Down-Arrow"
                                                  />
                                                )}
                                              </IconButton>
                                            )
                                        )
                                        : res.mappedGrade.length > 1 &&
                                        res.mappedGrade.map(
                                          (yes, indx) => {
                                            return (
                                              show.grade === yes.grade &&
                                              indx === 0 && (
                                                <IconButton
                                                  aria-label="expand row"
                                                  size="small"
                                                  onClick={() =>
                                                    handleClick(
                                                      "subSec",
                                                      ind
                                                    )
                                                  }
                                                  id={section.id}
                                                >
                                                  {expanded[ind] ? (
                                                    <img
                                                      src={arrow__Up}
                                                      alt="Up-Arrow"
                                                    />
                                                  ) : (
                                                    <img
                                                      src={arrow__Down}
                                                      alt="Down-Arrow"
                                                    />
                                                  )}
                                                </IconButton>
                                              )
                                            );
                                          }
                                        )}
                                    </TableCell>
                                  </TableRow>
                                ) : (
                                  <TableRow
                                    key={show.gradeId}
                                    style={{
                                      borderBottom: "unset",
                                    }}
                                    className={
                                      sectionstyles.table_row_section
                                    }
                                  >
                                    <TableCell>
                                      {/* <Checkbox
                                        sx={{
                                          color: `#111fee !important`,
                                          "&.Mui-checked": {
                                            color: blue[900],
                                          },
                                        }}
                                      /> */}
                                    </TableCell>
                                    <TableCell
                                      scope="row"
                                      className={
                                        sectionstyles.cbse_table_head3
                                      }
                                      id={show.id}
                                      style={{ color: "#354052" }}
                                    >
                                      {show.grade}
                                    </TableCell>
                                    <TableCell
                                      scope="row"
                                      className={
                                        sectionstyles.cbse_table_head2
                                      }
                                      align="center"
                                      style={{ color: "#354052" }}
                                    >
                                      {show.section}
                                    </TableCell>
                                    <TableCell
                                      className={
                                        sectionstyles.cbse_table_head2
                                      }
                                      align="center"
                                    >
                                      <Switch
                                        checked={show.active}
                                        onChange={(e) =>
                                          switchHandler(e, show.id)
                                        }
                                        style={{ color: "red !important" }}
                                      />
                                    </TableCell>
                                    <TableCell
                                      scope="row"
                                      align="center"
                                      className={
                                        sectionstyles.cbse_table_head3
                                      }
                                    >
                                      <Box
                                        className={sectionstyles.wrapper}
                                      >
                                        <div
                                          className={sectionstyles.icon}
                                          onClick={() =>
                                            addGradeRow(show, ind)
                                          }
                                          style={{ zIndex: "0" }}
                                        >
                                          <div
                                            className={
                                              sectionstyles.tooltip
                                            }
                                          >
                                            Addsection
                                          </div>
                                          <img
                                            className={
                                              dashboardContentStyles.aRoleActionBtn
                                            }
                                            src={action__Add}
                                            alt="Add-Btn"
                                          />
                                        </div>
                                        <div
                                          className={sectionstyles.icon}
                                          onClick={() =>
                                            handleEditSection(show)
                                          }
                                          style={{ zIndex: "0" }}
                                        >
                                          <div
                                            className={
                                              sectionstyles.tooltip
                                            }
                                          >
                                            Edit
                                          </div>
                                          <img
                                            className={
                                              dashboardContentStyles.aRoleActionBtn
                                            }
                                            src={action__Edit}
                                            alt="Edit-Btn"
                                          />
                                        </div>
                                        {/* <div
                                              onClick={(e) => {
                                                checkBothMapping(e, show);
                                              }}
                                              className={sectionstyles.icon}
                                              style={{ zIndex: "0" }}
                                            >
                                              <div
                                                className={
                                                  sectionstyles.tooltip
                                                }
                                              >
                                                Remove
                                              </div>
                                              <img
                                                className={
                                                  dashboardContentStyles.aRoleActionBtn
                                                }
                                                src={action__Delete}
                                                alt="Dlt-Btn"
                                              />
                                            </div> */}
                                      </Box>
                                    </TableCell>
                                    <TableCell
                                      scope="row"
                                      align="center"
                                      className={
                                        sectionstyles.cbse_table_head2
                                      }
                                    >
                                      {gradeRow.length > 0
                                        ? gradeRow.map(
                                          (grade) =>
                                            grade.id === show.id && (
                                              <IconButton
                                                aria-label="expand row"
                                                size="small"
                                                onClick={() =>
                                                  handleClick(
                                                    "addRow",
                                                    ind
                                                  )
                                                }
                                                id={section.id}
                                              >
                                                {gradeExpanded[ind] ? (
                                                  <img
                                                    src={arrow__Up}
                                                    alt="Up-Arrow"
                                                  />
                                                ) : (
                                                  <img
                                                    src={arrow__Down}
                                                    alt="Down-Arrow"
                                                  />
                                                )}
                                              </IconButton>
                                            )
                                        )
                                        : res.mappedGrade.length > 1 &&
                                        res.mappedGrade.map(
                                          (yes, indx) => {
                                            return (
                                              show.grade === yes.grade &&
                                              indx === 0 && (
                                                <IconButton
                                                  aria-label="expand row"
                                                  size="small"
                                                  onClick={() =>
                                                    handleClick(
                                                      "subSec",
                                                      ind
                                                    )
                                                  }
                                                  id={section.id}
                                                >
                                                  {expanded[ind] ? (
                                                    <img
                                                      src={arrow__Up}
                                                      alt="Up-Arrow"
                                                    />
                                                  ) : (
                                                    <img
                                                      src={arrow__Down}
                                                      alt="Down-Arrow"
                                                    />
                                                  )}
                                                </IconButton>
                                              )
                                            );
                                          }
                                        )}
                                    </TableCell>
                                  </TableRow>
                                )
                              ) : index > 0 && show.id === editId ? (
                                <TableRow
                                  style={{
                                    borderBottom: "unset",
                                  }}
                                  className={
                                    sectionstyles.table_row_section
                                  }
                                  id={show.id}
                                >
                                  <TableCell>
                                    {/* <Checkbox
                                      sx={{
                                        color: `#111fee !important`,
                                        "&.Mui-checked": {
                                          color: blue[900],
                                        },
                                      }}
                                    /> */}
                                  </TableCell>
                                  <TableCell
                                    scope="row"
                                    className={
                                      sectionstyles.cbse_table_head3
                                    }
                                    style={{ color: "#354052" }}
                                  >
                                    {show.grade}
                                  </TableCell>
                                  <TableCell
                                    scope="row"
                                    className={
                                      sectionstyles.cbse_table_head2
                                    }

                                    align="center"
                                    style={{
                                      color: "#354052",
                                      textAlign: "center",
                                    }}
                                  >
                                    {/* {sections && sections.length > 0 && ( */}
                                    <>
                                      <input
                                        type="text"
                                        name="inputBox"
                                        placeholder="Ex: A,B"
                                        onChange={(event) => {
                                          setUpdateSection(
                                            event.target.value
                                          );
                                          setUpdateId("");
                                          setSearchValue(
                                            event.target.value
                                          );
                                          if (event.target.value !== "") {
                                            const savingSection =
                                              sectionList.filter((e) => {
                                                const capsLetter =
                                                  event.target.value.toUpperCase();
                                                return (
                                                  e.section === capsLetter
                                                );
                                              });
                                            if (
                                              savingSection.length > 0
                                            ) {
                                              setCreateSection(false);
                                              setSection(savingSection);
                                            } else {
                                              setnewSection(
                                                event.target.value.toUpperCase()
                                              );
                                              setCreateSection(true);
                                            }
                                          }
                                        }}
                                        list="sectionDatas"
                                        value={updateSection}
                                      />
                                      {sections?.length > 0 && (
                                        <datalist id="sectionDatas">
                                          {sections.map((section, i) => {
                                            return (
                                              <option
                                                key={section.id}
                                                value={section.section}
                                              />
                                            );
                                          })}
                                        </datalist>
                                      )}
                                    </>
                                    {/* )} */}
                                  </TableCell>
                                  <TableCell
                                    scope="row"
                                    className={
                                      sectionstyles.cbse_table_head2
                                    }
                                    align="center"
                                  >
                                    <Switch
                                      checked={show.active}
                                      onChange={(e) =>
                                        switchHandler(e, show.id)
                                      }
                                      disabled
                                    />
                                  </TableCell>

                                  <TableCell
                                    scope="row"
                                    className={
                                      sectionstyles.cbse_table_head3
                                    }
                                    align="center"
                                  >
                                    <button
                                      className={sectionstyles.button}
                                      onClick={async (e) => {
                                        if (createSection) {
                                          await addSection((data) =>
                                            handleTempSectionUpdate(
                                              e,
                                              show,
                                              data
                                            )
                                          );
                                        } else {
                                          await updateMapping(
                                            e,
                                            show,
                                            updateId ? updateId : section
                                          );
                                        }
                                      }}
                                      id="section_table_btn"
                                      disabled={
                                        sectionType === "NO_SECTION"
                                      }
                                    >
                                      Update
                                    </button>
                                  </TableCell>
                                  <TableCell scope="row"></TableCell>
                                </TableRow>
                              ) : (
                                <Fade
                                  in={expanded[ind]}
                                  unmountOnExit={true}
                                >
                                  <TableRow
                                    className={
                                      sectionstyles.table_row_section
                                    }
                                    style={
                                      expanded[ind]
                                        ? { display: "table-row" }
                                        : { display: "none" }
                                    }
                                  >
                                    <TableCell>
                                      <Checkbox
                                        sx={{
                                          color: `#111fee !important`,
                                          "&.Mui-checked": {
                                            color: blue[900],
                                          },
                                        }}
                                      />
                                    </TableCell>
                                    <TableCell
                                      scope="row"
                                      className={
                                        sectionstyles.cbse_table_head3
                                      }
                                      style={{ color: "#354052" }}
                                    >
                                      {show.grade}
                                    </TableCell>
                                    <TableCell
                                      className={
                                        sectionstyles.cbse_table_head2
                                      }
                                      style={{
                                        padding: "0px",
                                        color: "#354052",
                                      }}
                                      align="center"
                                    >
                                      {show.section}
                                    </TableCell>
                                    <TableCell
                                      className={
                                        sectionstyles.cbse_table_head2
                                      }
                                      align="center"
                                    >
                                      <Switch
                                        checked={show.active}
                                        onChange={(e) =>
                                          switchHandler(e, show.id)
                                        }
                                      />
                                    </TableCell>
                                    <TableCell
                                      scope="row"
                                      className={
                                        sectionstyles.cbse_table_head3
                                      }
                                      align="center"
                                    >
                                      <div
                                        className={sectionstyles.wrapper}
                                      >
                                        <div
                                          style={{
                                            display: "inline-block",
                                            zIndex: "0",
                                            width: "40px",
                                          }}
                                        >
                                          <div></div>
                                          <span>
                                            <i></i>
                                          </span>
                                        </div>
                                        <div
                                          className={sectionstyles.icon}
                                          onClick={() =>
                                            handleEditSection(show)
                                          }
                                          style={{ zIndex: "0" }}
                                        >
                                          <div
                                            className={
                                              sectionstyles.tooltip
                                            }
                                          >
                                            Edit
                                          </div>
                                          <img
                                            className={
                                              dashboardContentStyles.aRoleActionBtn
                                            }
                                            src={action__Edit}
                                            alt="Edit-Btn"
                                          />
                                        </div>
                                        {/* <div
                                              onClick={(e) => {
                                                checkBothMapping(
                                                  e,
                                                  show,
                                                  show.active
                                                );
                                              }}
                                              className={sectionstyles.icon}
                                              style={{ zIndex: "0" }}
                                            >
                                              <div
                                                className={
                                                  sectionstyles.tooltip
                                                }
                                              >
                                                Remove
                                              </div>
                                              <img
                                                className={
                                                  dashboardContentStyles.aRoleActionBtn
                                                }
                                                src={action__Delete}
                                                alt="Dlt-Btn"
                                              />
                                            </div> */}
                                      </div>
                                    </TableCell>
                                    <TableCell
                                      scope="row"
                                      className={
                                        sectionstyles.cbse_table_head2
                                      }
                                    ></TableCell>
                                  </TableRow>
                                </Fade>
                              )}

                              {/* after clicking add icon to add more section  */}
                              {gradeRow.length > 0 &&
                                gradeRow.map((grade, i) => {
                                  return (
                                    grade.id === show.id && (
                                      <Fade
                                        in={gradeExpanded[ind]}
                                        timeout="auto"
                                        unmountOnExit={true}
                                      >
                                        <TableRow
                                          className={
                                            sectionstyles.table_row_section
                                          }
                                          style={
                                            gradeExpanded[ind]
                                              ? { display: "table-row" }
                                              : { display: "none" }
                                          }
                                        >
                                          <TableCell
                                            scope="row"
                                            className={
                                              sectionstyles.cbse_table_head3
                                            }
                                            style={{ color: "#354052" }}
                                          >
                                            {show.grade}
                                          </TableCell>
                                          <TableCell
                                            scope="row"
                                            className={
                                              sectionstyles.cbse_table_head2
                                            }
                                            align="center"
                                            style={{ textAlign: "center" }}
                                          >
                                            <>
                                              <input
                                                type="text"
                                                placeholder="Ex: A,B"
                                                onChange={(event) => {
                                                  setSearchValue(
                                                    event.target.value
                                                  );
                                                  if (
                                                    event.target.value !==
                                                    ""
                                                  ) {
                                                    const savingSection =
                                                      sectionList.filter(
                                                        (e) => {
                                                          const capsLetter =
                                                            event.target.value.toUpperCase();
                                                          return (
                                                            e.section ===
                                                            capsLetter
                                                          );
                                                        }
                                                      );
                                                    if (
                                                      savingSection.length >
                                                      0
                                                    ) {
                                                      setCreateSection(
                                                        false
                                                      );
                                                      setSection(
                                                        savingSection
                                                      );
                                                    } else {
                                                      setnewSection(
                                                        event.target.value.toUpperCase()
                                                      );
                                                      setCreateSection(
                                                        true
                                                      );
                                                    }
                                                  }
                                                }}
                                                list="sectionDatas"
                                              />
                                              {sections.length > 0 && (
                                                <datalist id="sectionDatas">
                                                  {sections.map(
                                                    (section, i) => {
                                                      return (
                                                        <option
                                                          key={section.id}
                                                          value={
                                                            section.section
                                                          }
                                                        />
                                                      );
                                                    }
                                                  )}
                                                </datalist>
                                              )}
                                            </>
                                          </TableCell>
                                          <TableCell
                                            scope="row"
                                            className={
                                              sectionstyles.cbse_table_head2
                                            }
                                            align="center"
                                          >
                                            <Switch
                                              checked={show.active}
                                              onChange={(e) =>
                                                switchHandler(e, show.id)
                                              }
                                              disabled
                                            />
                                          </TableCell>

                                          <TableCell
                                            scope="row"
                                            className={
                                              sectionstyles.cbse_table_head3
                                            }
                                            align="center"
                                          >
                                            <button
                                              className={
                                                sectionstyles.button
                                              }
                                              onClick={async (e) => {
                                                const subAdd = true;
                                                if (createSection) {
                                                  await addSection((data) =>
                                                    handleTempSection(
                                                      e,
                                                      show,
                                                      data,
                                                      show.gradeId,
                                                      subAdd
                                                    )
                                                  );
                                                } else {
                                                  await addMappingNew(
                                                    e,
                                                    show,
                                                    section,
                                                    show.gradeId,
                                                    subAdd
                                                  );
                                                }
                                              }}
                                              id="section_table_btn"
                                              disabled={
                                                sectionType === "NO_SECTION"
                                              }
                                            >
                                              Add
                                            </button>
                                          </TableCell>
                                          <TableCell
                                            scope="row"
                                            className={
                                              sectionstyles.cbse_table_head2
                                            }
                                          ></TableCell>
                                        </TableRow>
                                      </Fade>
                                    )
                                  );
                                })}
                            </>
                          ) : (
                            // if section is empty on gradeSectionMapping
                            <>
                              <TableRow
                                style={{
                                  borderBottom: "unset",
                                }}
                                className={sectionstyles.table_row_section}
                                id={show.id}
                              >
                                <TableCell
                                  scope="row"
                                  className={sectionstyles.cbse_table_head3}
                                  style={{ color: "#354052" }}
                                >
                                  {show.grade}
                                </TableCell>
                                <TableCell
                                  scope="row"
                                  className={sectionstyles.cbse_table_head2}
                                  align="center"
                                  style={{ textAlign: "center" }}
                                >
                                  <>
                                    <input
                                      type="text"
                                      placeholder="Ex: A,B"
                                      onChange={(event) => {
                                        setSearchValue(event.target.value);
                                        if (event.target.value !== "") {
                                          const savingSection =
                                            sectionList.filter((e) => {
                                              const capsLetter =
                                                event.target.value.toUpperCase();
                                              return (
                                                e.section === capsLetter
                                              );
                                            });
                                          if (savingSection.length > 0) {
                                            setCreateSection(false);
                                            setSection(savingSection);
                                          } else {
                                            setnewSection(
                                              event.target.value.toUpperCase()
                                            );
                                            setCreateSection(true);
                                          }
                                        }
                                      }}
                                      list="sectionDatas"
                                      disabled={
                                        sectionType === "NO_SECTION"
                                      }
                                    />
                                    {sections.length > 0 && (
                                      <datalist id="sectionDatas">
                                        {sections.map((section, i) => {
                                          return (
                                            <option
                                              key={section.id}
                                              value={section.section}
                                            />
                                          );
                                        })}
                                      </datalist>
                                    )}
                                  </>
                                </TableCell>
                                <TableCell
                                  scope="row"
                                  className={sectionstyles.cbse_table_head2}
                                  align="center"
                                >
                                  <Switch
                                    checked={show.active}
                                    onChange={(e) =>
                                      switchHandler(e, show.id)
                                    }
                                    disabled
                                  />
                                </TableCell>

                                <TableCell
                                  scope="row"
                                  className={sectionstyles.cbse_table_head3}
                                  align="center"
                                >
                                  <button
                                    className={sectionstyles.button}
                                    onClick={async (e) => {
                                      if (createSection) {
                                        await addSection((data) =>
                                          handleTempSection(e, show, data)
                                        );
                                      } else {
                                        await updateMapping(
                                          e,
                                          show,
                                          section
                                        );
                                      }
                                    }}
                                    id="section_table_btn"
                                    disabled={sectionType === "NO_SECTION"}
                                  >
                                    Add
                                  </button>
                                </TableCell>
                                <TableCell
                                  className={sectionstyles.cbse_table_head2}
                                >
                                  {res.mappedGrade.length > 1 &&
                                    res.mappedGrade.map((yes, indx) => {
                                      return (
                                        show.grade === yes.grade &&
                                        indx === 0 && (
                                          <IconButton
                                            aria-label="expand row"
                                            size="small"
                                            onClick={() =>
                                              handleClick("subSec", ind)
                                            }
                                            id={section.id}
                                          >
                                            {expanded[ind] ? (
                                              <img
                                                src={arrow__Up}
                                                alt="Up-Arrow"
                                              />
                                            ) : (
                                              <img
                                                src={arrow__Down}
                                                alt="Down-Arrow"
                                              />
                                            )}
                                          </IconButton>
                                        )
                                      );
                                    })}
                                </TableCell>
                              </TableRow>
                            </>
                          );
                        })
                        : // if mappedGrade array's length is zero
                        gradeSection.map((u, index) => {
                          return (
                            u.grade === res.gradeName && (
                              <>
                                <TableRow
                                  style={{
                                    borderBottom: "unset",
                                  }}
                                  className={
                                    sectionstyles.table_row_section
                                  }
                                  id={u.id}
                                >
                                  <TableCell
                                    scope="row"
                                    className={
                                      sectionstyles.cbse_table_head3
                                    }
                                    style={{ color: "#354052" }}
                                  >
                                    {u.grade}
                                  </TableCell>
                                  <TableCell
                                    scope="row"
                                    className={
                                      sectionstyles.cbse_table_head2
                                    }
                                    style={{ textAlign: "center" }}
                                  >
                                    <>
                                      <input
                                        type="text"
                                        placeholder="Ex: A,B"
                                        onChange={(event) => {
                                          setSearchValue(
                                            event.target.value
                                          );
                                          if (event.target.value !== "") {
                                            const savingSection =
                                              sectionList.filter((e) => {
                                                const capsLetter =
                                                  event.target.value.toUpperCase();
                                                return (
                                                  e.section === capsLetter
                                                );
                                              });
                                            if (savingSection.length > 0) {
                                              setCreateSection(false);
                                              setSection(savingSection);
                                            } else {
                                              setnewSection(
                                                event.target.value.toUpperCase()
                                              );
                                              setCreateSection(true);
                                            }
                                          }
                                        }}
                                        list="sectionDatas"
                                      />
                                      {sections.length > 0 && (
                                        <datalist id="sectionDatas">
                                          {sections.map((section, i) => {
                                            return (
                                              <option
                                                key={section.id}
                                                value={section.section}
                                              />
                                            );
                                          })}
                                        </datalist>
                                      )}
                                    </>
                                  </TableCell>
                                  <TableCell
                                    scope="row"
                                    className={
                                      sectionstyles.cbse_table_head2
                                    }
                                    align="center"
                                  >
                                    <Switch
                                      checked={u.active}
                                      onChange={(e) =>
                                        switchHandler(e, u.id)
                                      }
                                      disabled
                                    />
                                  </TableCell>

                                  <TableCell
                                    scope="row"
                                    className={
                                      sectionstyles.cbse_table_head3
                                    }
                                    align="center"
                                  >
                                    <button
                                      className={sectionstyles.button}
                                      onClick={async (e) => {
                                        if (createSection) {
                                          await addSection((data) =>
                                            handleTempSection(e, u, data)
                                          );
                                        } else {
                                          await addMappingNew(
                                            e,
                                            u,
                                            section
                                          );
                                        }
                                      }}
                                      id="section_table_btn"
                                      disabled={
                                        sectionType === "NO_SECTION"
                                      }
                                    >
                                      Add
                                    </button>
                                  </TableCell>
                                  <TableCell
                                    scope="row"
                                    className={
                                      sectionstyles.cbse_table_head2
                                    }
                                  ></TableCell>
                                </TableRow>
                              </>
                            )
                          );
                        });
                    })
                    : // if gradeSectionMapping length is zero
                    gradeSection.map((u, index) => {
                      return (
                        <>
                          <TableRow
                            style={{
                              borderBottom: "unset",
                            }}
                            className={sectionstyles.table_row_section}
                            id={u.id}
                          >
                            <TableCell
                              scope="row"
                              className={sectionstyles.cbse_table_head3}
                              style={{ color: "#354052" }}
                            >
                              {u.grade}
                            </TableCell>
                            <TableCell
                              scope="row"
                              className={sectionstyles.cbse_table_head2}
                              align="center"
                              style={{ textAlign: "center" }}
                            >
                              <>
                                <input
                                  type="text"
                                  placeholder="Ex: A,B"
                                  onChange={(event) => {
                                    setSearchValue(event.target.value);
                                    if (event.target.value !== "") {
                                      const savingSection =
                                        sectionList.filter((e) => {
                                          const capsLetter =
                                            event.target.value.toUpperCase();
                                          return e.section === capsLetter;
                                        });
                                      if (savingSection.length > 0) {
                                        setCreateSection(false);
                                        setSection(savingSection);
                                      } else {
                                        setnewSection(
                                          event.target.value.toUpperCase()
                                        );
                                        setCreateSection(true);
                                      }
                                    }
                                  }}
                                  list="sectionDatas"
                                  disabled={sectionType === "NO_SECTION"}
                                />
                                {sections.length > 0 && (
                                  <datalist id="sectionDatas">
                                    {sections.map((section, i) => {
                                      return (
                                        <option
                                          key={section.id}
                                          value={section.section}
                                        />
                                      );
                                    })}
                                  </datalist>
                                )}
                              </>
                            </TableCell>
                            <TableCell
                              scope="row"
                              className={sectionstyles.cbse_table_head2}
                              align="center"
                            >
                              <Switch
                                checked={u.active}
                                onChange={(e) => switchHandler(e, u.id)}
                                disabled
                              />
                            </TableCell>

                            <TableCell
                              scope="row"
                              className={sectionstyles.cbse_table_head3}
                              align="center"
                            >
                              <button
                                className={sectionstyles.button}
                                onClick={async (e) => {
                                  if (createSection) {
                                    await addSection((data) =>
                                      handleTempSection(e, u, data)
                                    );
                                  } else {
                                    await addMappingNew(e, u, section);
                                  }
                                }}
                                id="section_table_btn"
                                disabled={sectionType === "NO_SECTION"}
                              >
                                Add
                              </button>
                            </TableCell>
                            <TableCell
                              scope="row"
                              className={sectionstyles.cbse_table_head2}
                            ></TableCell>
                          </TableRow>
                        </>
                      );
                    })}
                </TableBody>
              </Table>
            </TableContainer>
          </Paper>
        </div>
        <CommonDeleteModal
          open={openDeleteModal}
          close={hideDeleteModal}
          deleteTitle={"Section"}
          deleteHandler={handleDeleteSection}
          deleteId={selectedItem?.id}
          deleteName={selectedItem?.section}
        />
        {/* alert */}
        <ConfirmationAlert
          open={showAlert}
          // onClose={hideAlertHandler}
          yesClick={handleDeleteSection}
          close={hideAlertHandler}
          okClick={errorMessage}
          alert={alertMessage}
        />
        <MappingAlert
          open={showAlert}
          onClose={hideAlertHandler}
          close={hideAlertHandler}
          alert="No Permission to delete or deactivate or edit as it has existing mapping in the workflow...!"
        />
        <ConfirmationModal
          open={toggleConfirmation}
          confirmationmessage={toggleMessage}
          close={handleConfirmationClose}
          updateToggle={updateToggle}
        />
        <UpdatedModal
          userType={"Section"}
          userName_Modal={updatedName}
          open={succesUpdatesModal}
          handleOk={closeModal}
          close={closeModal}
        />
        <ConfigurationSuccessModal
          userType={"Section Added Successfully"}
          open={isAddSectionModal}
          handleOk={onCloseAddSectionModal} />
      </article>
    </>
  );
};
export default BranchSectionTesting;
