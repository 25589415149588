import React, { useEffect, useState, useRef } from "react";
import axios from "axios";
import { Link, useNavigate, useLocation, useParams } from "react-router-dom";
import chapterStyles from "./../css/createChapter.module.css";
import dashboardContentStyles from "./../css/dashboardContent.module.css";
import createquestionstyle from "./../css/NavbarAddStudentModal.module.css";
import createschool from "./../css/CreateSchool.module.css";
import BlueButton from "../../components/common/BlueButton";
import GreenAddButton from "../../components/common/GreenAddButton";
import WhiteAddButton from "../../components/common/WhiteButton";
import InactiveButton from "../../components/common/InactiveButton";
import AddCircleOutlineOutlinedIcon from "@mui/icons-material/AddCircleOutlineOutlined";
import Pagination1 from "./pagination1";
import McqCreationQB from "./McqCreationQB";
import CaseStudy from "./CaseStudy";
import FillBlankModal from "./FillBlankModal";
import FillInTheBlanks from "./QuestionBank/FillIntheBlanks";
import {
  Box,
  Button,
  FormControl,
  IconButton,
  InputLabel,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Paper,
  Select,
  Tooltip,
  Grid,
} from "@mui/material";
import ExamMcqModal from "./ExamMcqModal";
import { SearchOutlined } from "@mui/icons-material";
import conceptlist_get from "../API_CONTROLLER/concept-controller/conceptlist_get";
import GradesControllerGetall from "../API_CONTROLLER/grades-controller/grades_controller_getall";
import boards_controller_getall from "../API_CONTROLLER/boards-controller/boards_controller_getall";
import subjects_controller_getall from "../API_CONTROLLER/subject_controller/subjects_controller_getall";
import subtopics_controller_getall from "../API_CONTROLLER/subtopic-controller/subtopics_controller_getall";
import chapters_controller_getall from "../API_CONTROLLER/chapters-controller/chapters_controller_get";
import chapters_controller_post from "../API_CONTROLLER/chapters-controller/chapters_controller_post";
import chapters_file_uploader_post from "../API_CONTROLLER/chapters-controller/chapters_file_uploader_post";
import chapters_last_modified_get from "../API_CONTROLLER/chapters-controller/chapters_last_modified_get";
import chapters_edit_getby_id from "../API_CONTROLLER/chapters-controller/chapters_edit_getby_id";
import chapters_controller_put from "../API_CONTROLLER/chapters-controller/chapters_controller_put";
import rootConcept_controller_get from "../API_CONTROLLER/root-concept-controller/rootConcept_controller_get";
import subConcept_controller_get from "../API_CONTROLLER/concept-controller/subConcept_controller_get";
import taxonomy_categorylist_get from "../API_CONTROLLER/taxonomy-controller/taxonomy_categorylist_get";
import queType_get from "../API_CONTROLLER/question-controller/queType_get";
import MultiSelectQB from "./MultiSelectQB";
import SelectQuestions from "./QuestionBank/CommonComponents/SelectQuestions";
import dashHome from "../../../src/img/dashHome.svg";
import searchIcn from "../../../src/img/search.svg";
import CreateCaseStudy from "./QuestionBank/CreateCaseStudy";
import MatchTheFollowing from "./QuestionBank/MatchTheFollowing";
import get_question from "../API_CONTROLLER/create-questions/get_questionbyid";
import BreadCrumbs from "../js/RevisionAdmin/General/BreadCrumbs";

const ObjectiveQuestion = (props) => {
  const [gradesName, setGradesName] = useState({});
  const [taxonomylist, setTaxonomylist] = useState({});
  const [quetype, setQuetype] = useState({});
  const [board, setBoard] = useState({});
  const [subject, setSubject] = useState({});
  const [subtopic, setSubtopic] = useState({});
  const [chapters, setChapters] = useState({});
  const [chapter, setChapter] = useState("");
  const [lastModifiedDate, setLastModifiedDate] = useState("");
  const [isAddChapter, setIsAddChapter] = useState(true);
  const [isError, setIsError] = useState(false);
  const [showError, setShowError] = useState("");
  const [success, setSuccess] = useState({});
  const [conceptlist, setConceptlist] = useState({});
  const [subconceptList, setSubconceptList] = useState({});
  const [rootConceptList, setRootConceptList] = useState({});
  const [subjectiveQuestionType, setSubjectiveQuestionType] = useState("");
  const [subConceptInputs, setSubConceptInputs] = useState({
    subConceptId: "",
  });
  const [conceptInputs, setConceptInputs] = useState({
    conceptId: "",
    concept: "",
    rootConcept: "",
    subTopicId: "",
    subjectsId: "",
  });
  const navigate = useNavigate();
  const prevLocation = useLocation();

  const [chapterInputs, setChapterInputs] = useState({
    boardId: "",
    gradeId: "",
    subjectId: "",
    chapter: "",
    // docType:"",
    // logoUrl:"",
    chapterStatus: "PENDING",
  });

  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [filterValue, setFilterValue] = useState();

  const [gradeInput, setGradeInput] = useState("");
  const [subjectInput, setSubjectInput] = useState("");
  const [subTopicInput, setSubTopicInput] = useState("");
  const [questionType, setQuestionType] = useState("");

  const [newConcept, setNewConcept] = useState(false);

  const [goToQuestionBank, setGoToQuestionBank] = useState(false);

  const [responseValue, setResponseValue] = useState();

  const [disableTopFields, setDisableTopFields] = useState(false);

  let token = sessionStorage.getItem("token");

  const { id } = useParams();

  sessionStorage.setItem("questionPattern", "OBJECTIVE");

  useEffect(() => {
    if (id) {
      get_question(id, setResponseValue);
    }
    else {
      setResponseValue();
      setDisableTopFields(false);
      setGoToQuestionBank(true);
    }
  }, [id]);

  if (prevLocation.state === "edit") {
    setIsAddChapter(false);
    prevLocation.state = null;
  }

  const handleCreateChapter = (e) => {
    e.preventDefault();
    for (const input in chapterInputs) {
      if (chapterInputs[input] == "") {
        setIsError(true);
        setShowError("Please enter all required valules");
        return;
      }
    }

    chapters_controller_post(chapterInputs, setSuccess, setShowError);
    // setChapterInputs("")
    // navigate('/dashboard/Upload%20Content')
  };

  // pagination starts
  const [rowsInput, setRowsInputs] = useState({
    rows: 10,
  });
  // const [page , setPage] = useState(1);
  const [pageDetail, setPageDetail] = useState({});

  // pagination ends

  const [search, setSearch] = useState({
    search: "",
  });

  useEffect(() => {
    if (success.success === false) {
      setIsError(true);
      setShowError(success.message);
      return () => { };
    }
    if (success.success === true) {
      setIsError(false);
    }
  }, [success]);

  useEffect(() => {
    if (goToQuestionBank === true) {
      setQuestionType("");
    }
  }, [goToQuestionBank])

  // useEffect(() => {
  //   setChapterInputs({
  //     ...chapterInputs,
  //     logourl: sessionStorage.getItem("logourl"),
  //   });
  //   return () => {};
  // }, [sessionStorage.getItem("logoUrl")]);



  // useEffect(() => {
  //   chapters_controller_getall(
  //     setChapters,
  //     rowsInput,
  //     page,
  //     setPageDetail,
  //     search,
  //     gradeInput,
  //     subjectInput,
  //     subTopicInput
  //   );
  // }, [rowsInput, page]);

  // useEffect(() => {
  //   chapters_controller_getall(
  //     setChapters,
  //     rowsInput,
  //     page,
  //     setPageDetail,
  //     search,
  //     gradeInput,
  //     subjectInput,
  //     subTopicInput
  //   );
  //   return () => {};
  // }, [search, gradeInput, subjectInput, subTopicInput]);

  // useEffect(() => {
  //   subConcept_controller_get(conceptInputs, setSubconceptList);
  // }, [conceptInputs]);

  // useEffect(() => {
  //   rootConcept_controller_get(subConceptInputs, setRootConceptList);
  // }, [subConceptInputs]);

  useEffect(() => {
    // GradesControllerGetall(setGradesName);
    // boards_controller_getall(setBoard);
    // subjects_controller_getall(setSubject);
    // subtopics_controller_getall(setSubtopic);
    // taxonomy_categorylist_get(setTaxonomylist);
    // conceptlist_get(setConceptlist);
    chapters_last_modified_get(setLastModifiedDate);
    // queType_get(setQuetype);
    // documentType_controller_getall(setDocumenttype);

    return () => { };
  }, []);

  const questionTypeChange = (value) => {
    setQuestionType(value);
  };

  const subjectiveQuestionTypeChange = (value) => {
    setSubjectiveQuestionType(value);
  };

  return (
    <>
      <article>
        <Grid container>
          <Grid item lg={12}>
            <BreadCrumbs currentPage={"Question Bank"} />
            {/* <div className={dashboardContentStyles.dashboard_link}>
              <span className={dashboardContentStyles.link_icon}>
                <img
                  src={dashHome}
                  alt="no img"
                  width="15px"
                  height="20px"
                  style={{ verticalAlign: "middle" }}
                />
              </span>
              {"  "}
              <span className={dashboardContentStyles.link_text}>Home</span>
              {"  "}
              <span>
                <i className="fa-solid fa-angle-right"></i>
              </span>
              <span>
                <i className="fa-solid fa-angle-right"></i>
              </span>
              {"  "}
              <a>Question Bank</a>
            </div>
            <div className={dashboardContentStyles.dashboard_last_updated}>
              <p>
                Last Update:
                {lastModifiedDate.data}
                
              </p>
            </div> */}
          </Grid>
          {/* create chapter */}
          <Grid item lg={12}>
            <Paper
              style={{
                margin: "4% 1% 0%",
              }}
            >
              <Grid container>
                <Grid
                  item
                  xs={12}
                  style={{
                    borderBottom: "1px solid #00000029",
                    marginBottom: "10px",
                    display: "flex",
                  }}
                >
                  <div
                    style={{
                      width: "17vw",
                      borderBottom: "4px solid rgb(253, 140, 0)",
                      borderRight: "1px solid #00000029",
                      textAlign: "center",
                    }}
                  >
                    {" "}
                    <p
                      style={{
                        color: "orange",
                        fontSize: "1.2vw",
                        padding: "10px",
                        cursor: 'pointer'
                      }}
                      onClick={(e) => {
                        e.preventDefault();
                        sessionStorage.removeItem("mapUrl"); sessionStorage.removeItem("mapImage");
                        sessionStorage.removeItem("uploadedImg"); sessionStorage.removeItem("logoUrl");
                        navigate("/dashboard/QuestionBank");
                      }}
                    >
                      Objective
                    </p>
                  </div>
                  <div
                    style={{
                      width: "17vw",
                      borderRight: "1px solid #00000029",
                      textAlign: "center",
                    }}
                  >
                    {" "}
                    <p
                      style={{
                        color: "grey",
                        fontSize: "1.2vw",
                        padding: "10px",
                        cursor: 'pointer'
                      }}
                      onClick={(e) => {
                        e.preventDefault();
                        sessionStorage.removeItem("mapUrl");sessionStorage.removeItem("mapImage");
                        sessionStorage.removeItem("uploadedImg");sessionStorage.removeItem("logoUrl");
                        navigate("/dashboard/QuestionBank/Subjective");
                      }}
                    >
                      Subjective
                    </p>
                  </div>
                </Grid>
              </Grid>
              <SelectQuestions
                objQuestionTypeChange={questionTypeChange}
                objSubjectiveQuestionTypeChange={subjectiveQuestionTypeChange}
                questionData={responseValue}
                newConcept={newConcept}
                disableTopFields={disableTopFields}
                setNewConcept={setNewConcept}
                goToQuestionBank={goToQuestionBank}
                setGoToQuestionBank={setGoToQuestionBank}
              />
            </Paper>
          </Grid>
          <Grid item lg={12}>
            <Paper
              style={{
                margin: "4% 1% 0%",
              }}
            >
              {questionType === "FILL" || responseValue && responseValue.questionTypeCode === "FILL" ? (
                <FillInTheBlanks
                  setNewConcept={setNewConcept}
                  setGoToQuestionBank={setGoToQuestionBank}
                  setDisableTopFields={setDisableTopFields}
                  questionData={responseValue}
                  fromViewAllQuestion={id}
                />
              ) : questionType === "MCQ" || responseValue && responseValue.questionTypeCode === "MCQ" ? (
                <McqCreationQB
                  setNewConcept={setNewConcept}
                  setGoToQuestionBank={setGoToQuestionBank}
                  setDisableTopFields={setDisableTopFields}
                  questionData={responseValue}
                  fromViewAllQuestion={id}
                />
              ) : questionType === "CASE" || responseValue && responseValue.questionTypeCode === "CASE" ? (
                <CreateCaseStudy
                  setNewConcept={setNewConcept}
                  setGoToQuestionBank={setGoToQuestionBank}
                  setDisableTopFields={setDisableTopFields}
                  questionData={responseValue}
                  fromViewAllQuestion={id}
                />
              ) : questionType === "MSQ" || responseValue && responseValue.questionTypeCode === "MSQ" ? (
                <MultiSelectQB
                  setNewConcept={setNewConcept}
                  setGoToQuestionBank={setGoToQuestionBank}
                  setDisableTopFields={setDisableTopFields}
                  questionData={responseValue}
                  fromViewAllQuestion={id}
                />
              ) : questionType === "MATCH" || responseValue && responseValue.questionTypeCode === "MATCH" ? (
                <MatchTheFollowing
                  setNewConcept={setNewConcept}
                  setGoToQuestionBank={setGoToQuestionBank}
                  setDisableTopFields={setDisableTopFields}
                  questionData={responseValue}
                  fromViewAllQuestion={id}
                />
              ) : (
                <></>
              )}
            </Paper>
          </Grid>
        </Grid>
      </article>
    </>
  );
};

export default ObjectiveQuestion;
