import React, { useState, useEffect, useRef } from "react";
import {
  Button,
  Divider,
  FormControl,
  IconButton,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select,
  TextField,
  Paper,
  Box,
  InputLabel,
  Modal,
} from "@mui/material";
import PsychologyAltOutlinedIcon from "@mui/icons-material/PsychologyAltOutlined";
import ArrowCircleUpIcon from "@mui/icons-material/ArrowCircleUp";
import ArrowCircleDownIcon from "@mui/icons-material/ArrowCircleDown";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import dashboardContentStyles from "../css/dashboardContent.module.css";
import { MenuProps } from "./Data";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import DarkGreenButton from "../../components/common/DarkGreenButton";
import { styled } from "@mui/material/styles";
import DarkYellowButton from "../../components/common/DarkYellowButton";
import DocViewer, { DocViewerRenderers } from "@cyntler/react-doc-viewer";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import Rotate90DegreesCwOutlinedIcon from "@mui/icons-material/Rotate90DegreesCwOutlined";
import Rotate90DegreesCcwOutlinedIcon from "@mui/icons-material/Rotate90DegreesCcwOutlined";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import PlayCircleIcon from "@mui/icons-material/PlayCircle";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import PauseCircleIcon from "@mui/icons-material/PauseCircle";
import PauseIcon from "@mui/icons-material/Pause";
import SelectSectionModal from "./SelectSectionModal";
import { FormattedTime } from "react-player-controls";
import "../css/switch.css";
import ReactPlayer from "react-player";
import SideMenu from "./SideMenu";
import GetLastModifiedAt from "../API/token-controller/GetLastModifiedAt";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import dashHome from "../../../src/img/dashHome.svg";
import ReactAudioPlayer from "react-audio-player";
import AudiotrackOutlinedIcon from "@material-ui/icons/AudiotrackOutlined";
import { isNOTNullOrUndefined, isNullOrUndefined } from "../js/TeachValidate";
import StartedSuccess from "./StartedSuccess";
import { Tooltip } from "@material-ui/core";
import moment from "moment";
import CustomVideoPlayer from "../js/CustomVideoPlayer/CustomVideoPlayer";
import HomeWorkModal from "./HomeWorkAssignment/HomeWorkModal";
import { truncateString } from "../../utils/helper";
import { Worker, Viewer } from "@react-pdf-viewer/core";
import { defaultLayoutPlugin } from "@react-pdf-viewer/default-layout";
import { toolbarPlugin } from "@react-pdf-viewer/toolbar";
import "@react-pdf-viewer/core/lib/styles/index.css";
import "@react-pdf-viewer/default-layout/lib/styles/index.css";
import { handleRightClick } from '../../utils/AppUtility';
import ContentViewer from '../../components/common/ContentViewer';
import { SpecialZoomLevel } from "@react-pdf-viewer/core";

const RedButton = styled(Button)((props) => ({
  // color: "#fff",
  color: props.disabled ? "#fff" : "#fff",
  backgroundColor: props.disabled ? "#ccc" : "red",

  // backgroundColor: "red",
  borderColor: "red",
  textTransform: "none",
  display: "flex",
  flexDirection: "row",
  columnGap: "10px",
  fontSize: "0.9vw",
  "&:hover": {
    color: "#fff",
    backgroundColor: "red",
    border: 0,
    boxShadow: "0px 3px 5px red",
  },
}));
const GrayButton = styled(Button)(() => ({
  color: "#fff",
  backgroundColor: "gray",
  borderColor: "gray",
  textTransform: "none",
  display: "flex",
  flexDirection: "row",
  columnGap: "10px",
  fontSize: "0.9vw",
  "&:hover": {
    color: "#fff",
    backgroundColor: "gray",
    border: 0,
    boxShadow: "0px 3px 5px gray",
  },
}));
const YButton = styled(Button)(() => ({
  color: "#fff",
  backgroundColor: "#ffcc00",
  borderColor: "#ffcc00",
  textTransform: "none",
  display: "flex",
  flexDirection: "row",
  columnGap: "10px",
  fontSize: "0.9vw",
  "&:hover": {
    color: "#fff",
    backgroundColor: "#ffcc00",
    border: 0,
    boxShadow: "0px 3px 5px #ffcc00",
  },
}));
const WhiteButton = styled(Button)(() => ({
  color: "black",
  backgroundColor: "#fff",
  textTransform: "none",
  border: "1px solid #757575",
  borderColor: "#757575",
  fontWeight: "500",
  fontSize: "0.9vw",
  boxShadow: "1px -0px -0px 0px rgba(117,117,117,0.77)",
  "&:hover": {
    color: "black",
    backgroundColor: "#fff",
  },
}));
const PresentationButton = styled(Button)(() => ({
  color: "black",
  textTransform: "none",
  fontWeight: "500",
  fontSize: "0.9vw",
  backgroundColor: "rgb(207,205,205)",
  border: "0px",
  borderRadius: "150px",
}));

const ViewContentPage = ({
  menuType,
  setIsClicked,
  studyData,
  closeModal,
  selectedChapterId,
  pageName,
  setSelectedSection,
  selectedSection,
  selectedAcademicYear,
  setSelectedAcademicYear,
  selectedSubject,
  selectedSubTopic,
  showSubTopic
}) => {
  //console.log("viewcontentpage called", studyData)
  const token = sessionStorage.getItem("token");
  const navigate = useNavigate();
  const [collapseId, setCollapseId] = useState("");
  const [numPages, setNumPages] = useState(null);
  const [presentationNumPages, setPresentationNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  const [presentationPageNumber, setPresentationPageNumber] = useState(1);
  const [startIndex, setStartIndex] = useState(0);
  const [scale, setScale] = useState(1);
  const [rotate, setRotate] = useState(0);
  const [enterPageNo, setEnterPagNo] = useState("");
  const [selectedMenu, setSelectedMenu] = useState("");
  const [selectedItemDetails, setSelectedItemDetails] = useState({});
  const [videoDetailStart, setVideoDetailStart] = useState({});
  const [showSectionModal, setShowSectionModal] = useState(false);
  const [tabsValue, setTabsValue] = useState("1");
  const [tab, setTab] = useState("Practice Question");
  const [modifiedDate, setModifiedDate] = useState({});
  const [sectionList, setSectionList] = useState([]);
  const [academicYearList, setAcademicYearList] = useState([]);
  const [selectedUrl, setSelectedUrl] = useState("");
  const [isPlaying, setPlaying] = useState(false);
  const [duration, setDuration] = useState(0);
  const [progressObj, setProgressObj] = useState({});
  const [subDurationValue, setSubDurationValue] = useState(0);
  const [teacherId, setTeacherId] = useState("");
  const [Section, setSection] = useState([]);
  const [startDisabled, setStartDisabled] = useState(false);
  const [endDisabled, setEndDisabled] = useState(true);
  const [quizDisabled, setQuizDisabled] = useState(true);
  const [isSuccessModalOpen, setSuccessModalOpen] = useState(false);
  const [isChapterStarted, setIsChapterStarted] = useState(false);
  const [teacherDetail, setTeacherDetail] = useState({
    firstName: "",
    lastName: "",
    schoolName: "",
    joinDate: "",
    designation: "",
    email: "",
    mobile: "",
    id: "",
  });
  const [buttonsActiveState, setButtonsActiveState] = useState({
    startButton: true,
    endButton: false,
    assignButton: false,
  });
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  const [docType, setDocType] = useState("");

  const userName = localStorage.getItem("userName");
  const role = localStorage.getItem("role");
  const videoRef = useRef(null);
  //GET SESSION DATA
  const boardId = sessionStorage.getItem("boardId");
  const schoolId = sessionStorage.getItem("schoolId");
  const branchId = sessionStorage.getItem("branchId");
  const gradeId = sessionStorage.getItem("gradeId");
  const sectionId = sessionStorage.getItem("sectionId");
  const subjectId = sessionStorage.getItem("subjectId");
  const subTopicId = sessionStorage.getItem("subTopicId");
  const academicYearId = sessionStorage.getItem("academicYearId");

  const [isHomeWorkModalOpen, setIsHomeWorkModalOpen] = useState(false);
  const [assignedAccess, setAssignedAccess] = useState([]);
  const [sectn, setSectn] = useState(selectedSection);

  // const defaultLayoutPluginInstance = defaultLayoutPlugin();
  let ws_dnl = localStorage.getItem("wsDownload");
  let wb_dwload = localStorage.getItem("wbDownload");

  const toolbarPluginInstance = toolbarPlugin();
  const { renderDefaultToolbar } = toolbarPluginInstance;

  const transform = (slot) => ({
    ...slot,
    Download: () => <></>,
    Open: () => <></>,
    Print: () => <></>,
  });

  const renderToolbar = (Toolbar) => (
    <Toolbar>{renderDefaultToolbar(transform)}</Toolbar>
  );

  const defaultLayoutPluginInstance = defaultLayoutPlugin({
    sidebarTabs: (defaultTabs) => [
      defaultTabs[0], // Thumbnails tab      
    ],
    // setInitialTab: (doc) => Promise.resolve(0),
    renderToolbar,
  });


  ////////////////////

  const toolbarPluginInstance1 = toolbarPlugin();
  const { renderDefaultToolbar: renderDefaultToolbar1 } = toolbarPluginInstance1;

  const transform1 = (slot) => ({
    ...slot,
    Open: () => <></>,
    OpenMenuItem: () => <></>

  });

  const renderToolbar1 = (Toolbar) => (
    <Toolbar>{renderDefaultToolbar1(transform1)}</Toolbar>
  );

  const defaultLayoutPluginInstance1 = defaultLayoutPlugin({
    sidebarTabs: (defaultTabs) => [
      defaultTabs[0],
    ],
    renderToolbar: renderToolbar1,
  });


  useEffect(() => {
    //console.log("wswb >>>>> ", ws_dnl, wb_dwload);
  }, []);

  useEffect(async () => {
    // await GetLastModifiedAt(setModifiedDate);
    await fetchTeacherData(userName, role);
    const teacherData = await fetchTeacherData(userName, role);
    // Set the initial value of the filter
    const storedSectionId = sessionStorage.getItem("sectionId");
    if (storedSectionId) {
      setSelectedSection(storedSectionId);
    }
    fetchStartEnd();
    setTeacherDetail(teacherData);
    return () => { };
  }, []);

  useEffect(() => {
    if (Object.keys(progressObj).length !== 0) {
      if (progressObj && duration) {
        setSubDurationValue(duration - progressObj?.playedSeconds);
      }
    }
  }, [progressObj, duration]);

  const nameHandler = (name) => {
    const data = name?.split("/")?.[name.split("/")?.length - 1]?.split(".");
    // return `${data[0]}`;

    var str = data[0];
    return str.split(' ').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ')
    // return name;
  };


  const checkLastIndexHandler = (menu, value) => {
    // const array = menu.value.split(".");
    // const lastIndex = array?.[array.length - 1];
    // return lastIndex.includes(value);
    // return value;
    return menu.includes(value);

  };

  const fetchAcademicYearData = async () => {
    const res = await axios.get(
      `${process.env.REACT_APP_SWAGGER_URL}v1/api/master/academic-years/all`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return res?.data?.data || [];
  };

  const fetchTeacherData = async (userName, role) => {
    const res = await axios.get(
      `${process.env.REACT_APP_SWAGGER_URL}v1/api/user/users/profile?username=${userName}&roles=${role}`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );
    setTeacherId(res.data.data.teacher);
    sessionStorage.setItem(
      "profile",
      res.data.data.teacher.academicStaffProfile
    );
  };

  const fetchSection = async () => {
    // const id = '4028928886f00aec0186fda2e0a70004'
    const gradeId = sessionStorage.getItem("gradeId");
    const id = teacherId.id;
    await axios
      .get(
        `${process.env.REACT_APP_SWAGGER_URL}v1/api/user/teachers/assigned-grades?teacherId=${id}&menuName=ASSIGN`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((response) => {
        let gradeFilter = response?.data?.data?.filter((i) => i.id === gradeId);
        const sectionsArray = gradeFilter?.map(item => item.sections).flat();
        setSection(sectionsArray);
      })
      .catch((err) => console.log(err));
  };

  const quizRelease = async () => {
    const bordId = sessionStorage.getItem("boardId");
    const schoolId = sessionStorage.getItem("schoolId");
    const branchId = sessionStorage.getItem("branchId");
    const gradeId = sessionStorage.getItem("gradeId");
    const sectionId = sessionStorage.getItem("sectionId");
    const subjectId = sessionStorage.getItem("subjectId");
    const subTopicId = sessionStorage.getItem("subTopicId");
    const academicYearId = sessionStorage.getItem("academicYearId");
    const chapterId = selectedChapterId;
    const data = {
      bordId,
      gradeId,
      schoolId,
      branchId,
      subjectId,
      subTopicId,
      sectionId,
      chapterId,
      academicYearId,
    };
    await axios
      .get(
        `${process.env.REACT_APP_SWAGGER_URL
        }v1/api/content/quiz-release/list-to-teacher?schoolId=${schoolId}&branchId=${branchId}&boardId=${bordId}&gradeId=${gradeId}&sectionId=${sectionId}&subjectId=${subjectId}&chapterId=${selectedChapterId}&academicYearId=${academicYearId}${isNOTNullOrUndefined(subTopicId) ? `&subTopicId=${subTopicId}` : ""
        }`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((response) => {
        if (response?.data) {
          navigate("/dashboard/assign%20quiz", {
            state: { ...data, id: selectedChapterId, from: "Teach" },
          });
        } else {
          alert("Something went wrong while releasing , try later");
          fetchStartEnd();
        }
      })
      .catch((err) => console.log(err.message, "error"));
  };

  useEffect(async () => {
    await fetchSection();
    const academic = await fetchAcademicYearData();
    setAcademicYearList(academic);

    // Set the initial value of the filter
    const storedAcademicYearId = sessionStorage.getItem("academicYearId");
    if (storedAcademicYearId) {
      setSelectedAcademicYear(storedAcademicYearId);
    } else if (academic.length > 0) {
      setSelectedAcademicYear(academic[0].id);
      sessionStorage.setItem("academicYearId", academic[0].id);
    }
  }, [teacherId.id]);

  useEffect(() => {
    if (studyData?.length) {
      // const data = studyData[0].studyDocumentCategories;
      const data = studyData[0]?.uploadContents || studyData[0]?.studentRevisionContent;
      if (!data?.length) {
        setSelectedMenu(data?.documentType);
        // console.log("setSelectedMenu,", data?.documentType, data.documentType === "Work Sheet")
      } else {
        setSelectedItemDetails(data[0]);
        setSelectedMenu(data[0]?.contentWithIndux);
        // console.log("setSelectedMenu,", data[0]?.contentWithIndux)
        setSelectedUrl(data[0]?.contentUrl);
      }
    }
  }, [studyData]);
  const handleClick = (id, type, item, index) => {
    // console.log(id, type, item, index, "---123")
    setCollapseId((pre) => (pre === id ? "" : id));
    if (!item.uploadContents?.length || !item.studentRevisionContent?.length) {
      setSelectedMenu(item?.documentType);
      // console.log("setSelectedMenu,", item?.documentType, type)
      // console.log("selected doc type >>> ", item?.documentType);
      setDocType(type);
    } else {
      // console.log("else called 24-6-24")
    }
    setStartIndex(index);
  };

  const subMenuHandler = (item, url, { details }) => {
    if (item !== selectedMenu) {
      setPlaying(false);
      setProgressObj({});
      setDuration(0);
      setSubDurationValue(0);
    }
    setSelectedItemDetails(details);
    setSelectedMenu(item);
    // console.log("setSelectedMenu", item)
    setSelectedUrl(url);
  };

  const onStartClick = async () => {
    const boardId = sessionStorage.getItem("boardId");
    const schoolId = sessionStorage.getItem("schoolId");
    const branchId = sessionStorage.getItem("branchId");
    const gradeId = sessionStorage.getItem("gradeId");
    const sectionId = sessionStorage.getItem("sectionId");
    const subjectId = sessionStorage.getItem("subjectId");
    const subTopicId = sessionStorage.getItem("subTopicId");
    const academicYearId = sessionStorage.getItem("academicYearId");
    const id = teacherId.id;
    //console.log("selectedChapterId", selectedChapterId)
    let data = {
      boardId: boardId,
      schoolId: schoolId,
      branchId: branchId,
      gradeId: gradeId,
      sectionId: sectionId,
      subjectId: subjectId,
      chapterId: selectedChapterId,
      academicYearId: academicYearId,
      operation: "START",
      teacherId: id,

    };
    //console.log("start1")
    if (isNOTNullOrUndefined(subTopicId) && showSubTopic) {
      data.subTopicId = subTopicId;
    }
    try {
      await axios
        .post(
          `${process.env.REACT_APP_SWAGGER_URL}v1/api/teacher/teach/chapter/start`,
          data,

          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((res) => {
          setIsChapterStarted(true);
          setSuccessModalOpen(true);
          fetchStartEnd();
        })
        .catch((error) => {
          if (error.response?.data?.errorCode === 404) {
            alert(error.response?.data?.message);
            fetchStartEnd();
          } else {
            alert("Something went wrong try later");
            fetchStartEnd();
          }
        });
    } catch (error) {
      // console.error(error, "errorrrrd");
    }
  };

  // VIDEO POST API
  const startVideoAPI = async () => {
    await axios
      .post(
        `${process.env.REACT_APP_SWAGGER_URL}v1/api/content/video-history`,
        {
          videoContentFrom: teacherId.academicStaffProfile,
          videoId: selectedItemDetails.id,
          actualDuration:
            duration !== Infinity && duration
              ? moment.utc(duration * 1000).format("HH:mm:ss")
              : "00:00:00",
          pausedOn: "00:00:00",
          watchingPersona: teacherId.academicStaffProfile,
          schoolId: schoolId,
          branchId: branchId,
          gradeId: gradeId,
          sectionId: sectionId,
          subjectId: subjectId,
          subTopicId: subTopicId,
          academicYearId: academicYearId,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        setVideoDetailStart(res.data.data);
      })
      .catch((err) => {
        //console.log("error", err);
      });
  };

  // RESUME VIDEO PUT API
  const pauseVideoAPI = async () => {
    await axios
      .post(
        `${process.env.REACT_APP_SWAGGER_URL}v1/api/content/video-history`,
        {
          id: videoDetailStart.id,
          videoContentFrom: teacherId.academicStaffProfile,
          videoId: selectedItemDetails.id,
          actualDuration:
            duration !== Infinity && duration
              ? moment.utc(duration * 1000).format("HH:mm:ss")
              : "00:00:00",
          pausedOn: progressObj.playedSeconds
            ? moment.utc(progressObj.playedSeconds * 1000).format("HH:mm:ss")
            : "00:00:00",
          watchingPersona: teacherId.academicStaffProfile,
          schoolId: schoolId,
          branchId: branchId,
          gradeId: gradeId,
          sectionId: sectionId,
          subjectId: subjectId,
          subTopicId: subTopicId,
          academicYearId: academicYearId,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((response) => {
        setVideoDetailStart(response.data.data);
      })
      .catch((err) => {
        //console.log(err, "error");
      });
  };

  const onVideoReady = (player) => {
    if (!progressObj.playedSeconds && (duration === Infinity || !duration))
      player.seekTo(10 ** 3);
  };

  const onEndClick = async () => {
    const boardId = sessionStorage.getItem("boardId");
    const schoolId = sessionStorage.getItem("schoolId");
    const branchId = sessionStorage.getItem("branchId");
    const gradeId = sessionStorage.getItem("gradeId");
    const sectionId = sessionStorage.getItem("sectionId");
    const subjectId = sessionStorage.getItem("subjectId");
    const subTopicId = sessionStorage.getItem("subTopicId");
    const academicYearId = sessionStorage.getItem("academicYearId");
    const id = teacherId.id;

    let data = {
      boardId: boardId,
      schoolId: schoolId,
      branchId: branchId,
      gradeId: gradeId,
      sectionId: sectionId,
      subjectId: subjectId,

      chapterId: selectedChapterId,
      academicYearId: academicYearId,
      operation: "END",
      teacherId: id,
    };

    if (isNOTNullOrUndefined(subTopicId) && showSubTopic) {
      data.subTopicId = subTopicId;
    }

    //console.log("data", data)
    await axios
      .put(
        `${process.env.REACT_APP_SWAGGER_URL}v1/api/teacher/teach/chapter/end/or/assign-quiz`,
        data,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((response) => {
        setIsChapterStarted(false);
        setSuccessModalOpen(true);
        fetchStartEnd();
      })
      .catch((err) => {
        if (err.response?.data?.errorCode === 404) {
          alert(err.response?.data?.message);
          // alert("Something went wrong try later");
        } else {
          alert("Something went wrong try later");
        }
        fetchStartEnd();
      });
  };

  const onAssignQuizClick = () => {
    quizRelease();

    // SS for Highlighthing the Sidenav Menu Name
    sessionStorage.setItem("sideNavMainMenuNames", "Assign Quiz");
  };

  useEffect(() => {
    if (isNullOrUndefined(startDate) && isNullOrUndefined(endDate)) {
      setButtonsActiveState((prev) => ({
        endButton: false,
        startButton: true,
        assignButton:
          isNOTNullOrUndefined(startDate) && isNOTNullOrUndefined(endDate),
      }));
    }

    if (startDate && isNullOrUndefined(endDate)) {
      setButtonsActiveState((prev) => ({
        endButton: true,
        startButton: false,
        assignButton:
          isNOTNullOrUndefined(startDate) && isNOTNullOrUndefined(endDate),
      }));
    }
    if (endDate && isNullOrUndefined(startDate)) {
      setButtonsActiveState((prev) => ({
        startButton: true,
        endButton: false,
        assignButton:
          isNOTNullOrUndefined(startDate) && isNOTNullOrUndefined(endDate),
      }));
    }
    if (isNOTNullOrUndefined(endDate) && isNOTNullOrUndefined(startDate)) {
      setButtonsActiveState({
        startButton: false,
        endButton: false,
        assignButton: true,
      });
    }
  }, [startDate, endDate]);

  // useEffect(() => {
  // //console.log('re fetching')
  //   fetchStartEnd()
  // }, [buttonsActiveState.startButton, buttonsActiveState.endButton])

  const fetchStartEnd = async () => {
    const boardId = sessionStorage.getItem("boardId");
    const schoolId = sessionStorage.getItem("schoolId");
    const branchId = sessionStorage.getItem("branchId");
    const gradeId = sessionStorage.getItem("gradeId");
    const sectionId = sessionStorage.getItem("sectionId");
    const subjectId = sessionStorage.getItem("subjectId");
    const academicYearId = sessionStorage.getItem("academicYearId");
    await axios
      .get(
        `${process.env.REACT_APP_SWAGGER_URL}v1/api/teacher/teach/chapter/min/details?schoolId=${schoolId}&branchId=${branchId}&boardId=${boardId}&gradeId=${gradeId}&sectionId=${sectionId}&subjectId=${subjectId}&chapterId=${selectedChapterId}&academicYearId=${academicYearId}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((response) => {
        setStartDate(response.data.data.startDate);
        setEndDate(response.data.data.endDate);
      })
      .catch((err) => console.log(err));
  };

  const handleSectionSelect = (e) => {
    setSectn(e.target.value);
    sessionStorage.setItem("sectionId", e.target.value);
    fetchStartEnd();
  };

  const [sectionFilteredList, setSectionFilteredList] = useState([]);

  useEffect(() => {
    const selectedGrade = sessionStorage.getItem("gradeId");
    // if (isNOTNullOrUndefined(selectedGrade) && Section?.length) {
    //   const filtered = Section?.find((i) => i.id === selectedGrade)?.sections;
    //   setSectionFilteredList(filtered);
    //   fetchSectionValidate();
    // }
    fetchSectionValidate();
  }, [Section, sectn,]);

  //-------| Display the sections associated with Selected Subject |-------//
  const sectionState = [];

  assignedAccess?.forEach((grade) => {
    grade?.subjects.forEach((subject) => {
      if (subject.subjectId === selectedSubject) {
        // Case 1: Sections associated with the subject directly
        if (subject.sectionsForSubject) {
          subject.sectionsForSubject.forEach((section) => {
            sectionState.push({
              subjectId: subject.subjectId,
              subject: subject.subject,
              sectionId: section.sectionId,
              section: section.section,
            });
          });
        }

        // Case 2: Sections associated with subtopics within the subject
        if (subject.subTopics) {
          subject.subTopics.forEach((subTopic) => {
            subTopic.sectionsForSubTopic.forEach((section) => {
              sectionState.push({
                subjectId: subject.subjectId,
                subject: subject.subject,
                subTopicId: subTopic.subTopicId,
                subTopic: subTopic.subTopic,
                sectionId: section.sectionId,
                section: section.section,
              });
            });
          });
        }
      }
    });
  });


  useEffect(() => {
    if (Section?.length > 0 && !sectn) {
      setSectn(Section[0]?.id);
    }
  }, [Section, sectn]);


  const fetchSectionValidate = async () => {
    const id = teacherId?.id;
    await axios
      .get(
        `${process.env.REACT_APP_SWAGGER_URL}v1/api/user/teachers/list/assigned-access?teacherId=${id}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((response) => {
        setAssignedAccess(response.data.data)
      })
      .catch((err) => console.log(err));
  };

  // console.log("(2)--->sectn", sectn)
  // console.log("--->Section", Section);
  // console.log("🟡--->sectionState", sectionState);
  // console.log("--->assignedAccess", assignedAccess);
  // console.log("--->selectedSubject", selectedSubject);
  // console.log("--->selectedSection", selectedSection);
  // console.log("--->sectionFilteredList", sectionFilteredList);

  const mql = window.matchMedia("(max-width: 2000px)");
  const smallScreen = mql.matches;
  const tabHandle = (event, newValue) => {
    setTab(newValue);
  };
  const tabsChangeHandle = (event, newValue) => {
    setTabsValue(newValue);
  };
  const addScale = () => {
    setScale((pre) => pre + 0.5);
  };
  const removeScale = () => {
    setScale((pre) => pre - 0.5);
  };
  const changePage = (offset) => {
    setPageNumber((prevPageNumber) => prevPageNumber + offset);
  };
  const changePresentationPage = (offset) => {
    setPresentationPageNumber((prevPageNumber) => prevPageNumber + offset);
  };
  const previousPresentationPage = () => {
    changePresentationPage(-1);
  };
  const nextPresentationPage = () => {
    changePresentationPage(1);
  };
  const previousPage = () => {
    changePage(-1);
  };
  const nextPage = () => {
    changePage(1);
  };

  const pageChangeHandler = (number) => {
    if (number) {
      setEnterPagNo(number);
    } else {
      setEnterPagNo("");
      setPageNumber(1);
    }
  };
  const handleClickRevision = (id, item, index) => {
    setCollapseId((pre) => (pre === id ? "" : id));
    if (!item?.studentRevisionContent?.length) {
      setSelectedMenu(item?.documentType);
      //console.log("setSelectedMenu,", item?.documentType)
    }
    setStartIndex(index);
  };

  const subMenuHandlerRevision = (item, url) => {
    if (item !== selectedMenu) {
      setPlaying(false);
      setProgressObj({});
      setDuration(0);
      setSubDurationValue(0);
    }
    setSelectedMenu(item);
    //console.log("setSelectedMenu,", item, item?.documentType)
    setSelectedUrl(url);
  };
  const pageHandler = () => {
    setPageNumber(enterPageNo ? Number(enterPageNo) : "");
  };
  const rightRotateHandler = () => {
    setRotate((pre) =>
      pre === 0
        ? 90
        : pre === 90
          ? 180
          : pre === -90
            ? 0
            : pre === -180
              ? -90
              : 0
    );
  };
  const leftRotateHandler = () => {
    setRotate((pre) =>
      pre === 0
        ? -90
        : pre === -90
          ? -180
          : pre === 90
            ? 0
            : pre === 180
              ? 90
              : 0
    );
  };
  const handleFullscreen = () => {
    const element = document.querySelector("iframe");
    if (element.requestFullscreen) {
      element.requestFullscreen();
    } else if (element.webkitRequestFullscreen) {
      /* Safari */
      element.webkitRequestFullscreen();
    } else if (element.msRequestFullscreen) {
      /* IE11 */
      element.msRequestFullscreen();
    }
  };

  const isActiveitm = sessionStorage.getItem("isActiveBoo");
  const parsedisActiveitm = JSON.parse(isActiveitm);

  const AssignHomeWork = () => {
    setIsHomeWorkModalOpen(true);
  };

  return (
    <div style={{ width: "100%", display: "flex", flexDirection: "row" }}
    // onContextMenu={handleRightClick}
    >
      <StartedSuccess
        open={isSuccessModalOpen}
        onClose={() => {
          setSuccessModalOpen(false);
        }}
        title={isChapterStarted}
      />
      <SideMenu
        menuType={menuType}
        menu={studyData?.length && studyData}
        menuClickHandler={handleClick}
        subMenuHandler={subMenuHandler}
        menuRevisionClickHandler={handleClickRevision}
        subMenuHandlerRev={subMenuHandlerRevision}
        collapseId={collapseId}
        startIndex={startIndex}
        selectedUrl={selectedUrl}
      />

      <div style={{ width: "100%", overflowY: "auto", overflowX: "hidden", scrollbarWidth: "thin" }}>
        <article>
          <div id="az-breadscrum-parent"
            // className={dashboardContentStyles.dashboard_link}
            style={{ fontSize: "1vw", margin: "3px 10px" }}
          >
            <span className={dashboardContentStyles.link_icon}>

              <img
                src={dashHome}
                alt="no img"
                width="15px"
                height="20px"
                style={{ verticalAlign: "middle" }}
              />
            </span>
            <span id="az-breadscrum-home" className={dashboardContentStyles.az_menu_text}
              style={{ cursor: "pointer" }}
              onClick={() => navigate("/dashboard/TeacherDashboard")}
            >Home</span>
            <span>
              <i className="fa-solid fa-angle-right" />
            </span>
            <span>
              <i className="fa-solid fa-angle-right" />
            </span>
            <span
              className={dashboardContentStyles.az_menu_text}
              onClick={() => closeModal()} style={{ cursor: "pointer" }}>
              {pageName}
            </span>
            <span>
              <i className="fa-solid fa-angle-right" />
            </span>
            <span>
              <i className="fa-solid fa-angle-right" />
            </span>
            <a id="az-breadscrum-view-document" className={dashboardContentStyles.az_selected_menu_text}>View Document</a>
          </div>
          {/* <div className={dashboardContentStyles.dashboard_last_updated}>
            <p>
              Last Update:
              {modifiedDate?.length && modifiedDate}
            </p>
          </div> */}
        </article>
        <div
          style={{
            marginTop: "20px",
            paddingRight: "33px",
            marginLeft: "10px",
            width: "100%",
            display: "flex",
            flexDirection: "column",
            rowGap: "1rem",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
            }}
            className='outer_DIV'
          >
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                columnGap: "0.5rem",
                alignItems: "center",
              }}
              className='inner_DIV'
            >
              <FormControl sx={{ minWidth: '150px', flexShrink: 0 }}>
                <InputLabel
                  sx={{
                    fontSize: '13px',
                    color: '#e7ab3c',
                    top: '-10px',
                    '&.MuiInputLabel-shrink': {
                      top: '0',
                    },
                    '&.Mui-focused': {
                      color: '#e7ab3c'
                    }
                  }}
                  id="az-teacher-teach-section-select-label" className='dropDownLabel'>Section</InputLabel>
                <Select
                  MenuProps={{
                    PaperProps: {
                      sx: {
                        width: '150px',
                        marginTop: '2px',
                      },
                    },
                    MenuListProps: {
                      sx: {
                        padding: '0px'
                      },
                    },
                  }}
                  input={<OutlinedInput label="Section" />}
                  IconComponent={(props) => (
                    <ExpandMoreIcon
                      {...props}
                      sx={{
                        fontSize: "1.5vw",
                      }}
                    />
                  )}
                  value={sectn}
                  onChange={handleSectionSelect}
                  inputProps={{ "aria-label": "Without label" }}
                  sx={{
                    width: '150px',
                    height: '30px',
                    padding: '10px',
                    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                      borderColor: '#000',
                    },
                    '&:hover .MuiOutlinedInput-notchedOutline': {
                      borderColor: '#000',
                    },
                    '&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
                      borderColor: '#e7ab3c',
                    }
                  }}
                  className='dropdownSelect'
                  id="az-teacher-teach-section-select"
                >
                  {Section?.map((item) => {
                    return (
                      <MenuItem
                        value={item.id}
                        key={item.id}
                        className={dashboardContentStyles.menu_item}
                        id={`az-teacher-sectiondropdown-${item.id}`}
                        sx={{
                          fontSize: '10px',
                          backgroundColor: 'white',
                          '&:hover': {
                            backgroundColor: '#f4d788',
                          },
                          '&.Mui-selected': {
                            backgroundColor: '#e7ab3c',
                            '&:hover': {
                              backgroundColor: '#f4d788',
                            },
                          },
                        }}
                      >
                        {item.hasOwnProperty("section") ? (
                          <ListItemText primary={item.section} />
                        ) : null}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
              <FormControl sx={{ minWidth: '150px', flexShrink: 0 }}>
                <InputLabel
                  sx={{
                    fontSize: '13px',
                    color: '#e7ab3c',
                    top: '-10px',
                    '&.MuiInputLabel-shrink': {
                      top: '0',
                    },
                    '&.Mui-focused': {
                      color: '#e7ab3c'
                    }
                  }}
                  className='dropDownLabel'
                  id="az-teacher-teach-Academic-label">Academic Year</InputLabel>
                <Select
                  labelId="az-teacher-teach-Academic-label"
                  id="az-teacher-teach-Academic-select"
                  input={<OutlinedInput label="Academic Year" />}
                  MenuProps={{
                    PaperProps: {
                      sx: {
                        width: '150px',
                        marginTop: '2px',
                      },
                    },
                    MenuListProps: {
                      sx: {
                        padding: '0px'
                      },
                    },
                  }}
                  disabled
                  IconComponent={(props) => (
                    <ExpandMoreIcon {...props} sx={{ fontSize: "1.5vw" }} />
                  )}
                  value={selectedAcademicYear}
                  onChange={setSelectedAcademicYear}
                  sx={{
                    width: '150px',
                    height: '30px',
                    padding: '10px',
                    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                      borderColor: '#000',
                    },
                    '&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
                      borderColor: '#e7ab3c',
                    }
                  }}
                  className='dropdownSelect'
                >
                  {academicYearList?.map((item) => {
                    return (
                      <MenuItem value={item.id} key={item.id}
                        className={
                          dashboardContentStyles.menu_item
                        }
                        sx={{
                          fontSize: '10px',
                          backgroundColor: 'white',
                          '&:hover': {
                            backgroundColor: '#f4d788',
                          },
                          '&.Mui-selected': {
                            backgroundColor: '#e7ab3c',
                            '&:hover': {
                              backgroundColor: '#f4d788',
                            },
                          },
                        }}>
                        <ListItemText primary={item.academicYear} />
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  minWidth: '105px',
                  // columnGap: "10px",
                  rowGap: '5px',
                  alignItems: "left",
                  fontSize: "0.93vw",
                }}
              >
                <div style={{ fontWeight: 600, color: "rgb(66, 61, 92)" }}>
                  Start Date:
                </div>
                <div style={{ color: "#01b5eb" }}>
                  {startDate ? (moment(startDate)).format('DD-MM-YYYY') : "Not started yet"}
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  width: '170px',
                  rowGap: '5px',
                  // columnGap: "10px",
                  alignItems: "center",
                  fontSize: "0.93vw",
                }}
              >
                <div style={{ fontWeight: 600, color: "rgb(66, 61, 92)" }}>
                End Date:
                </div>
                <div style={{ color: "#01b5eb" }}>
                {endDate ? (moment(endDate)).format('DD-MM-YYYY') : "Not ended yet"}
                </div>
              </div>
            </div>
            {selectedChapterId && pageName === "Teach" ? (
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  alignItems: "center",
                  flexDirection: "row",
                  columnGap: "10px",
                }}
              >
                <DarkGreenButton
                  id={`az-teacher-start-btn`}
                  disabled={!buttonsActiveState.startButton}
                  onClick={onStartClick}
                >
                  Start
                </DarkGreenButton>

                <RedButton
                  id={`az-teacher-end-btn`}
                  onClick={onEndClick}
                  disabled={!buttonsActiveState.endButton}
                >
                  End
                </RedButton>

                {parsedisActiveitm ? (
                  <DarkYellowButton
                    id={`az-teacher-assignquiz-btn`}
                    disabled={!buttonsActiveState.assignButton}
                    onClick={onAssignQuizClick}
                  >
                    Assign Quiz
                  </DarkYellowButton>
                ) : (
                  <button
                    style={{
                      height: "auto",
                      fontSize: "14px",
                      padding: "8px",
                      color: "white",
                      background: "#707070",
                      borderRadius: "5px",
                      border: "none",
                      outline: "none",
                    }}
                  >
                    Assign Quiz
                  </button>
                )}
              </div>
            ) : null}
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "end",
              alignItems: "center",
              marginRight: "2px",
            }}
          >
            {startDate || endDate ? (
              <button
                style={{
                  height: "33px",
                  width: "180px",
                  fontSize: "13px",
                  padding: "8px",
                  color: "white",
                  background: "#1912BF",
                  borderRadius: "5px",
                  border: "none",
                  outline: "none",
                }}
                onClick={() => AssignHomeWork()}
                id={`az-teacher-homework-btn`}
              >
                Homework Assignment
              </button>
            ) : null}
          </div>
          <div>
            <div>

              {
                checkLastIndexHandler(selectedMenu, "html") ||
                  checkLastIndexHandler(selectedMenu, "jpg") ||
                  checkLastIndexHandler(selectedMenu, "jpeg") ||
                  checkLastIndexHandler(selectedMenu, "png") ||
                  checkLastIndexHandler(selectedMenu, "svg") ||
                  checkLastIndexHandler(selectedMenu, "gif") ||
                  checkLastIndexHandler(selectedMenu, "cur") ?
                  (
                    <div
                      style={{
                        display: "flex",
                        background: "white",
                        flexDirection: "column",
                        alignItems: "flex-start",
                        padding: "20px",
                      }}
                    >
                      <div
                        id="text6"
                        style={{
                          fontWeight: "bold",
                          paddingBottom: "10px",
                          fontSize: "0.84vw",
                        }}
                      >
                        {nameHandler(selectedMenu)}
                      </div>
                      <iframe
                        id="id1"
                        // style={{ pointerEvents: "none", }}
                        title={nameHandler(selectedUrl)}
                        src={selectedUrl + "#toolbar=0"}
                        className="image_iframe"
                      />

                    </div>
                  ) : checkLastIndexHandler(selectedMenu, "pdf") ? (
                    <div
                      style={{
                        display: "flex",
                        // justifyContent: "center",
                        background: "white",
                        flexDirection: "column",
                        alignItems: "flex-start",
                        padding: "20px",
                        // width: "100%",
                        // height: "550px",
                      }}
                    >

                      <div
                        id="middle-text1"
                        style={{
                          fontWeight: "bold",
                          paddingBottom: "10px",
                          // textTransform: "capitalize",
                          fontSize: "13px",

                        }}
                      >
                        {nameHandler(selectedMenu)}
                      </div>
                      <>
                        {
                          (docType === 'Work Sheet' && JSON.parse(localStorage.getItem("wsDownload"))) || (docType === 'Learning Resource' && JSON.parse(localStorage.getItem("wbDownload"))) ? (
                            //  ((docType === 'Work Sheet' && ws_dnl === true) || (docType === 'Learning Resource' && wb_dwload === true)) ? (
                            //  ( ws_dnl && wb_dwload && (docType === 'Learning Resource' || docType === 'Work Sheet')) ? (
                            <div style={{ height: "600px", width: "100%" }}
                              onContextMenu={handleRightClick}>
                              <Viewer
                                fileUrl={selectedUrl}
                                plugins={[defaultLayoutPluginInstance1]}
                                defaultScale={SpecialZoomLevel.PageWidth}
                              />
                            </div>

                          ) :

                            (docType === 'Doc_img' && !selectedUrl.includes(".pdf")) ?
                              <iframe
                                id="id2"
                                title={nameHandler(selectedUrl)}
                                src={selectedUrl}
                                target="_blank"
                                style={{ height: "600px", width: "100%" }}
                              /> :

                              <ContentViewer contentstyle={{ height: "600px", width: "100%" }}
                                url={selectedUrl} />
                        }
                      </>

                    </div>
                  ) : selectedMenu === "Workbook" ? (
                    ""
                  ) : selectedMenu === "WB_Student" ? (
                    ""
                  )
                    // : selectedMenu === "Doc_img" ? (
                    //   <div
                    //     style={{
                    //       display: "flex",
                    //       // justifyContent: "center",
                    //       background: "white",
                    //       flexDirection: "column",
                    //       alignItems: "flex-start",
                    //       padding: "20px",
                    //       // width: "100%",
                    //       // height: "550px",
                    //     }}
                    //   >
                    //     <div
                    //       style={{
                    //         fontWeight: "bold",
                    //         paddingBottom: "10px",
                    //         fontSize: "13px",
                    //       }}
                    //     >
                    //       {nameHandler(selectedMenu)}
                    //     </div>


                    //     <iframe
                    //       title={nameHandler(selectedUrl)}
                    //       src={selectedUrl}
                    //       style={{ height: "600px", width: "100%" }}
                    //     />


                    //    {/* <button
                    //       onClick={handleFullscreen}
                    //       className="FullScrnBtn hvr-bubble-top"
                    //     >
                    //       Fullscreen
                    //     </button>  */}
                    //   </div>

                    // )
                    : selectedMenu === "VIDEO" ||
                      checkLastIndexHandler(selectedMenu, "mp4") ||
                      checkLastIndexHandler(selectedMenu, "webm") ||
                      checkLastIndexHandler(selectedMenu, "ogv") ||
                      checkLastIndexHandler(selectedMenu, "mov") ||
                      checkLastIndexHandler(selectedMenu, "avi") ? (
                      <Paper
                        sx={{
                          padding: "20px",
                          display: "flex",
                          flexDirection: "column",
                          rowGap: "10px",
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            columnGap: "10px",
                            alignItems: "center",
                            padding: "10px 0px",
                          }}
                        >

                          <div
                            id="text2"
                            style={{
                              fontWeight: 600,
                              fontWeight: "bold",
                              paddingBottom: "10px",
                              fontSize: "13px",
                            }}
                          >
                            {nameHandler(selectedMenu)}
                          </div>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            width: "100%",
                            background: "rgb(241, 237, 237)",
                            padding: "20px",
                            borderRadius: "10px",
                            height: "100%",
                          }}
                        >
                          <div style={{ marginBottom: "20px", marginTop: "20px", textAlign: "center" }}>
                            <CustomVideoPlayer
                              videoRef={videoRef}
                              selectedUrl={selectedUrl}
                            />
                          </div>

                        </div>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "space-between",
                          }}
                        >
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              rowGap: "5px",
                            }}
                          ></div>
                        </div>
                      </Paper>
                    ) : selectedMenu === "Audio" ||
                      checkLastIndexHandler(selectedMenu, "mp3") ||
                      checkLastIndexHandler(selectedMenu, "wav") ||
                      checkLastIndexHandler(selectedMenu, "wma") ||
                      checkLastIndexHandler(selectedMenu, "aac") ||
                      checkLastIndexHandler(selectedMenu, "ogg") ? (
                      <Paper
                        sx={{
                          padding: "20px",
                          display: "flex",
                          flexDirection: "column",
                          rowGap: "10px",
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            columnGap: "10px",
                            alignItems: "center",
                            padding: "10px 0px",
                          }}
                        >

                          <div
                            id="text3"
                            style={{
                              fontWeight: 600,
                              fontWeight: "bold",
                              paddingBottom: "10px",
                              fontSize: "13px",
                            }}
                          >
                            {nameHandler(selectedMenu)}
                          </div>
                        </div>

                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            width: "100%",
                            background: "rgb(241, 237, 237)",
                            padding: "20px",
                            borderRadius: "10px",
                            height: "100%", // minHeight: "100vh",
                          }}
                        >
                          <ReactAudioPlayer
                            src={selectedUrl}
                            autoPlay={false}
                            controls
                            controlsList="nodownload"
                          />
                        </div>

                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "space-between",
                          }}
                        >
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              rowGap: "5px",
                            }}
                          ></div>
                        </div>
                      </Paper>
                    ) : selectedMenu === "PRESENTATION" ||
                      checkLastIndexHandler(selectedMenu, "pptx") ||
                      checkLastIndexHandler(selectedMenu, "ppt") ||
                      checkLastIndexHandler(selectedMenu, "doc") ||
                      checkLastIndexHandler(selectedMenu, "docx") ? (
                      <Paper
                        sx={{
                          padding: "20px",
                          display: "flex",
                          flexDirection: "column",
                          rowGap: "10px",
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            columnGap: "10px",
                            alignItems: "center",
                            padding: "10px 0px",
                          }}
                        >

                          <div
                            id="text4"
                            style={{
                              fontWeight: 600,
                              fontWeight: "bold",
                              paddingBottom: "10px",
                              fontSize: "13px",
                            }}
                          >
                            {nameHandler(selectedMenu)}
                          </div>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            width: "100%",
                            background: "rgb(241, 237, 237)",
                            padding: "15px",
                            borderRadius: "10px",
                            height: "100%",
                            // minHeight: "100vh",
                            flexDirection: "column",
                          }}
                        >
                          <div
                            style={{
                              height: "520px",
                              width: "100%",
                              overflow: "auto",
                              padding: "10px",
                            }}
                          >

                            <iframe
                              id="id3"
                              src={`https://view.officeapps.live.com/op/embed.aspx?src=${selectedUrl}`}
                              style={{ width: "100%", height: "100%" }}
                            />
                          </div>
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              columnGap: "2px",
                              alignItems: "center",
                              background: "rgb(192 190 190)",
                              borderRadius: "150px",
                              marginTop: "10px",
                            }}
                          >
                            <PresentationButton
                              onClick={previousPresentationPage}
                              disabled={presentationPageNumber <= 1}
                              sx={{
                                borderTopLeftRadius: "150px",
                                borderTopRightRadius: "5px",
                                borderBottomRightRadius: "5px",
                                borderBottomLeftRadius: "150px",
                              }}
                            >
                              <ArrowDropDownIcon />
                            </PresentationButton>
                            <TextField
                              type={"number"}
                              sx={{
                                width: "60px",
                                margin: "2px",
                                background: "white",
                                borderRadius: "3px",
                                // height: "50px",
                              }}
                              value={presentationPageNumber}
                              onChange={(e) => {
                                const number = e.target.value;
                                if (number) {
                                  setPresentationPageNumber(Number(number));
                                } else {
                                  setPresentationPageNumber("");
                                }
                              }}
                              size={"small"}
                            />
                            <PresentationButton
                              onClick={nextPresentationPage}
                              disabled={
                                presentationPageNumber >= presentationNumPages
                              }
                              sx={{
                                borderBottomRightRadius: "150px",
                                borderTopRightRadius: "150px",
                                borderTopLeftRadius: "5px",
                                borderBottomLeftRadius: "5px",
                              }}
                            >
                              <ArrowDropUpIcon />
                            </PresentationButton>
                          </div>
                        </div>
                      </Paper>
                    ) : selectedMenu === "Exel" ||
                      checkLastIndexHandler(selectedMenu, "xls") ||
                      checkLastIndexHandler(selectedMenu, "xlsx") ? (
                      <Paper
                        sx={{
                          padding: "20px",
                          display: "flex",
                          flexDirection: "column",
                          rowGap: "10px",
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            columnGap: "10px",
                            alignItems: "center",
                            padding: "10px 0px",
                          }}
                        >

                          <div
                            id="text5"
                            style={{
                              fontWeight: 600,
                              fontWeight: "bold",
                              paddingBottom: "10px",
                              fontSize: "13px",
                            }}
                          >
                            {nameHandler(selectedMenu)}
                          </div>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            width: "100%",
                            background: "rgb(241, 237, 237)",
                            padding: "15px",
                            borderRadius: "10px",
                            height: "100%",
                            // minHeight: "100vh",
                            flexDirection: "column",
                          }}
                        >
                          <div
                            style={{
                              height: "520px",
                              width: "100%",
                              overflow: "auto",
                              padding: "10px",
                            }}
                          >

                            <iframe
                              id="id4"
                              src={`https://view.officeapps.live.com/op/embed.aspx?src=${selectedUrl}`}
                              style={{ width: "100%", height: "100%" }}
                            />
                          </div>
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              columnGap: "2px",
                              alignItems: "center",
                              background: "rgb(192 190 190)",
                              borderRadius: "150px",
                              marginTop: "10px",
                            }}
                          >
                            <PresentationButton
                              onClick={previousPresentationPage}
                              disabled={presentationPageNumber <= 1}
                              sx={{
                                borderTopLeftRadius: "150px",
                                borderTopRightRadius: "5px",
                                borderBottomRightRadius: "5px",
                                borderBottomLeftRadius: "150px",
                              }}
                            >
                              <ArrowDropDownIcon />
                            </PresentationButton>
                            <TextField
                              type={"number"}
                              sx={{
                                width: "60px",
                                margin: "2px",
                                background: "white",
                                borderRadius: "3px",
                                // height: "50px",
                              }}
                              value={presentationPageNumber}
                              onChange={(e) => {
                                const number = e.target.value;
                                if (number) {
                                  setPresentationPageNumber(Number(number));
                                } else {
                                  setPresentationPageNumber("");
                                }
                              }}
                              size={"small"}
                            />
                            <PresentationButton
                              onClick={nextPresentationPage}
                              disabled={
                                presentationPageNumber >= presentationNumPages
                              }
                              sx={{
                                borderBottomRightRadius: "150px",
                                borderTopRightRadius: "150px",
                                borderTopLeftRadius: "5px",
                                borderBottomLeftRadius: "5px",
                              }}
                            >
                              <ArrowDropUpIcon />
                            </PresentationButton>
                          </div>
                        </div>
                      </Paper>
                    ) : selectedMenu === "REVISION" ? (
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          background: "rgb(204, 204, 204)",
                          flexDirection: "column",
                          alignItems: "center",
                        }}
                      >
                        <div
                          style={{
                            width: "100%",
                            background: "rgb(241,237,237)",
                            padding: "10px",
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "space-between",
                            alignItems: "center",
                          }}
                        >
                          <div>{nameHandler}</div>
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              columnGap: "10px",
                            }}
                          >
                            <TextField
                              type={"number"}
                              size={"small"}
                              sx={{ width: 100, background: "white" }}
                              value={enterPageNo}
                              onChange={(e) => pageChangeHandler(e.target.value)}
                            />
                            <WhiteButton
                              onClick={pageHandler}
                              disabled={enterPageNo?.length === 0}
                            ></WhiteButton>
                          </div>
                        </div>
                        <div
                          style={{
                            height: "550px",
                            overflow: "auto",
                            padding: "10px",
                            maxWidth: "1300px",
                          }}
                        >

                          <DocViewer
                            pluginRenderers={DocViewerRenderers}
                            documents={[{ uri: selectedUrl }]}
                            theme={{
                              primary: "rgb(241,237,237)",

                              disableThemeScrollbar: true,
                            }}
                            style={{ height: "600px" }}
                          />
                        </div>
                        <div
                          style={{
                            background: "rgb(61 60 60)",
                            width: "100%",
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "center",
                            padding: "10px",
                            color: "white",
                            alignItems: "center",
                            columnGap: "10px",
                            fontSize: "0.93vw",
                          }}
                        >
                          <div style={{ color: "white" }}>
                            {pageNumber || (numPages ? 1 : "--")} / {numPages || "--"}
                          </div>
                          <Divider
                            sx={{
                              border: "0.1px solid white",
                              padding: "0px",
                            }}
                            orientation="vertical"
                            flexItem
                          />
                          <IconButton
                            onClick={nextPage}
                            disabled={pageNumber >= numPages}
                          >
                            <ArrowCircleUpIcon sx={{ color: "white" }} />
                            {/*Next*/}
                          </IconButton>
                          <IconButton
                            onClick={previousPage}
                            disabled={pageNumber <= 1}
                          >
                            <ArrowCircleDownIcon sx={{ color: "white" }} />
                            {/*prev*/}
                          </IconButton>
                          <Divider
                            orientation="vertical"
                            flexItem
                            sx={{
                              border: "0.1px solid white",
                              padding: "0px",
                            }}
                          />
                          <IconButton onClick={addScale}>
                            <AddCircleOutlineIcon
                              sx={{ color: "white", fontSize: "1.5vw" }}
                            />
                          </IconButton>
                          <IconButton onClick={removeScale} disabled={scale <= 1}>
                            <RemoveCircleOutlineIcon
                              sx={{ color: "white", fontSize: "1.5vw" }}
                            />
                          </IconButton>
                          <Divider
                            orientation="vertical"
                            flexItem
                            sx={{
                              border: "0.1px solid white",
                              padding: "0px",
                            }}
                          />
                          <IconButton onClick={rightRotateHandler}>
                            <Rotate90DegreesCwOutlinedIcon
                              sx={{ color: "white", fontSize: "1.5vw" }}
                            />
                          </IconButton>
                          <IconButton onClick={leftRotateHandler}>
                            <Rotate90DegreesCcwOutlinedIcon
                              sx={{ color: "white", fontSize: "1.5vw" }}
                            />
                          </IconButton>
                        </div>
                      </div>
                    ) : (
                      <>
                        {
                          checkLastIndexHandler(selectedMenu, "html") ||
                          checkLastIndexHandler(selectedMenu, "jpg") ||
                          (checkLastIndexHandler(selectedMenu, "png") ||
                            checkLastIndexHandler(selectedMenu, "jpg") ||
                            checkLastIndexHandler(selectedMenu, "jpeg") ||
                            checkLastIndexHandler(selectedMenu, "png") ||
                            checkLastIndexHandler(selectedMenu, "svg") ||
                            checkLastIndexHandler(selectedMenu, "gif") ||
                            checkLastIndexHandler(selectedMenu, "cur") && (
                              <Paper
                                sx={{
                                  padding: "20px",
                                  display: "flex",
                                  flexDirection: "column",
                                  rowGap: "10px",
                                }}
                              >
                                <div
                                  style={{
                                    display: "flex",
                                    flexDirection: "row",
                                    columnGap: "10px",
                                    alignItems: "center",
                                    padding: "10px 0px",
                                  }}
                                >
                                  <div
                                    style={{
                                      background: "#ffcc00",
                                      borderRadius: "150px",
                                      width: "1.5vw",
                                      height: "1.5vw",
                                      display: "flex",
                                      justifyContent: "center",
                                      alignItems: "center",
                                    }}
                                  >
                                    <PsychologyAltOutlinedIcon
                                      style={{ color: "white", fontSize: "1.3vw" }}
                                    />
                                  </div>
                                  <div
                                    style={{
                                      fontSize: 20,
                                      color: "black",
                                      fontWeight: 600,
                                    }}
                                  ></div>
                                </div>
                                <div>{nameHandler(selectedUrl)}</div>
                                <div
                                  style={{
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    width: "100%",
                                    background: "rgb(241, 237, 237)",
                                    padding: "20px",
                                    borderRadius: "10px",
                                    height: "100%",
                                    // minHeight: "100vh",
                                  }}
                                >

                                  <iframe
                                    id="id5"
                                    height={"550px"}
                                    width={"100%"}
                                    src={selectedUrl}
                                    title={`${nameHandler(selectedUrl)}`}
                                  />
                                </div>
                              </Paper>
                            ))}
                      </>
                    )}
            </div>
          </div>
        </div>
      </div>
      {showSectionModal && (
        <SelectSectionModal
          open={showSectionModal}
          close={() => setShowSectionModal(false)}
          sectionList={Section}
          selectedSection={selectedSection}
          setSelectedSection={setSelectedSection}
        />
      )}

      {isHomeWorkModalOpen && (
        <HomeWorkModal
          open={isHomeWorkModalOpen}
          close={() => setIsHomeWorkModalOpen(false)}
          boardId={boardId}
          gradeId={gradeId}
          schoolId={schoolId}
          branchId={branchId}
          subjectId={subjectId}
          sectionId={sectionId}
          subTopicId={subTopicId}
          chapterId={selectedChapterId}
          academicYearId={academicYearId}
          teacherId={teacherId}
          showSubTopic={showSubTopic}
        />
      )}
    </div>
  );
};
export default ViewContentPage;