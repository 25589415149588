import axios from 'axios'

// async function update_question_post(
//     payload,
//     setShowSuccessAlert,
//     setResponseValue,
//     setShowErrorAlert,
//     setShowErroMessage,
// ) {
//     console.log("update_question_post called", payload);
//     console.log(process.env.REACT_APP_SWAGGER_URL, `${process.env.REACT_APP_SWAGGER_URL}v1/api/content/question`)
//     let token = sessionStorage.getItem("token");
//     try {
//         let result = await axios.put(`${process.env.REACT_APP_SWAGGER_URL}v1/api/content/question`, payload,
//             {
//                 headers: {
//                     "Content-Type": "application/json",
//                     Authorization: `Bearer ${token}`,
//                 },
//             })
//             .then(
//                 (response) => {
//                     console.log("response.data.data ", response.data.data)
//                     if (setShowSuccessAlert) {
//                         setShowSuccessAlert(true);
//                     }
//                     if (setResponseValue) {
//                         setResponseValue(response.data.data);
//                     }
//                 }
//             )
//             .catch(err => {
//                 console.log(err)
//                 if (setShowErrorAlert) {
//                     setShowErrorAlert(true);
//                     setShowErroMessage(err?.response?.data?.message);
//                 }
//                 // console.log(err)
//             });
//     } catch (error) {
//         console.log("==========", error.message);
//     }
// }
async function update_question_post(
    payload,
    setShowSuccessAlert,
    setResponseValue,
    setShowErrorAlert,
    setShowErroMessage
) {
    let token = sessionStorage.getItem("token");
    // if (!payload) {
    //     console.log("payloadpayloadpayloadpayloadpayloadpayloadpayloadpayloadpayloadpayloadpayloadpayloadpayload", payload)
    // }
    try {
        const response = await axios.put(
            `${process.env.REACT_APP_SWAGGER_URL}v1/api/content/question`,
            payload,
            {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${token}`,
                },
            }
        );
        if (setShowSuccessAlert) setShowSuccessAlert(true);
        if (setResponseValue) setResponseValue(response.data.data);
    } catch (err) {
        // console.log("Error:", err.message);

        if (setShowErrorAlert) {
            setShowErrorAlert(true);
            if (setShowErroMessage) {
                setShowErroMessage(err?.response?.data?.message || "Something went wrong.");
            }
        }
    }
}

export default update_question_post;
